import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Card,CardHeader, CardContent} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as Constants from './../config';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import HeroImage from './HeroImage';
import Pair from './Pair';
import TabPanel from './TabPanel';
import HeroForm from './../HeroForm/HeroForm';
import AddSubmission from './../Submission/AddSubmission';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { AppBar, Paper } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Submission from './../Submission/Submission';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '105px',
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-around',
        //overflow: 'hidden',
    },
    carousel: {
        width: '450px',
        display: 'flex',
        flexDirection: 'column',
        height:'auto',
        margin: '16px',
    },
    details: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        margin:'16px 8px 0px 0px',
        
    },
    content: {
        flex: '1 0 auto',
    },
    cover:{
        width:'100%'

    },
    donateButton:{
        backgroundColor: "#dc3545",
        '&:hover': {
       backgroundColor: "#c82333",
    },
        color: "white",
        margin: "10px"
    }, 
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

    heroTitle: {
        margin: '0px 16px',
    },

    uploadPic: {
        width : '100%',
    },
}));

const a11yProps=function(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Hero(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const id = parseInt(props.match.params.id);
    const hero = Constants.tileData.find(hero => hero.id == id);
    
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState("testimony");    
    const handleClickOpen = (input) => {
        setType(input);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className={classes.root}>

                <Typography className={classes.heroTitle} component="h1" variant="h5">
                    {hero.title}
                </Typography>
                <div className="row">
                <div className={classes.carousel}>
                   
                    {/*<img
                    className={classes.cover} 
                    src={process.env.PUBLIC_URL + "/assets/" + hero.img}
                    alt={hero.title}
                    /> */}
                        <Paper>
                            <HeroImage hero={hero} />
                        <Button className={classes.uploadPic} onClick={() => { handleClickOpen('picture') }}>
                                {t('UploadLbl')}
                        </Button>
                        </Paper>
                        <Button variant="outlined" className={ classes.donateButton} onClick={() => { window.open('https://www.1000plus.am/'+i18n.language+'/payment/in-memory/?name='+hero.title) }}>
                            {t('DonateBtn', {name: hero.name })}
                      
                        </Button>
                        
                        <Card className={classes.details}>
                            <div className={classes.details}>
                                <CardHeader
                                    action={
                                        <Button onClick={() => {  handleClickOpen('document') }}>
                                                     {t('AddBtn')}
                                        </Button>
                                    }
                                    title={t("DocumentTitle")}
                                />
                                <CardContent className={classes.content}>
                                    {hero.documents && <Submission type="documents" data={hero.documents} />}
                                </CardContent>                               
                            </div>
                        </Card>

                    </div>
                    <div class="column">
                        <Card className={classes.details}>
                            <div className={classes.details}>

                                <CardContent className={classes.content}>          
                                            <CardHeader
                               
                                        title={`${hero.surname}, ${hero.name} `}
                            />
                                    <Typography component="h1" variant="h6">
                                       
                                    </Typography>
                                <AppBar position="static">
                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                            <Tab label={t("PersonalDetailsLbl")} {...a11yProps(0)} />
                                            <Tab label={t("BiographyLbl")} {...a11yProps(1)} />
                                            <Tab label={t("ServiceHistoryLbl")} {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                        <Pair label={t('DateOfBirthLbl')} value={hero.dateOfBirth} />
                                        <Pair label={t('PlaceOfBirthLbl')} value={hero.placeOfBirth} />
                                        <Pair label={t('MartyrdomDateLbl')} value={hero.dateOfDeath} />
                                        <Pair label={t('ProfessionLbl')} value={hero.profession} />
                                        <Pair label={t('NationalityLbl')} value={hero.nationality} />
                                        <Pair label={t('PlaceOfWorkLbl')} value={hero.placeOfWork} />
                                        <Pair label={t('FamilyStatusLbl')} value={hero.familyStatus} />
                                        <Pair label={t('NumberOfChildrensLbl')} value={hero.numberOfChildren} />
                                </TabPanel>
                                 <TabPanel value={value} index={1}>
                                        {hero.biography ? hero.biography : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam."}
                                </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <Pair label={t('MilitaryRankLbl')} value={hero.rank} />
                                        <Pair label={t('ArmyProfessionLbl')} value={hero.profession} />
                                        <Pair label={t('PlaceOfMartyrdomLbl')} value={hero.placeOfDeath} />
                                        <Pair label={t('PlacesFoughtLbl')} value={hero.placesFought} />
                                        <Pair label={t('CircumstancesOfDeathLbl')} value={hero.cirucmstancesOfDeath} />
                                        <br/>
                                       {t('CemeteryLbl', { name: hero.name, section: "A", row: "B" })}
                                        <img
                                            className={classes.cover}
                                            src={process.env.PUBLIC_URL + "/assets/sample_grave.png"}
                                            alt={hero.title} />
                                </TabPanel>
                                </CardContent>
                                <CardActions>
                                    {/*<Button color="secondary" aria-label="edit" onClick={handleClickOpen}>
                                        <EditIcon />
                                    </Button>*/}
                                </CardActions>
                            </div>
                    </Card>
                        <Card className={classes.details}>
                            <CardHeader
                                action={
                                    <Button onClick={() => { handleClickOpen('testimony') }}>
                                        {t('StoryBtn', { name: hero.name})}
                                        
                                    </Button>
                                }
                                title={t("TestimoniesTitle")}
                            />
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                    {hero.testimonies&&<Submission type="testimonies" data={hero.testimonies} />}
                            </CardContent>
                        </div>
                        
                        </Card>
                        </div>
                </div>
                <Dialog fullWidth
                    maxWidth="lg"  open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={classes.Dialog}>
                    <DialogTitle id="form-dialog-title">Add your Information</DialogTitle>
                    <DialogContent>
                        <AddSubmission type={type} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {t("CancelBtn")}
                         </Button>
                        <Button onClick={handleClose} color="primary">
                            {t("AddBtn")}
                         </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}