import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Divider, Link, Box} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
       backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

export default function AlignItemsList(props) {
    const classes = useStyles();
    const preventDefault = (event) => event.preventDefault();
    const { data, type } = props;
    return (
        <>

            <Box component="span" m={1}>
                {data.map((entry) =>  {

                    switch (type) {
                        case "testimonies": return (<>
                            <Typography variant="body1" gutterBottom>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                                unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
                                dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                Submitted By: A. V on 22 April, 2021 16:21
                </Typography>
                            <hr /></>);
                        case "documents": return (<>
                            <Link href={entry.link ? entry.link:'#'} target="_blank" rel="noopener">
                                {entry.label? entry.label:"Link Sample"}
                </Link>
                        </>);  }
                })}
            </Box>
        </> 
       
    );
}