import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { MenuItem,Select, InputLabel, FormControl, Button, Typography, TextField} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

function getSteps() {
    return ['Enter Soldier Information', 'Upload photos', 'About the Service', 'Information Provider Information'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Step 1: Select campaign settings...';
        case 1:
            return 'Step 2: What is an ad group anyways?';
        case 2:
            return 'Step 3: This is the bit I really care about!';
        default:
            return 'Unknown step';
    }
}

export default function HeroForm() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();
    const [gender, setGender] = React.useState('');
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <div className={classes.root}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton onClick={handleStep(index)} completed={completed[index]}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            <form className={classes.container} noValidate>
                {activeStep === 0 && <div>
                    <FormControl className={classes.formControl}><TextField required id="name" label="Name" /></FormControl><br />
                    <FormControl className={classes.formControl}><TextField required id="surname" label="Surname" /></FormControl><br />
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="date"
                            label="Birthday"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /></FormControl>
                    <br />
                    <FormControl className={classes.formControl}>
                        <TextField required id="birthPlace" label="Place of Birth" /></FormControl><br />
                    <FormControl className={classes.formControl}>
                        <TextField required id="nationality" label="Nationality" /></FormControl><br />

                    <FormControl className={classes.formControl}>
                        <InputLabel id="gender">Gender</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            id="gender-select"
                            value={gender}
                            onChange={setGender}
                        >
                            <MenuItem value={'male'}>Male</MenuItem>
                            <MenuItem value={'female'}>Female</MenuItem>
                        </Select>
                    </FormControl><br />
                    <FormControl className={classes.formControl}> <TextField required id="profession" label="Profession" /></FormControl><br />
                    <FormControl className={classes.formControl}><TextField required id="workPlace" label="Place of Work" /></FormControl><br />

                    <FormControl className={classes.formControl}><TextField required id="familyStatus" label="Family status" /></FormControl ><br />
                    <FormControl className={classes.formControl}> <TextField required id="childNo" label="Number of children" /></FormControl ><br />
                </div>}
                {activeStep === 1 && <div>
                    <input
                        accept="image/*"
                        className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="raised" component="span" className={classes.button}>
                            Upload
                </Button>
                    </label>
                </div>
                }
                {activeStep === 2 &&
                    <div>
                        <FormControl className={classes.formControl}>     <TextField required id="title" label="Title" /></FormControl ><br />
                        <FormControl className={classes.formControl}>   <TextField required id="professionArmy" label="Army Profession" /></FormControl ><br />
                        <FormControl className={classes.formControl}>   <TextField required id="deathDate" label="Date of Death" /></FormControl ><br />
                        <FormControl className={classes.formControl}>   <TextField required id="deathPlace" label="Place of Death" /></FormControl ><br />
                        <FormControl className={classes.formControl}>   <TextField required id="places" label="Places fought" /></FormControl ><br />
                        <FormControl className={classes.formControl}> <TextField required id="deathReason" label="Circumstances of death" /></FormControl >

                    </div>}
                {activeStep === 3 && <div>
                    <FormControl className={classes.formControl}>  <TextField required id="nameProvider" label="Name" /></FormControl ><br />
                    <FormControl className={classes.formControl}>  <TextField required id="surnameProvider" label="Surname" /></FormControl ><br />
                    <FormControl className={classes.formControl}>  <TextField required id="address" label="Address" /></FormControl ><br />
                    <FormControl className={classes.formControl}>  <TextField required id="phone" label="Phone Number" /></FormControl ><br />
                    <FormControl className={classes.formControl}>  <TextField required id="country" label="Country" /></FormControl ><br />

                    <label>Signature</label><br />
                    
                </div>}
                <br/>
                <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        Back
              </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </div>
                </form>
        </div>
    );
}
