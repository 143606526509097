export const tileData = [
   
    {
        id: 1,
        image: '140640011_1812767502233713_4185073129532043285_n.jpg',
        title: 'Van Aslanyan',
        name: 'Van',
        surname: 'Aslanyan',
        region: 'Ararat',
        biography:'Van Aslanyan was born on 17 August 2001 in Shakhap village, Ararat Marz. In January 2020, he was called to serve in the army in the Hadrut region. When the war began, Van was on the front lines from day one. He destroyed three enemy tanks and saved the lives of two fellow soldiers. He fought heroically but became a martyr in Jibrayil (Armenian Chrakan) on 30 September from the explosion of enemy drones. Van served in the army for ten months. His love for and sense of duty towards the Homeland were part of his character, as well as being kind and helping others.',
        dateOfBirth:'17 August 2001',
        placeOfBirth:'Shakhap village, Ararat Marz',
        nationality:'',
        dateOfDeath:'30 September 2020',
        placeOfDeath: 'Chrakan',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    },
    {
        id: 2,
        image: 'areg-gevorgyan.jpg',
        title: 'Areg Gevorgyan',
        name: 'Areg',
        surname: 'Gevorgyan',
        region: 'Yerevan',
        biography: 'Areg Gevorgyan heroically fell on the battlefield protecting his homeland. Areg Gevorgyan was admitted to the BA in English and Communications program in summer 2020, but did not have a chance to attend AUA before his conscription. Areg was a curious student who was interested in public relations, advertising and all aspects of communications. ',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    },
    {
        id: 3,
        image: 'aziz-aghajanyan.png',
        title: 'Aziz Aghajanyan',
        name: 'Aziz',
        surname: 'Aghajanyan',
        region: 'Yerevan',
        biography: 'Aziz Aghajanyan was admitted to AUA in 2019 to our BS in Computer Science program and was immediately conscripted. He sacrificed his life to protect his homeland and his people. ',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    },
    {
        id: 4,
        image: 'davit-uzunyan.jpg',
        title: 'Davit Uzunyan',
        name: 'Davit',
        surname: 'Uzunyan',
        region: 'Yerevan',
        biography: 'Davit Uzunyan started his AUA journey in 2018 in our BAB program. After completing two semesters, he was conscripted to the army in the summer of 2019. Davit was a treasure both in and out of the classroom.He was an avid footballer who enjoyed participating in tournaments with his team.He was incredibly smart and mature for his age, treating everyone he met with grace and politeness.He was very quiet and shy but he spoke with intention and carried himself with dignity.It was as if Davit knew he had a bigger purpose in life than most. It is tragic to lose someone so bright and so young.Davit sacrificed himself protecting his people and his nation.He heroically defended his homeland until his very last moment, as leader of their position. He will always be remembered as a hero. ',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    }, 
    {
        id: 5,
        image: 'davit-virabyan.png',
        title: 'Davit Virabyan',
        name: 'Davit',
        surname: 'Virabyan',
        region: 'Yerevan',
        biography: 'Davit was a freshman in AUA\'s BAB program. He started his journey at AUA in Fall 2019 and was conscripted at the end of the semester after having successfully completed one semester at AUA. We will always remember Davit, our hero, through his kindness and love for life. Davit was a vibrant, gracious, and determined young man. He was supportive of his friends, considerate toward his instructors, and immersed in his learning. His warm eyes and infectious smile made others happy just to be around him. An essay he wrote in his freshman seminar class began with words that he lived by, "We should appreciate every moment and trifle in our life."Davit served his country with a great sense of honor and responsibility.His last moments were spent defending his homeland and his people.His passing is a huge loss for our nation and community.His memory will live on in victory and peace. ',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    }, 
    {
        id: 6,
        image: 'erik-hajikyan.jpg',
        title: 'Erik Hajikyan',
        name: 'Erik',
        surname: 'Hajikyan',
        region: 'Yerevan',
        biography: 'Erik was in AUA\'s first cohort of the Bachelor of Science in Engineering Sciences program, successfully completing two semesters at AUA. He was conscripted in the summer of 2019. It goes without saying that Erik’s passing is a huge loss for our community and our country.Erik was very gentle and soft- spoken.He was an extremely smart, kind, responsible, and courageous young man.Besides being an extraordinary student, he was an accomplished karate practitioner.He was loved by everyone in and out of the classroom.He treated everyone with love and respect and will be missed very much.Erik’s memory will live in our hearts forever as we remember him for his kindness, bravery, and compassion. It is tragic to lose someone so bright and so young.Like all his peers, Erik had dreams, hopes, and wishes.He heroically defended his homeland until his very last moment.',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    },    
    {
        id: 7,
        image: 'hakob-hakobyan.jpg',
        title: 'Hakob Hakobyan',
        name: 'Hakob',
        surname: 'Hakobyan',
        region: 'Yerevan',
        biography: '',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],

    }, {
        id: 8,
        image: '157844639_1848691255308004_3942396979119041775_n.jpg',
        title: 'Erik Abrahamyan',
        name: 'Erik',
        surname: 'Abrahamyan',
        region: 'Yerevan',
        biography: 'Erik Abrahamyan was an excellent shooter. He was in Martuni, which was 50 meters away from the enemy. Thanks to his accurate calculations, he struck the enemy. Erik was always smiling, he was bright, and everyone loved him. On November 2nd, Erik was seriously injured due to the ongoing battles.Erik died on his 19th birthday.After his death, Erik was rewarded with a great TIGRAN medal.Rest in peace hero, eternal glory to you.',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        dateOfDeath: '',
        placeOfDeath: '',
        profession: '',
        rank: '',
        numberOfChildren: '',
        placesFought: '',
        cirucmstancesOfDeath: '',
        placeOfWork: '',
        familyStatus: '',
        testimonies: [{}, {}],
        documents: [{}],
        images: [],


        testimonies: [{}, {}],
        documents: [{
            link: ' https://www.facebook.com/ArmenianHeroes/posts/know-our-heroeserik-abrahamyan-was-an-excellent-shooter-he-was-in-martuni-which-/1848691288641334/',
            label: 'Facebook Post'
        }],
        images: [],
       
    },
    {
        "id": "2330",
        "title": "Varuzhan Adamyan",
        "name": "Varuzhan",
        "surname": "Adamyan",
        "dateOfBirth": "27 Feb., 1991",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2329",
        "title": "Narek Matevosyan",
        "name": "Narek",
        "surname": "Matevosyan",
        "dateOfBirth": "27 Mar., 1998",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    
    {
        "id": "2328",
        "title": "Aram Ghazaryan",
        "name": "Aram",
        "surname": "Ghazaryan",
        "dateOfBirth": "24 Oct., 1997",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },

  
    {
        "id": "2327",
        "title": "Valeri Mikaelyan",
        "name": "Valeri",
        "surname": "Mikaelyan",
        "dateOfBirth": "08 Oct., 1991",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2326",
        "title": "Narek Gevorgyan",
        "name": "Narek",
        "surname": "Gevorgyan",
        "dateOfBirth": "23 Aug., 2000",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },   {
        "id": "2325",
        "title": "Hakob Ghukasyan",
        "name": "Hakob",
        "surname": "Ghukasyan",
        "dateOfBirth": "19 July, 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2324",
        "title": "Hayk Ghulyan",
        "name": "Hayk",
        "surname": "Ghulyan",
        "dateOfBirth": "08 May, 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2323",
        "title": "Sasun Arzumanyan",
        "name": "Sasun",
        "surname": "Arzumanyan",
        "dateOfBirth": "18 July, 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2322",
        "title": "Vardges Poghosyan",
        "name": "Vardges",
        "surname": "Poghosyan",
        "dateOfBirth": "21 Oct., 2000",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2321",
        "title": "Serjik Sahakyan",
        "name": "Serjik",
        "surname": "Sahakyan",
        "dateOfBirth": "09 June, 2001",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2140",
        "title": "Zaven Hakobyan",
        "name": "Zaven",
        "surname": "Hakobyan",
        "dateOfBirth": "21 Mar., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2139",
        "title": "David Hambartsumyan",
        "name": "David",
        "surname": "Hambartsumyan",
        "dateOfBirth": "06 Sept. , 2021",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2138",
        "title": "Lyova Harutyunyan",
        "name": "Lyova",
        "surname": "Harutyunyan",
        "dateOfBirth": "22 Aug., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2137",
        "title": "Manvel Harutyunyan",
        "name": "Manvel",
        "surname": "Harutyunyan",
        "dateOfBirth": "18 Apr., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2136",
        "title": "Hovhannes Hovhannisyan",
        "name": "Hovhannes",
        "surname": "Hovhannisyan",
        "dateOfBirth": "17 May, 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2135",
        "title": "Levon Hovhannisyan",
        "name": "Levon",
        "surname": "Hovhannisyan",
        "dateOfBirth": "19 Dec., 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2134",
        "title": "Tatul Hovhannisyan",
        "name": "Tatul",
        "surname": "Hovhannisyan",
        "dateOfBirth": "26 Mar., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2133",
        "title": "Gor Abazyan",
        "name": "Gor",
        "surname": "Abazyan",
        "dateOfBirth": "29 Apr., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2132",
        "title": "Sargis Abrahamyan",
        "name": "Sargis",
        "surname": "Abrahamyan",
        "dateOfBirth": "13 Sept. , 1999",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2131",
        "title": "Grigor Hovsepyan",
        "name": "Grigor",
        "surname": "Hovsepyan",
        "dateOfBirth": "27 Sept. , 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2320",
        "title": "Sergey Barseghyan",
        "name": "Sergey",
        "surname": "Barseghyan",
        "dateOfBirth": "12 Jan., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2319",
        "title": "Valery Musayelyan",
        "name": "Valery",
        "surname": "Musayelyan",
        "dateOfBirth": "19 Sept. , 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2318",
        "title": "Albert Kalashyan",
        "name": "Albert",
        "surname": "Kalashyan",
        "dateOfBirth": "30 Sept. , 2000",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2317",
        "title": "Mher Soghomonyan",
        "name": "Mher",
        "surname": "Soghomonyan",
        "dateOfBirth": "23 Oct., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2316",
        "title": "Edik Bagryan",
        "name": "Edik",
        "surname": "Bagryan",
        "dateOfBirth": "25 Oct., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2315",
        "title": "Armen Harutyunyan",
        "name": "Armen",
        "surname": "Harutyunyan",
        "dateOfBirth": "12 June, 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2314",
        "title": "David Harutyunyan",
        "name": "David",
        "surname": "Harutyunyan",
        "dateOfBirth": "18 July, 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2313",
        "title": "Vardan Danielyan",
        "name": "Vardan",
        "surname": "Danielyan",
        "dateOfBirth": "31 Oct., 2000",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2312",
        "title": "Arayik Davtyan",
        "name": "Arayik",
        "surname": "Davtyan",
        "dateOfBirth": "10 Feb., 2001",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2311",
        "title": "Sergey Hayrapetyan",
        "name": "Sergey",
        "surname": "Hayrapetyan",
        "dateOfBirth": "24 Feb., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2050",
        "title": "Khoren Ohanyan",
        "name": "Khoren",
        "surname": "Ohanyan",
        "dateOfBirth": "18 Sept. , 1984",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2049",
        "title": "Hayk Lalayan",
        "name": "Hayk",
        "surname": "Lalayan",
        "dateOfBirth": "23 Oct., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2048",
        "title": "Karen Beglaryan",
        "name": "Karen",
        "surname": "Beglaryan",
        "dateOfBirth": "08 Jan., 1997",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2047",
        "title": "Makar Beglaryan",
        "name": "Makar",
        "surname": "Beglaryan",
        "dateOfBirth": "08 Apr., 1980",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2046",
        "title": "Aghasi Bezhanyan",
        "name": "Aghasi",
        "surname": "Bezhanyan",
        "dateOfBirth": "04 Dec., 1997",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2045",
        "title": "Albert Tsatryan",
        "name": "Albert",
        "surname": "Tsatryan",
        "dateOfBirth": "08 July, 1991",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2044",
        "title": "Eduard Vardanyan",
        "name": "Eduard",
        "surname": "Vardanyan",
        "dateOfBirth": "08 Sept. , 1985",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2043",
        "title": "Gekhaznik Tonoyan",
        "name": "Gekhaznik",
        "surname": "Tonoyan",
        "dateOfBirth": "23 Nov., 1990",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2042",
        "title": "Mkhitar Mkhitaryan",
        "name": "Mkhitar",
        "surname": "Mkhitaryan",
        "dateOfBirth": "04 May, 2002",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2041",
        "title": "Hakob Ohanyan",
        "name": "Hakob",
        "surname": "Ohanyan",
        "dateOfBirth": "03 Mar., 2002",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2440",
        "title": "Harut Babayan",
        "name": "Harut",
        "surname": "Babayan",
        "dateOfBirth": "03 Apr., 1991",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2439",
        "title": "Vahe Verdyan",
        "name": "Vahe",
        "surname": "Verdyan",
        "dateOfBirth": "27 Aug., 1994",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2438",
        "title": "Arshavir Torosyan",
        "name": "Arshavir",
        "surname": "Torosyan",
        "dateOfBirth": "18 May, 1993",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2437",
        "title": "Armen Shahnazaryan",
        "name": "Armen",
        "surname": "Shahnazaryan",
        "dateOfBirth": "27 Dec., 1986",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2436",
        "title": "Serob Melkonyan",
        "name": "Serob",
        "surname": "Melkonyan",
        "dateOfBirth": "18 Jan., 1993",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2435",
        "title": "Vahram Aydinyan",
        "name": "Vahram",
        "surname": "Aydinyan",
        "dateOfBirth": "04 May, 1973",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2434",
        "title": "Ashot Abrahamyan",
        "name": "Ashot",
        "surname": "Abrahamyan",
        "dateOfBirth": "10 Mar., 1985",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2433",
        "title": "Hambartsum Hakobyan",
        "name": "Hambartsum",
        "surname": "Hakobyan",
        "dateOfBirth": "18 Feb., 1971",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2432",
        "title": "Armen Aghajanyan",
        "name": "Armen",
        "surname": "Aghajanyan",
        "dateOfBirth": "13 May, 1977",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2431",
        "title": "Aram Beklaryan",
        "name": "Aram",
        "surname": "Beklaryan",
        "dateOfBirth": "22 Apr., 1969",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1950",
        "title": "Armen Grigoryan",
        "name": "Armen",
        "surname": "Grigoryan",
        "dateOfBirth": "26 May, 1990",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1949",
        "title": "Gor Grigoryan",
        "name": "Gor",
        "surname": "Grigoryan",
        "dateOfBirth": "17 Apr., 1999",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1948",
        "title": "Vahan Grigoryan",
        "name": "Vahan",
        "surname": "Grigoryan",
        "dateOfBirth": "22 Aug., 1999",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1947",
        "title": "Marat Hakobjanyan",
        "name": "Marat",
        "surname": "Hakobjanyan",
        "dateOfBirth": "26 Apr., 1982",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1946",
        "title": "Tigran Hakobjanyan",
        "name": "Tigran",
        "surname": "Hakobjanyan",
        "dateOfBirth": "28 Apr., 1978",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1945",
        "title": "Martin Saghyan",
        "name": "Martin",
        "surname": "Saghyan",
        "dateOfBirth": "27 July, 1978",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1944",
        "title": "Artur Ghahramanyan",
        "name": "Artur",
        "surname": "Ghahramanyan",
        "dateOfBirth": "26 Aug., 1985",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1943",
        "title": "Gurgen Shakaryan",
        "name": "Gurgen",
        "surname": "Shakaryan",
        "dateOfBirth": "19 Jan., 1991",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1942",
        "title": "Narek Manukyan",
        "name": "Narek",
        "surname": "Manukyan",
        "dateOfBirth": "02 Dec., 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1941",
        "title": "Khoren Khachatryan",
        "name": "Khoren",
        "surname": "Khachatryan",
        "dateOfBirth": "12 Sept. , 1983",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1920",
        "title": "Arsen Musayelyan",
        "name": "Arsen",
        "surname": "Musayelyan",
        "dateOfBirth": "23 May, 1979",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1919",
        "title": "Sasun Aleksanyan",
        "name": "Sasun",
        "surname": "Aleksanyan",
        "dateOfBirth": "24 Feb., 1992",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1918",
        "title": "Levon Hambaryan",
        "name": "Levon",
        "surname": "Hambaryan",
        "dateOfBirth": "17 Dec., 1992",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1917",
        "title": "Seyran Arustamyan",
        "name": "Seyran",
        "surname": "Arustamyan",
        "dateOfBirth": "03 Apr., 1973",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1916",
        "title": "Andranik Harutyunyan",
        "name": "Andranik",
        "surname": "Harutyunyan",
        "dateOfBirth": "06 July, 1968",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1915",
        "title": "David Osipyan",
        "name": "David",
        "surname": "Osipyan",
        "dateOfBirth": "26 Apr., 2002",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1914",
        "title": "Tigran Avanesyan",
        "name": "Tigran",
        "surname": "Avanesyan",
        "dateOfBirth": "07 Feb., 1987",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1913",
        "title": "Hunan Avetisyan",
        "name": "Hunan",
        "surname": "Avetisyan",
        "dateOfBirth": "02 Jan., 1972",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1912",
        "title": "Arman Aghababyan",
        "name": "Arman",
        "surname": "Aghababyan",
        "dateOfBirth": "27 Apr., 2001",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1911",
        "title": "Valery Aghajanyan",
        "name": "Valery",
        "surname": "Aghajanyan",
        "dateOfBirth": "22 Aug., 1976",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2430",
        "title": "Mkhitar Hovhannisyan",
        "name": "Mkhitar",
        "surname": "Hovhannisyan",
        "dateOfBirth": "04 July, 1982",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2429",
        "title": "Armen Barkhudaryan",
        "name": "Armen",
        "surname": "Barkhudaryan",
        "dateOfBirth": "08 Feb., 1989",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2428",
        "title": "Vrej Musaelyan",
        "name": "Vrej",
        "surname": "Musaelyan",
        "dateOfBirth": "23 Feb., 1987",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2427",
        "title": "Arman Beknazaryan",
        "name": "Arman",
        "surname": "Beknazaryan",
        "dateOfBirth": "04 Aug., 1982",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2426",
        "title": "Artashes Danielyan",
        "name": "Artashes",
        "surname": "Danielyan",
        "dateOfBirth": "01 Oct., 1974",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2425",
        "title": "Slava Davtyan",
        "name": "Slava",
        "surname": "Davtyan",
        "dateOfBirth": "10 Aug., 1976",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2424",
        "title": "Armen Dolukhanyan",
        "name": "Armen",
        "surname": "Dolukhanyan",
        "dateOfBirth": "15 Feb., 1983",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2423",
        "title": "Artur Gasparyan",
        "name": "Artur",
        "surname": "Gasparyan",
        "dateOfBirth": "22 May, 1987",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2422",
        "title": "Mher Mkrtchyan",
        "name": "Mher",
        "surname": "Mkrtchyan",
        "dateOfBirth": "28 Jan., 1976",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2421",
        "title": "Garik Mnatsakanyan",
        "name": "Garik",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "24 June, 1968",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1910",
        "title": "Nikolai Aghasyan",
        "name": "Nikolai",
        "surname": "Aghasyan",
        "dateOfBirth": "05 Mar., 1987",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1909",
        "title": "Arsen Ghazaryan",
        "name": "Arsen",
        "surname": "Ghazaryan",
        "dateOfBirth": "29 Aug., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1908",
        "title": "Karen Baghdasaryan",
        "name": "Karen",
        "surname": "Baghdasaryan",
        "dateOfBirth": "18 Mar., 1991",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1907",
        "title": "Monte Melkumyan",
        "name": "Monte",
        "surname": "Melkumyan",
        "dateOfBirth": "26 Mar., 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1906",
        "title": "Samvel Arushanyan",
        "name": "Samvel",
        "surname": "Arushanyan",
        "dateOfBirth": "08 May, 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1905",
        "title": "Robert Baghdasaryan",
        "name": "Robert",
        "surname": "Baghdasaryan",
        "dateOfBirth": "17 June, 2002",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1904",
        "title": "Armen Khachatryan",
        "name": "Armen",
        "surname": "Khachatryan",
        "dateOfBirth": "10 July, 1997",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1903",
        "title": "Garry Aslanyan",
        "name": "Garry",
        "surname": "Aslanyan",
        "dateOfBirth": "02 Jan., 1990",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1902",
        "title": "Hayk Bakhshyan",
        "name": "Hayk",
        "surname": "Bakhshyan",
        "dateOfBirth": "20 Aug., 1985",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1901",
        "title": "Arman Khachatryan",
        "name": "Arman",
        "surname": "Khachatryan",
        "dateOfBirth": "21 Jan., 1989",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2160",
        "title": "Tsovak Madatyan",
        "name": "Tsovak",
        "surname": "Madatyan",
        "dateOfBirth": "20 Jan., 1979",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2159",
        "title": "Sevak Movsisyan",
        "name": "Sevak",
        "surname": "Movsisyan",
        "dateOfBirth": "22 June, 1990",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2158",
        "title": "Aram Arzumanyan",
        "name": "Aram",
        "surname": "Arzumanyan",
        "dateOfBirth": "17 Sept. , 1986",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2157",
        "title": "Harutyun Asryan",
        "name": "Harutyun",
        "surname": "Asryan",
        "dateOfBirth": "11 Mar., 1998",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2156",
        "title": "Aghvan Khalatyan",
        "name": "Aghvan",
        "surname": "Khalatyan",
        "dateOfBirth": "19 Jan., 1996",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2155",
        "title": "Karen Gevorgyan",
        "name": "Karen",
        "surname": "Gevorgyan",
        "dateOfBirth": "28 May, 1998",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2154",
        "title": "Vahagn Ajatyan",
        "name": "Vahagn",
        "surname": "Ajatyan",
        "dateOfBirth": "31 July, 1991",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2153",
        "title": "Artur Aghajanyan",
        "name": "Artur",
        "surname": "Aghajanyan",
        "dateOfBirth": "27 May, 1982",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2152",
        "title": "Gevorg Gevorgyan",
        "name": "Gevorg",
        "surname": "Gevorgyan",
        "dateOfBirth": "25 Dec., 1994",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2151",
        "title": "Zhora Arzumanyan",
        "name": "Zhora",
        "surname": "Arzumanyan",
        "dateOfBirth": "08 Feb., 1991",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2080",
        "title": "Karapet Tadevosyan",
        "name": "Karapet",
        "surname": "Tadevosyan",
        "dateOfBirth": "22 Feb., 1999",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2079",
        "title": "Kamo Ghambaryan",
        "name": "Kamo",
        "surname": "Ghambaryan",
        "dateOfBirth": "29 Sept. , 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2078",
        "title": "Aren Mirzoyan",
        "name": "Aren",
        "surname": "Mirzoyan",
        "dateOfBirth": "02 Jan., 2002",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2077",
        "title": "Gegham Avetisyan",
        "name": "Gegham",
        "surname": "Avetisyan",
        "dateOfBirth": "04 May, 1971",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2076",
        "title": "Narek Babayan",
        "name": "Narek",
        "surname": "Babayan",
        "dateOfBirth": "13 July, 1994",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2075",
        "title": "Sergey Balbabyan",
        "name": "Sergey",
        "surname": "Balbabyan",
        "dateOfBirth": "30 Dec., 1982",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2074",
        "title": "Alen Beglarian",
        "name": "Alen",
        "surname": "Beglarian",
        "dateOfBirth": "01 Feb., 2000",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2073",
        "title": "Hovhannes Arakelyan",
        "name": "Hovhannes",
        "surname": "Arakelyan",
        "dateOfBirth": "06 Sept. , 1992",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2072",
        "title": "Artyom Arustamyan",
        "name": "Artyom",
        "surname": "Arustamyan",
        "dateOfBirth": "09 Nov., 1983",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2071",
        "title": "Vladimir Avanesyan",
        "name": "Vladimir",
        "surname": "Avanesyan",
        "dateOfBirth": "12 Apr., 1994",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2090",
        "title": "Pargev Gasparyan",
        "name": "Pargev",
        "surname": "Gasparyan",
        "dateOfBirth": "27 Oct., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2089",
        "title": "Vahagn Gevorgyan",
        "name": "Vahagn",
        "surname": "Gevorgyan",
        "dateOfBirth": "06 Apr., 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2088",
        "title": "Narek Ginosyan",
        "name": "Narek",
        "surname": "Ginosyan",
        "dateOfBirth": "29 July, 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2087",
        "title": "Karen Grigoryan",
        "name": "Karen",
        "surname": "Grigoryan",
        "dateOfBirth": "26 June, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2086",
        "title": "Mayis Grigoryan",
        "name": "Mayis",
        "surname": "Grigoryan",
        "dateOfBirth": "08 Nov., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2085",
        "title": "Serj Hakobkekhvyan",
        "name": "Serj",
        "surname": "Hakobkekhvyan",
        "dateOfBirth": "29 Sept. , 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2084",
        "title": "Artsrun Hakobyan",
        "name": "Artsrun",
        "surname": "Hakobyan",
        "dateOfBirth": "21 July, 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2083",
        "title": "Artyem Hakobyan",
        "name": "Artyem",
        "surname": "Hakobyan",
        "dateOfBirth": "26 Jan., 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2082",
        "title": "Gevorg Hakobyan",
        "name": "Gevorg",
        "surname": "Hakobyan",
        "dateOfBirth": "19 Oct., 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2081",
        "title": "Shant Tovmasyan",
        "name": "Shant",
        "surname": "Tovmasyan",
        "dateOfBirth": "06 Feb., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2100",
        "title": "Garegin Nikolyan",
        "name": "Garegin",
        "surname": "Nikolyan",
        "dateOfBirth": "02 Feb., 2000",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2099",
        "title": "Kolya Nikoghosyan",
        "name": "Kolya",
        "surname": "Nikoghosyan",
        "dateOfBirth": "04 Apr., 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2098",
        "title": "Tigran Pandunts",
        "name": "Tigran",
        "surname": "Pandunts",
        "dateOfBirth": "22 Feb., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2097",
        "title": "Martiros Barseghyan",
        "name": "Martiros",
        "surname": "Barseghyan",
        "dateOfBirth": "07 Feb., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2096",
        "title": "Sergey Benyaminyan",
        "name": "Sergey",
        "surname": "Benyaminyan",
        "dateOfBirth": "28 Nov., 1997",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2095",
        "title": "Hakob Bogharyan",
        "name": "Hakob",
        "surname": "Bogharyan",
        "dateOfBirth": "02 Jan., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2094",
        "title": "Suren Demirchyan",
        "name": "Suren",
        "surname": "Demirchyan",
        "dateOfBirth": "07 July, 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2093",
        "title": "Hayk Derikyan",
        "name": "Hayk",
        "surname": "Derikyan",
        "dateOfBirth": "30 Jan., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2092",
        "title": "Sergey Yedigaryan",
        "name": "Sergey",
        "surname": "Yedigaryan",
        "dateOfBirth": "29 Oct., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2091",
        "title": "Hayk Gasparyan",
        "name": "Hayk",
        "surname": "Gasparyan",
        "dateOfBirth": "21 Sept. , 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "560",
        "title": "Karen Musikyan",
        "name": "Karen",
        "surname": "Musikyan",
        "dateOfBirth": "19 Aug., 1987",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "559",
        "title": "Arsen Muradyan",
        "name": "Arsen",
        "surname": "Muradyan",
        "dateOfBirth": "20 Jan., 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "558",
        "title": "Moushegh Mnoyan",
        "name": "Moushegh",
        "surname": "Mnoyan",
        "dateOfBirth": "24 Oct., 1966",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "557",
        "title": "Arsen Karapetyan",
        "name": "Arsen",
        "surname": "Karapetyan",
        "dateOfBirth": "14 Jan., 1965",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "556",
        "title": "Aghasi Mikaelyan",
        "name": "Aghasi",
        "surname": "Mikaelyan",
        "dateOfBirth": "24 Nov., 1992",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "555",
        "title": "Ishkhan Manoukyan",
        "name": "Ishkhan",
        "surname": "Manoukyan",
        "dateOfBirth": "19 Feb., 2002",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "554",
        "title": "Arsham Manoukyan",
        "name": "Arsham",
        "surname": "Manoukyan",
        "dateOfBirth": "19 Oct., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "553",
        "title": "Victor Isunts",
        "name": "Victor",
        "surname": "Isunts",
        "dateOfBirth": "01 Mar., 1949",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "552",
        "title": "Hrant Petrosyan",
        "name": "Hrant",
        "surname": "Petrosyan",
        "dateOfBirth": "15 Dec., 1974",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "551",
        "title": "Alexey Babadjanyan",
        "name": "Alexey",
        "surname": "Babadjanyan",
        "dateOfBirth": "17 Feb., 1978",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1990",
        "title": "Rafik Simonyan",
        "name": "Rafik",
        "surname": "Simonyan",
        "dateOfBirth": "28 Nov., 1994",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1989",
        "title": "Vardges Minasyan",
        "name": "Vardges",
        "surname": "Minasyan",
        "dateOfBirth": "16 June, 1994",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1988",
        "title": "VaHram Srapyan",
        "name": "VaHram",
        "surname": "Srapyan",
        "dateOfBirth": "09 Nov., 1992",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1987",
        "title": "Suren Babayan",
        "name": "Suren",
        "surname": "Babayan",
        "dateOfBirth": "03 Apr., 1989",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1986",
        "title": "Albert Bagiyants",
        "name": "Albert",
        "surname": "Bagiyants",
        "dateOfBirth": "23 Aug., 1976",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1985",
        "title": "Apaven Barseghyan",
        "name": "Apaven",
        "surname": "Barseghyan",
        "dateOfBirth": "09 Aug., 1983",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1984",
        "title": "Armen Abrahamyan",
        "name": "Armen",
        "surname": "Abrahamyan",
        "dateOfBirth": "28 July, 1973",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1983",
        "title": "Hovik Babayan",
        "name": "Hovik",
        "surname": "Babayan",
        "dateOfBirth": "21 Nov., 1980",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1982",
        "title": "Vrej Barseghyan",
        "name": "Vrej",
        "surname": "Barseghyan",
        "dateOfBirth": "23 Feb., 1973",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1981",
        "title": "Gevorg Berakchyan",
        "name": "Gevorg",
        "surname": "Berakchyan",
        "dateOfBirth": "09 Aug., 1982",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1450",
        "title": "Roman Grigoryan",
        "name": "Roman",
        "surname": "Grigoryan",
        "dateOfBirth": "14 July, 1982",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1449",
        "title": "Grigor Grigoryan",
        "name": "Grigor",
        "surname": "Grigoryan",
        "dateOfBirth": "27 Aug., 1992",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1448",
        "title": "Vladik Gevorgyan",
        "name": "Vladik",
        "surname": "Gevorgyan",
        "dateOfBirth": "27 July, 1988",
        "dateOfDeath": "26Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1447",
        "title": "Boris Gasparyan",
        "name": "Boris",
        "surname": "Gasparyan",
        "dateOfBirth": "21 Oct., 1976",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1446",
        "title": "Artur Babayan",
        "name": "Artur",
        "surname": "Babayan",
        "dateOfBirth": "01 May, 1985",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1445",
        "title": "Armen Zohrabyan",
        "name": "Armen",
        "surname": "Zohrabyan",
        "dateOfBirth": "03 Dec., 1972",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1444",
        "title": "Sevada Sargsyan",
        "name": "Sevada",
        "surname": "Sargsyan",
        "dateOfBirth": "28 Sept. , 1980",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1443",
        "title": "Vruyr Ghukusyan",
        "name": "Vruyr",
        "surname": "Ghukusyan",
        "dateOfBirth": "21 May, 1976",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1442",
        "title": "Norayr Sargsyan",
        "name": "Norayr",
        "surname": "Sargsyan",
        "dateOfBirth": "13 Feb., 1967",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1441",
        "title": "Davit Khnusyan",
        "name": "Davit",
        "surname": "Khnusyan",
        "dateOfBirth": "15 June, 1995",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2030",
        "title": "Hayk Vardanyan",
        "name": "Hayk",
        "surname": "Vardanyan",
        "dateOfBirth": "21 Sept. , 1991",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2029",
        "title": "Paylak Yeghiazaryan",
        "name": "Paylak",
        "surname": "Yeghiazaryan",
        "dateOfBirth": "31 July, 1995",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2028",
        "title": "Robert Gevorgyan",
        "name": "Robert",
        "surname": "Gevorgyan",
        "dateOfBirth": "29 Nov., 1982",
        "dateOfDeath": "29Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2027",
        "title": "Babken Grigoryan",
        "name": "Babken",
        "surname": "Grigoryan",
        "dateOfBirth": "02 July, 1991",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2026",
        "title": "Narek Grigoryan",
        "name": "Narek",
        "surname": "Grigoryan",
        "dateOfBirth": "17 Feb., 1992",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2025",
        "title": "Arsen Gulzatyan",
        "name": "Arsen",
        "surname": "Gulzatyan",
        "dateOfBirth": "24 Sept. , 1987",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2024",
        "title": "Artsakh Hovhannisyan",
        "name": "Artsakh",
        "surname": "Hovhannisyan",
        "dateOfBirth": "25 Aug., 1994",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2023",
        "title": "Hamlet Khachatryan",
        "name": "Hamlet",
        "surname": "Khachatryan",
        "dateOfBirth": "27 June, 1978",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2022",
        "title": "Shahvalad Nikoyan",
        "name": "Shahvalad",
        "surname": "Nikoyan",
        "dateOfBirth": "02 Nov., 1960",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2021",
        "title": "Vardan Sahakyan",
        "name": "Vardan",
        "surname": "Sahakyan",
        "dateOfBirth": "09 Aug., 1972",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2280",
        "title": "Arshak Hovsepyan",
        "name": "Arshak",
        "surname": "Hovsepyan",
        "dateOfBirth": "25 Jan., 1985",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2279",
        "title": "Suren Hovsepyan",
        "name": "Suren",
        "surname": "Hovsepyan",
        "dateOfBirth": "17 June, 1979",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2278",
        "title": "Ruben Kirakosyan",
        "name": "Ruben",
        "surname": "Kirakosyan",
        "dateOfBirth": "12 Oct., 2020",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2277",
        "title": "Grigor Kotsinyan",
        "name": "Grigor",
        "surname": "Kotsinyan",
        "dateOfBirth": "11 May, 1964",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2276",
        "title": "Ararat Manukyan",
        "name": "Ararat",
        "surname": "Manukyan",
        "dateOfBirth": "28 Nov., 1971",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2275",
        "title": "Vladislav Atayan",
        "name": "Vladislav",
        "surname": "Atayan",
        "dateOfBirth": "09 Dec., 1980",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2274",
        "title": "Sargis Avetisyan",
        "name": "Sargis",
        "surname": "Avetisyan",
        "dateOfBirth": "24 Nov., 1993",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2273",
        "title": "Masis Dunamalyan",
        "name": "Masis",
        "surname": "Dunamalyan",
        "dateOfBirth": "30 Mar., 1990",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2272",
        "title": "Sargis Naghdalyan",
        "name": "Sargis",
        "surname": "Naghdalyan",
        "dateOfBirth": "08 Aug., 1988",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2271",
        "title": "Artem Kolandjyan",
        "name": "Artem",
        "surname": "Kolandjyan",
        "dateOfBirth": "22 Sept. , 1993",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1930",
        "title": "Harutyun Ghazaryan",
        "name": "Harutyun",
        "surname": "Ghazaryan",
        "dateOfBirth": "17 June, 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1929",
        "title": "Tigran Yarmaloyan",
        "name": "Tigran",
        "surname": "Yarmaloyan",
        "dateOfBirth": "04 June, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1928",
        "title": "Artak Zohrabyan",
        "name": "Artak",
        "surname": "Zohrabyan",
        "dateOfBirth": "11 Dec., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1927",
        "title": "Albert Musaelyan",
        "name": "Albert",
        "surname": "Musaelyan",
        "dateOfBirth": "08 Dec., 1980",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1926",
        "title": "Areg Azaryan",
        "name": "Areg",
        "surname": "Azaryan",
        "dateOfBirth": "09 May, 1988",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1925",
        "title": "Gurgen Abrahamyan",
        "name": "Gurgen",
        "surname": "Abrahamyan",
        "dateOfBirth": "14 Dec., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1924",
        "title": "Hayk Abrahamyan",
        "name": "Hayk",
        "surname": "Abrahamyan",
        "dateOfBirth": "24 Aug., 1988",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1923",
        "title": "Karen Aleksanyan",
        "name": "Karen",
        "surname": "Aleksanyan",
        "dateOfBirth": "27 Nov., 1980",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1922",
        "title": "Samvel Hakobyan",
        "name": "Samvel",
        "surname": "Hakobyan",
        "dateOfBirth": "19 Aug., 1971",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1921",
        "title": "Sasun Suleimanyan",
        "name": "Sasun",
        "surname": "Suleimanyan",
        "dateOfBirth": "01 July, 1981",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2150",
        "title": "Mher Martirosyan",
        "name": "Mher",
        "surname": "Martirosyan",
        "dateOfBirth": "16 Oct., 1989",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2149",
        "title": "Vardan Mirzakhanyan",
        "name": "Vardan",
        "surname": "Mirzakhanyan",
        "dateOfBirth": "27 Aug., 1993",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2148",
        "title": "Artur Sahakyan",
        "name": "Artur",
        "surname": "Sahakyan",
        "dateOfBirth": "17 Sept. , 1989",
        "dateOfDeath": "17Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2147",
        "title": "Gor Saribekyan",
        "name": "Gor",
        "surname": "Saribekyan",
        "dateOfBirth": "25 Apr., 1998",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2146",
        "title": "Seryozha Virabyan",
        "name": "Seryozha",
        "surname": "Virabyan",
        "dateOfBirth": "23 Oct., 1995",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2145",
        "title": "Suren Khachatryan",
        "name": "Suren",
        "surname": "Khachatryan",
        "dateOfBirth": "27 Nov., 1979",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2144",
        "title": "Robert Petrosyan",
        "name": "Robert",
        "surname": "Petrosyan",
        "dateOfBirth": "05 Oct., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2143",
        "title": "Sasun Petrosyan",
        "name": "Sasun",
        "surname": "Petrosyan",
        "dateOfBirth": "06 June, 2001",
        "dateOfDeath": "27Dec.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2142",
        "title": "Sergey Petrosyan",
        "name": "Sergey",
        "surname": "Petrosyan",
        "dateOfBirth": "16 Aug., 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2141",
        "title": "Sos Porqashyan",
        "name": "Sos",
        "surname": "Porqashyan",
        "dateOfBirth": "03 Feb., 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1960",
        "title": "Armen Mangasaryan",
        "name": "Armen",
        "surname": "Mangasaryan",
        "dateOfBirth": "25 Jan., 1976",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1959",
        "title": "Andranik Lalayan",
        "name": "Andranik",
        "surname": "Lalayan",
        "dateOfBirth": "23 Apr., 1990",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1958",
        "title": "Yuri Hayrapetyan",
        "name": "Yuri",
        "surname": "Hayrapetyan",
        "dateOfBirth": "25 Aug., 1997",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1957",
        "title": "David Hambardzumyan",
        "name": "David",
        "surname": "Hambardzumyan",
        "dateOfBirth": "19 Dec., 1998",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1956",
        "title": "Alen Avagyan",
        "name": "Alen",
        "surname": "Avagyan",
        "dateOfBirth": "01 Mar., 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1955",
        "title": "Aleksandr Farmanyan",
        "name": "Aleksandr",
        "surname": "Farmanyan",
        "dateOfBirth": "18 Nov., 1996",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1954",
        "title": "Gagik Farmanyan",
        "name": "Gagik",
        "surname": "Farmanyan",
        "dateOfBirth": "23 June, 1975",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1953",
        "title": "Sevak Galstyan",
        "name": "Sevak",
        "surname": "Galstyan",
        "dateOfBirth": "14 Oct., 1986",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1952",
        "title": "Vahe Gasparyan",
        "name": "Vahe",
        "surname": "Gasparyan",
        "dateOfBirth": "01 Apr., 1988",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1951",
        "title": "Armen Gevorgyan",
        "name": "Armen",
        "surname": "Gevorgyan",
        "dateOfBirth": "12 Nov., 1979",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2180",
        "title": "David Khachatryan",
        "name": "David",
        "surname": "Khachatryan",
        "dateOfBirth": "17 July, 2001",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2179",
        "title": "Suren Vagharshakyan",
        "name": "Suren",
        "surname": "Vagharshakyan",
        "dateOfBirth": "14 Apr., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2178",
        "title": "Narek Varzhapetyan",
        "name": "Narek",
        "surname": "Varzhapetyan",
        "dateOfBirth": "03 Nov., 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2177",
        "title": "Sargis Vardanyan",
        "name": "Sargis",
        "surname": "Vardanyan",
        "dateOfBirth": "11 Nov., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2176",
        "title": "Hovhannes Torosyan",
        "name": "Hovhannes",
        "surname": "Torosyan",
        "dateOfBirth": "10 May, 2002",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2175",
        "title": "Raphael Nahapetyan",
        "name": "Raphael",
        "surname": "Nahapetyan",
        "dateOfBirth": "10 Sept. , 1991",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2174",
        "title": "Simon Avagyan",
        "name": "Simon",
        "surname": "Avagyan",
        "dateOfBirth": "06 Sept. , 1972",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2173",
        "title": "Hamlet Mkoyan",
        "name": "Hamlet",
        "surname": "Mkoyan",
        "dateOfBirth": "30 Jan., 1978",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2172",
        "title": "Vazgen Grigoryan",
        "name": "Vazgen",
        "surname": "Grigoryan",
        "dateOfBirth": "12 Aug., 1976",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2171",
        "title": "Vahe Ghazaryan",
        "name": "Vahe",
        "surname": "Ghazaryan",
        "dateOfBirth": "26 May, 1977",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2360",
        "title": "Anatoly Harutyunyan",
        "name": "Anatoly",
        "surname": "Harutyunyan",
        "dateOfBirth": "27 Feb., 1976",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2359",
        "title": "Slava Movsesyan",
        "name": "Slava",
        "surname": "Movsesyan",
        "dateOfBirth": "09 Apr., 1978",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2358",
        "title": "Gurgen Mnatsakanyan",
        "name": "Gurgen",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "22 July, 1990",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2357",
        "title": "Boris Paramazyan",
        "name": "Boris",
        "surname": "Paramazyan",
        "dateOfBirth": "03 July, 1998",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2356",
        "title": "Erik Akhumyan",
        "name": "Erik",
        "surname": "Akhumyan",
        "dateOfBirth": "26 Feb., 1992",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2355",
        "title": "Nelson Petrosyan",
        "name": "Nelson",
        "surname": "Petrosyan",
        "dateOfBirth": "19 Mar., 2000",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2354",
        "title": "Armen Grigoryan",
        "name": "Armen",
        "surname": "Grigoryan",
        "dateOfBirth": "06 Sept. , 1984",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2353",
        "title": "Kajik Karamyan",
        "name": "Kajik",
        "surname": "Karamyan",
        "dateOfBirth": "04 Aug., 1991",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2352",
        "title": "Mikael Hakobyan",
        "name": "Mikael",
        "surname": "Hakobyan",
        "dateOfBirth": "29 Nov., 1999",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2351",
        "title": "Marat Safaryan",
        "name": "Marat",
        "surname": "Safaryan",
        "dateOfBirth": "20 Apr., 1982",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1850",
        "title": "Arsen Harutyunyan",
        "name": "Arsen",
        "surname": "Harutyunyan",
        "dateOfBirth": "23 Jan., 1983",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1849",
        "title": "Narek Artushyan",
        "name": "Narek",
        "surname": "Artushyan",
        "dateOfBirth": "10 Sept. , 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1848",
        "title": "Mikael Arstamyan",
        "name": "Mikael",
        "surname": "Arstamyan",
        "dateOfBirth": "05 July, 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1847",
        "title": "Vahe Asatryan",
        "name": "Vahe",
        "surname": "Asatryan",
        "dateOfBirth": "12 Nov., 2001",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1846",
        "title": "Ashot Dallakyan",
        "name": "Ashot",
        "surname": "Dallakyan",
        "dateOfBirth": "06 May, 1981",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1845",
        "title": "Aren Avanesyan",
        "name": "Aren",
        "surname": "Avanesyan",
        "dateOfBirth": "03 Nov., 2000",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1844",
        "title": "Benik Hambardzumyan",
        "name": "Benik",
        "surname": "Hambardzumyan",
        "dateOfBirth": "20 June, 1986",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1843",
        "title": "Hrayr Danielyan",
        "name": "Hrayr",
        "surname": "Danielyan",
        "dateOfBirth": "22 July, 1997",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1842",
        "title": "Garik Dolukhanyan",
        "name": "Garik",
        "surname": "Dolukhanyan",
        "dateOfBirth": "28 Oct., 1992",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1841",
        "title": "Gagik Petrosyan",
        "name": "Gagik",
        "surname": "Petrosyan",
        "dateOfBirth": "10 Dec., 1982",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1710",
        "title": "Վահե Հովհաննեսի Բաղդասարյան",
        "name": "Վահե",
        "surname": "Հովհաննեսի Բաղդասարյան",
        "dateOfBirth": "19 Sept. , 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1709",
        "title": "Արման Բուլղադարյան Գագիկի",
        "name": "Արման",
        "surname": "Բուլղադարյան Գագիկի",
        "dateOfBirth": "08 Oct., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1708",
        "title": "Լևոն Չատինյան Վահեի",
        "name": "Լևոն",
        "surname": "Չատինյան Վահեի",
        "dateOfBirth": "28 Sept. , 2000",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1707",
        "title": "Արտակ Դարբինյան Էդվարդի",
        "name": "Արտակ",
        "surname": "Դարբինյան Էդվարդի",
        "dateOfBirth": "05 Aug., 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1706",
        "title": "Գագիկ Դիլանյան Էդվարդի",
        "name": "Գագիկ",
        "surname": "Դիլանյան Էդվարդի",
        "dateOfBirth": "12 May, 2000",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1705",
        "title": "Գնել  Գաբրիելյան Կարապետի",
        "name": "Գնել",
        "surname": " Գաբրիելյան Կարապետի",
        "dateOfBirth": "04 Sept. , 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1704",
        "title": "Էրիկ  Գալստյան Սայաթի",
        "name": "Էրիկ",
        "surname": " Գալստյան Սայաթի",
        "dateOfBirth": "31 Oct., 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1703",
        "title": "Գառնիկ Գասպարյան Արտակի",
        "name": "Գառնիկ",
        "surname": "Գասպարյան Արտակի",
        "dateOfBirth": "12 Jan., 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1702",
        "title": "Արշակ Ղազարյան Կարենի",
        "name": "Արշակ",
        "surname": "Ղազարյան Կարենի",
        "dateOfBirth": "20 Feb., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1701",
        "title": "Էլֆիկ Գրիգորյան Գագիկի",
        "name": "Էլֆիկ",
        "surname": "Գրիգորյան Գագիկի",
        "dateOfBirth": "03 Sept. , 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "860",
        "title": "Hayk Tunyan",
        "name": "Hayk",
        "surname": "Tunyan",
        "dateOfBirth": "06 Feb., 2002",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "859",
        "title": "Artyom Tumanyan",
        "name": "Artyom",
        "surname": "Tumanyan",
        "dateOfBirth": "08 Dec., 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "858",
        "title": "Hovhannes Titizyan",
        "name": "Hovhannes",
        "surname": "Titizyan",
        "dateOfBirth": "11 Sept. , 1999",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "857",
        "title": "Artak Sukiasyan",
        "name": "Artak",
        "surname": "Sukiasyan",
        "dateOfBirth": "04 Apr., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "856",
        "title": "Tigran Mikoyan",
        "name": "Tigran",
        "surname": "Mikoyan",
        "dateOfBirth": "21 Jan., 1997",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "855",
        "title": "Garik Manukyan",
        "name": "Garik",
        "surname": "Manukyan",
        "dateOfBirth": "31 July, 1994",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "854",
        "title": "Abraham Sargsyan",
        "name": "Abraham",
        "surname": "Sargsyan",
        "dateOfBirth": "04 May, 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "853",
        "title": "Vardan Grigoryan",
        "name": "Vardan",
        "surname": "Grigoryan",
        "dateOfBirth": "03 May, 2002",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "852",
        "title": "John Sahakyan",
        "name": "John",
        "surname": "Sahakyan",
        "dateOfBirth": "23 Mar., 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "851",
        "title": "Vanik Grigoryan",
        "name": "Vanik",
        "surname": "Grigoryan",
        "dateOfBirth": "01 July, 1984",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1630",
        "title": "Հարություն Հարությունյան Տիգրանի",
        "name": "Հարություն",
        "surname": "Հարությունյան Տիգրանի",
        "dateOfBirth": "07 Jan., 2002",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1629",
        "title": "Ռազմիկ Թանդիլյան Կարենի",
        "name": "Ռազմիկ",
        "surname": "Թանդիլյան Կարենի",
        "dateOfBirth": "06 July, 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1628",
        "title": "Սարգիս Թոմբուլյան Հակոբի",
        "name": "Սարգիս",
        "surname": "Թոմբուլյան Հակոբի",
        "dateOfBirth": "30 Dec., 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1627",
        "title": "Գառնիկ Հովհաննիսյան Կարենի",
        "name": "Գառնիկ",
        "surname": "Հովհաննիսյան Կարենի",
        "dateOfBirth": "08 Oct., 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1626",
        "title": "Կարեն Թորոսյան Արայիկի",
        "name": "Կարեն",
        "surname": "Թորոսյան Արայիկի",
        "dateOfBirth": "22 June, 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1625",
        "title": "Արտյոմ Վարդանյան Վարդանի",
        "name": "Արտյոմ",
        "surname": "Վարդանյան Վարդանի",
        "dateOfBirth": "09 Jan., 2002",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1624",
        "title": "Հարություն  Հովհաննիսյան Հայկի",
        "name": "Հարություն",
        "surname": " Հովհաննիսյան Հայկի",
        "dateOfBirth": "24 Dec., 2000",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1623",
        "title": "Կարեն Հովհաննիսյան Գրիգորի",
        "name": "Կարեն",
        "surname": "Հովհաննիսյան Գրիգորի",
        "dateOfBirth": "01 Feb., 2002",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1622",
        "title": "Վլադիմիր  Տիգրանի  Իսախանյան",
        "name": "Վլադիմիր",
        "surname": " Տիգրանի  Իսախանյան",
        "dateOfBirth": "15 Jan., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1621",
        "title": "Երանոս Պետրոսյան Ռուզվելտի",
        "name": "Երանոս",
        "surname": "Պետրոսյան Ռուզվելտի",
        "dateOfBirth": "27 June, 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2060",
        "title": "Avag Melikyan",
        "name": "Avag",
        "surname": "Melikyan",
        "dateOfBirth": "20 Feb., 1978",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2059",
        "title": "Narek Mirzoyan",
        "name": "Narek",
        "surname": "Mirzoyan",
        "dateOfBirth": "27 July, 1995",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2058",
        "title": "David Mkrtchyan",
        "name": "David",
        "surname": "Mkrtchyan",
        "dateOfBirth": "26 May, 1992",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2057",
        "title": "Movses Movsisyan",
        "name": "Movses",
        "surname": "Movsisyan",
        "dateOfBirth": "06 Mar., 1998",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2056",
        "title": "Garik Nersisyan",
        "name": "Garik",
        "surname": "Nersisyan",
        "dateOfBirth": "12 Feb., 1978",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2055",
        "title": "Artur Hambartsumyan",
        "name": "Artur",
        "surname": "Hambartsumyan",
        "dateOfBirth": "01 Oct., 1978",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2054",
        "title": "Aghasi Harutyunyan",
        "name": "Aghasi",
        "surname": "Harutyunyan",
        "dateOfBirth": "11 Nov., 1968",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2053",
        "title": "Zaven Yesayan",
        "name": "Zaven",
        "surname": "Yesayan",
        "dateOfBirth": "21 July, 2000",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2052",
        "title": "Mikayel Hovsepyan",
        "name": "Mikayel",
        "surname": "Hovsepyan",
        "dateOfBirth": "05 Aug., 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2051",
        "title": "Ashot Harutyunyan",
        "name": "Ashot",
        "surname": "Harutyunyan",
        "dateOfBirth": "06 Dec., 1977",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2350",
        "title": "Aram Sargsyan",
        "name": "Aram",
        "surname": "Sargsyan",
        "dateOfBirth": "03 Jan., 1986",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2349",
        "title": "Misha Arakelyan",
        "name": "Misha",
        "surname": "Arakelyan",
        "dateOfBirth": "17 July, 1995",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2348",
        "title": "Mkhitar Arustamyan",
        "name": "Mkhitar",
        "surname": "Arustamyan",
        "dateOfBirth": "26 Nov., 1977",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2347",
        "title": "Hayaser Hovsepyan",
        "name": "Hayaser",
        "surname": "Hovsepyan",
        "dateOfBirth": "16 Sept. , 1989",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2346",
        "title": "Spartak Hakobyan",
        "name": "Spartak",
        "surname": "Hakobyan",
        "dateOfBirth": "06 Nov., 1983",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2345",
        "title": "Genady Askaryan",
        "name": "Genady",
        "surname": "Askaryan",
        "dateOfBirth": "29 May, 1984",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2344",
        "title": "Emil Hovsepyan",
        "name": "Emil",
        "surname": "Hovsepyan",
        "dateOfBirth": "26 June, 1986",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2343",
        "title": "Mkhitar Hovsepyan",
        "name": "Mkhitar",
        "surname": "Hovsepyan",
        "dateOfBirth": "11 May, 1986",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2342",
        "title": "Edgar Mangasaryan",
        "name": "Edgar",
        "surname": "Mangasaryan",
        "dateOfBirth": "19 July, 1992",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2341",
        "title": "Edvard Khachatryan",
        "name": "Edvard",
        "surname": "Khachatryan",
        "dateOfBirth": "26 Aug., 1967",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2020",
        "title": "Narek Sargsyan",
        "name": "Narek",
        "surname": "Sargsyan",
        "dateOfBirth": "06 Aug., 1991",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2019",
        "title": "Vardan Simonyan",
        "name": "Vardan",
        "surname": "Simonyan",
        "dateOfBirth": "01 Jan., 1969",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2018",
        "title": "David Hakobyan",
        "name": "David",
        "surname": "Hakobyan",
        "dateOfBirth": "22 Feb., 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2017",
        "title": "Spartak Hakobyan",
        "name": "Spartak",
        "surname": "Hakobyan",
        "dateOfBirth": "16 Mar., 2002",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2016",
        "title": "Arkady Ghazaryan",
        "name": "Arkady",
        "surname": "Ghazaryan",
        "dateOfBirth": "06 Oct., 1977",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2015",
        "title": "Ghambar Khachatryan",
        "name": "Ghambar",
        "surname": "Khachatryan",
        "dateOfBirth": "23 Feb., 1980",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2014",
        "title": "Ivan Sargsyan",
        "name": "Ivan",
        "surname": "Sargsyan",
        "dateOfBirth": "04 Sept. , 1985",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2013",
        "title": "Gevorg Harutyunyan",
        "name": "Gevorg",
        "surname": "Harutyunyan",
        "dateOfBirth": "22 Oct., 1970",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2012",
        "title": "Armen Ayvazyan",
        "name": "Armen",
        "surname": "Ayvazyan",
        "dateOfBirth": "29 July, 1984",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2011",
        "title": "Vruyr Antonyan",
        "name": "Vruyr",
        "surname": "Antonyan",
        "dateOfBirth": "03 Mar., 1977",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2070",
        "title": "Vahik Avanesyan",
        "name": "Vahik",
        "surname": "Avanesyan",
        "dateOfBirth": "15 Sept. , 1992",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2069",
        "title": "Daniel Avetisyan",
        "name": "Daniel",
        "surname": "Avetisyan",
        "dateOfBirth": "08 Feb., 1969",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2068",
        "title": "Andranik Hakobyan",
        "name": "Andranik",
        "surname": "Hakobyan",
        "dateOfBirth": "09 Apr., 1996",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2067",
        "title": "Hakob Hakobyan",
        "name": "Hakob",
        "surname": "Hakobyan",
        "dateOfBirth": "15 Aug., 1992",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2066",
        "title": "Gor Hambartsumyan",
        "name": "Gor",
        "surname": "Hambartsumyan",
        "dateOfBirth": "09 Feb., 1998",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2065",
        "title": "Narek Hovhannisyan",
        "name": "Narek",
        "surname": "Hovhannisyan",
        "dateOfBirth": "16 May, 1997",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2064",
        "title": "Vahram Hovhannisyan",
        "name": "Vahram",
        "surname": "Hovhannisyan",
        "dateOfBirth": "22 Apr., 1978",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2063",
        "title": "Artur Ishkhanyan",
        "name": "Artur",
        "surname": "Ishkhanyan",
        "dateOfBirth": "01 Oct., 1997",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2062",
        "title": "Ashot Karapetyan",
        "name": "Ashot",
        "surname": "Karapetyan",
        "dateOfBirth": "05 Aug., 1979",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2061",
        "title": "Arman Margaryan",
        "name": "Arman",
        "surname": "Margaryan",
        "dateOfBirth": "05 Mar., 1985",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1400",
        "title": "Vahe Hovakimyan",
        "name": "Vahe",
        "surname": "Hovakimyan",
        "dateOfBirth": "24 Sept. , 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1399",
        "title": "Garnik Hakobyan",
        "name": "Garnik",
        "surname": "Hakobyan",
        "dateOfBirth": "23 June, 1994",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1398",
        "title": "Vagharshak Muradyan",
        "name": "Vagharshak",
        "surname": "Muradyan",
        "dateOfBirth": "03 July, 1989",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1397",
        "title": "Rafayel Geghamyan",
        "name": "Rafayel",
        "surname": "Geghamyan",
        "dateOfBirth": "17 Sept. , 1992",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1396",
        "title": "Aharon Movsisyan",
        "name": "Aharon",
        "surname": "Movsisyan",
        "dateOfBirth": "15 Nov., 1989",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1395",
        "title": "Sargis Mkrtchyan",
        "name": "Sargis",
        "surname": "Mkrtchyan",
        "dateOfBirth": "06 Oct., 1994",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1394",
        "title": "Mher Zurabyan",
        "name": "Mher",
        "surname": "Zurabyan",
        "dateOfBirth": "30 Sept. , 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1393",
        "title": "Garnik Zeynalyan",
        "name": "Garnik",
        "surname": "Zeynalyan",
        "dateOfBirth": "24 Sept. , 2001",
        "dateOfDeath": "15Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1392",
        "title": "Mher Ghukasyan",
        "name": "Mher",
        "surname": "Ghukasyan",
        "dateOfBirth": "17 July, 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1391",
        "title": "Alik Khudoyan",
        "name": "Alik",
        "surname": "Khudoyan",
        "dateOfBirth": "14 Jan., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2380",
        "title": "Nairi Abrahamyan",
        "name": "Nairi",
        "surname": "Abrahamyan",
        "dateOfBirth": "30 Sept. , 1998",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2379",
        "title": "Seyran Abrahamyan",
        "name": "Seyran",
        "surname": "Abrahamyan",
        "dateOfBirth": "26 May, 1995",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2378",
        "title": "Vahe Melkonyan",
        "name": "Vahe",
        "surname": "Melkonyan",
        "dateOfBirth": "06 Mar., 1981",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2377",
        "title": "Norik Arakelyan",
        "name": "Norik",
        "surname": "Arakelyan",
        "dateOfBirth": "04 Mar., 1990",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2376",
        "title": "Gavrusha Avakyan",
        "name": "Gavrusha",
        "surname": "Avakyan",
        "dateOfBirth": "31 Mar., 1997",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2375",
        "title": "Artur Avanesyan",
        "name": "Artur",
        "surname": "Avanesyan",
        "dateOfBirth": "24 Apr., 1998",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2374",
        "title": "Gor Harutyunyan",
        "name": "Gor",
        "surname": "Harutyunyan",
        "dateOfBirth": "22 June, 1996",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2373",
        "title": "Aram Mkrtchyan",
        "name": "Aram",
        "surname": "Mkrtchyan",
        "dateOfBirth": "05 Oct., 1974",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2372",
        "title": "Gregory Petrosyan",
        "name": "Gregory",
        "surname": "Petrosyan",
        "dateOfBirth": "18 Feb., 1989",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2371",
        "title": "Samvel Avanesyan",
        "name": "Samvel",
        "surname": "Avanesyan",
        "dateOfBirth": "01 Dec., 1994",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2410",
        "title": "Eduard Abrahamyan",
        "name": "Eduard",
        "surname": "Abrahamyan",
        "dateOfBirth": "26 Nov., 1967",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2409",
        "title": "Armen Martirosyan",
        "name": "Armen",
        "surname": "Martirosyan",
        "dateOfBirth": "29 May, 1972",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2408",
        "title": "Harut Abrahamyan",
        "name": "Harut",
        "surname": "Abrahamyan",
        "dateOfBirth": "01 Jan., 1989",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2407",
        "title": "Mkhitar Mirzoyan",
        "name": "Mkhitar",
        "surname": "Mirzoyan",
        "dateOfBirth": "26 Apr., 1991",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2406",
        "title": "Abraham Barnjyan",
        "name": "Abraham",
        "surname": "Barnjyan",
        "dateOfBirth": "12 May, 1993",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2405",
        "title": "Stepan Seyranyan",
        "name": "Stepan",
        "surname": "Seyranyan",
        "dateOfBirth": "01 Nov., 1968",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2404",
        "title": "Yasha Sahakyan",
        "name": "Yasha",
        "surname": "Sahakyan",
        "dateOfBirth": "20 Apr., 1959",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2403",
        "title": "Serjik Petrosyan",
        "name": "Serjik",
        "surname": "Petrosyan",
        "dateOfBirth": "10 Jan., 1970",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2402",
        "title": "Mavrik Grigoryan",
        "name": "Mavrik",
        "surname": "Grigoryan",
        "dateOfBirth": "13 May, 1983",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2401",
        "title": "Spartak Hakobjanyan",
        "name": "Spartak",
        "surname": "Hakobjanyan",
        "dateOfBirth": "24 Sept. , 1974",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1620",
        "title": "Արմեն Ներսեսյան Յուրիի",
        "name": "Արմեն",
        "surname": "Ներսեսյան Յուրիի",
        "dateOfBirth": "12 Apr., 1975",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1619",
        "title": "Ռաֆիկ Նիկողոսյան Սամվելի",
        "name": "Ռաֆիկ",
        "surname": "Նիկողոսյան Սամվելի",
        "dateOfBirth": "05 May, 1995",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1618",
        "title": "Արման Օհանջանյան Կարենի",
        "name": "Արման",
        "surname": "Օհանջանյան Կարենի",
        "dateOfBirth": "10 Sept. , 1997",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1617",
        "title": "Արմեն Օհանյան Միքայելի",
        "name": "Արմեն",
        "surname": "Օհանյան Միքայելի",
        "dateOfBirth": "23 July, 1971",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1616",
        "title": "Արմեն Պետրոսյան Գևորգի",
        "name": "Արմեն",
        "surname": "Պետրոսյան Գևորգի",
        "dateOfBirth": "07 May, 1995",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1615",
        "title": "Զավեն Ստեփանյան Հովիկի",
        "name": "Զավեն",
        "surname": "Ստեփանյան Հովիկի",
        "dateOfBirth": "26 May, 2001",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1614",
        "title": "Արման Սուքիասյան Արտակի",
        "name": "Արման",
        "surname": "Սուքիասյան Արտակի",
        "dateOfBirth": "08 Apr., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1613",
        "title": "Պարգև Տերոյան Կարենի",
        "name": "Պարգև",
        "surname": "Տերոյան Կարենի",
        "dateOfBirth": "17 June, 1998",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1612",
        "title": "Արթուր Տիգրանյան Սերոբի",
        "name": "Արթուր",
        "surname": "Տիգրանյան Սերոբի",
        "dateOfBirth": "28 Nov., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1611",
        "title": "Ալբերտ Վլասյան Արտուրի",
        "name": "Ալբերտ",
        "surname": "Վլասյան Արտուրի",
        "dateOfBirth": "15 Mar., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2000",
        "title": "Sergey Sarkisyan",
        "name": "Sergey",
        "surname": "Sarkisyan",
        "dateOfBirth": "12 Feb., 1982",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1999",
        "title": "Sargis Aghajanyan",
        "name": "Sargis",
        "surname": "Aghajanyan",
        "dateOfBirth": "18 Mar., 1978",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1998",
        "title": "Azniv Agamalyan",
        "name": "Azniv",
        "surname": "Agamalyan",
        "dateOfBirth": "13 Aug., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1997",
        "title": "Vahe Aghamalyan",
        "name": "Vahe",
        "surname": "Aghamalyan",
        "dateOfBirth": "28 Sept. , 2000",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1996",
        "title": "Simon Hovhannisyan",
        "name": "Simon",
        "surname": "Hovhannisyan",
        "dateOfBirth": "29 July, 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1995",
        "title": "Mushegh Israelyan",
        "name": "Mushegh",
        "surname": "Israelyan",
        "dateOfBirth": "14 Apr., 1995",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1994",
        "title": "Artashes Janibekyan",
        "name": "Artashes",
        "surname": "Janibekyan",
        "dateOfBirth": "23 July, 1985",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1993",
        "title": "Armen Jumkhuryan",
        "name": "Armen",
        "surname": "Jumkhuryan",
        "dateOfBirth": "08 July, 1997",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1992",
        "title": "Vahe Karapetyan",
        "name": "Vahe",
        "surname": "Karapetyan",
        "dateOfBirth": "18 Mar., 1999",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1991",
        "title": "Vardan Davtyan",
        "name": "Vardan",
        "surname": "Davtyan",
        "dateOfBirth": "25 Aug., 1985",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2130",
        "title": "Narek Hovsepyan",
        "name": "Narek",
        "surname": "Hovsepyan",
        "dateOfBirth": "18 Dec., 1999",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2129",
        "title": "Tigran Karapetyan",
        "name": "Tigran",
        "surname": "Karapetyan",
        "dateOfBirth": "30 May, 2002",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2128",
        "title": "Azat Sahakyan",
        "name": "Azat",
        "surname": "Sahakyan",
        "dateOfBirth": "23 Feb., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2127",
        "title": "Aram Karapetyan",
        "name": "Aram",
        "surname": "Karapetyan",
        "dateOfBirth": "09 Feb., 2002",
        "dateOfDeath": "18Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2126",
        "title": "Pavel Sahakyan",
        "name": "Pavel",
        "surname": "Sahakyan",
        "dateOfBirth": "30 Oct., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2125",
        "title": "Samvel Iskandaryan",
        "name": "Samvel",
        "surname": "Iskandaryan",
        "dateOfBirth": "05 Aug., 2000",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2124",
        "title": "Edik Kirakosyan",
        "name": "Edik",
        "surname": "Kirakosyan",
        "dateOfBirth": "11 Nov., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2123",
        "title": "Garik Sargsyan",
        "name": "Garik",
        "surname": "Sargsyan",
        "dateOfBirth": "26 Nov., 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2122",
        "title": "Narek Sargsyan",
        "name": "Narek",
        "surname": "Sargsyan",
        "dateOfBirth": "08 July, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2121",
        "title": "Eduard Marabyan",
        "name": "Eduard",
        "surname": "Marabyan",
        "dateOfBirth": "28 Feb., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2190",
        "title": "Albert Hovhannisyan",
        "name": "Albert",
        "surname": "Hovhannisyan",
        "dateOfBirth": "10 Oct., 2001",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2189",
        "title": "Hovhannes Poghosyan",
        "name": "Hovhannes",
        "surname": "Poghosyan",
        "dateOfBirth": "27 Mar., 2002",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2188",
        "title": "Shavo Samsonyan",
        "name": "Shavo",
        "surname": "Samsonyan",
        "dateOfBirth": "13 Oct., 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2187",
        "title": "Artur Sargsyan",
        "name": "Artur",
        "surname": "Sargsyan",
        "dateOfBirth": "20 June, 2000",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2186",
        "title": "Levon Mantashyan",
        "name": "Levon",
        "surname": "Mantashyan",
        "dateOfBirth": "03 Apr., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2185",
        "title": "Melik Merelyan",
        "name": "Melik",
        "surname": "Merelyan",
        "dateOfBirth": "14 Feb., 2002",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2184",
        "title": "Suren Sosyan",
        "name": "Suren",
        "surname": "Sosyan",
        "dateOfBirth": "02 Feb., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2183",
        "title": "Garik Stepanyan",
        "name": "Garik",
        "surname": "Stepanyan",
        "dateOfBirth": "22 Jan., 2002",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2182",
        "title": "Gor Yaralyan",
        "name": "Gor",
        "surname": "Yaralyan",
        "dateOfBirth": "20 Jan., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2181",
        "title": "Moris Humarian",
        "name": "Moris",
        "surname": "Humarian",
        "dateOfBirth": "03 Aug., 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2260",
        "title": "Rafayel Mkhchyan",
        "name": "Rafayel",
        "surname": "Mkhchyan",
        "dateOfBirth": "15 Dec., 1993",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2259",
        "title": "Mnacakan Davtyan",
        "name": "Mnacakan",
        "surname": "Davtyan",
        "dateOfBirth": "08 Apr., 1995",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2258",
        "title": "Karen Balayan",
        "name": "Karen",
        "surname": "Balayan",
        "dateOfBirth": "30 May, 1993",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2257",
        "title": "Mikayel Atoyan",
        "name": "Mikayel",
        "surname": "Atoyan",
        "dateOfBirth": "06 Nov., 1984",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2256",
        "title": "Arshak Melikyan",
        "name": "Arshak",
        "surname": "Melikyan",
        "dateOfBirth": "27 Jan., 1987",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2255",
        "title": "Nerses Movsesyan",
        "name": "Nerses",
        "surname": "Movsesyan",
        "dateOfBirth": "04 Mar., 1990",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2254",
        "title": "Avetis Nazaryan",
        "name": "Avetis",
        "surname": "Nazaryan",
        "dateOfBirth": "08 Aug., 1951",
        "dateOfDeath": "11Dec.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2253",
        "title": "Valodya Noroyan",
        "name": "Valodya",
        "surname": "Noroyan",
        "dateOfBirth": "07 Oct., 1998",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2252",
        "title": "Arshak Tonoyan",
        "name": "Arshak",
        "surname": "Tonoyan",
        "dateOfBirth": "13 Oct., 1990",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2251",
        "title": "Rafael Vasilyan",
        "name": "Rafael",
        "surname": "Vasilyan",
        "dateOfBirth": "14 May, 1992",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1970",
        "title": "Andranik Petrosyan",
        "name": "Andranik",
        "surname": "Petrosyan",
        "dateOfBirth": "21 Sept. , 1993",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1969",
        "title": "Hayk Khachatryan",
        "name": "Hayk",
        "surname": "Khachatryan",
        "dateOfBirth": "11 June, 1992",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1968",
        "title": "Ago Kocharyan",
        "name": "Ago",
        "surname": "Kocharyan",
        "dateOfBirth": "17 Apr., 1997",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1967",
        "title": "Gevorg Gharakhanyan",
        "name": "Gevorg",
        "surname": "Gharakhanyan",
        "dateOfBirth": "05 Feb., 1991",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1966",
        "title": "Vladimir Sargsyan",
        "name": "Vladimir",
        "surname": "Sargsyan",
        "dateOfBirth": "11 May, 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1965",
        "title": "Miasnik Simonyan",
        "name": "Miasnik",
        "surname": "Simonyan",
        "dateOfBirth": "08 Apr., 1989",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1964",
        "title": "David Petrosyan",
        "name": "David",
        "surname": "Petrosyan",
        "dateOfBirth": "28 June, 1977",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1963",
        "title": "Hrachik Ohanyan",
        "name": "Hrachik",
        "surname": "Ohanyan",
        "dateOfBirth": "15 Feb., 1994",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1962",
        "title": "Andranik Sargsyan",
        "name": "Andranik",
        "surname": "Sargsyan",
        "dateOfBirth": "21 Jan., 1966",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1961",
        "title": "Maksim Sargsyan",
        "name": "Maksim",
        "surname": "Sargsyan",
        "dateOfBirth": "21 Mar., 1990",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1860",
        "title": "Melik Ghazaryan",
        "name": "Melik",
        "surname": "Ghazaryan",
        "dateOfBirth": "28 July, 1990",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1859",
        "title": "Edgar Zakaryan",
        "name": "Edgar",
        "surname": "Zakaryan",
        "dateOfBirth": "24 Feb., 1981",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1858",
        "title": "Arayik Abaghyan",
        "name": "Arayik",
        "surname": "Abaghyan",
        "dateOfBirth": "21 Aug., 1987",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1857",
        "title": "Hovhannes Grigoryan",
        "name": "Hovhannes",
        "surname": "Grigoryan",
        "dateOfBirth": "21 Mar., 1991",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1856",
        "title": "Sevak Hambartsumyan",
        "name": "Sevak",
        "surname": "Hambartsumyan",
        "dateOfBirth": "29 July, 1976",
        "dateOfDeath": "01Dec.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1855",
        "title": "Narek Adamyan",
        "name": "Narek",
        "surname": "Adamyan",
        "dateOfBirth": "05 Dec., 2000",
        "dateOfDeath": "28Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1854",
        "title": "Samvel Antonyan",
        "name": "Samvel",
        "surname": "Antonyan",
        "dateOfBirth": "22 July, 2020",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1853",
        "title": "Hovhannes Apozyan",
        "name": "Hovhannes",
        "surname": "Apozyan",
        "dateOfBirth": "30 Oct., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1852",
        "title": "Aramayis Hayrapetyan",
        "name": "Aramayis",
        "surname": "Hayrapetyan",
        "dateOfBirth": "26 Sept. , 1992",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1851",
        "title": "Aren Asryan",
        "name": "Aren",
        "surname": "Asryan",
        "dateOfBirth": "21 Jan., 2000",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1330",
        "title": "Patvakan Harutyunyan",
        "name": "Patvakan",
        "surname": "Harutyunyan",
        "dateOfBirth": "02 Oct., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1329",
        "title": "Gevorg Hakobyan",
        "name": "Gevorg",
        "surname": "Hakobyan",
        "dateOfBirth": "08 Apr., 1999",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1328",
        "title": "Aram Gyulamiryan",
        "name": "Aram",
        "surname": "Gyulamiryan",
        "dateOfBirth": "06 Nov., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1327",
        "title": "Artur Gasparyan",
        "name": "Artur",
        "surname": "Gasparyan",
        "dateOfBirth": "17 July, 1998",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1326",
        "title": "Norik Gasparyan",
        "name": "Norik",
        "surname": "Gasparyan",
        "dateOfBirth": "17 June, 1998",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1325",
        "title": "Artur Virabyan",
        "name": "Artur",
        "surname": "Virabyan",
        "dateOfBirth": "04 June, 1992",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1324",
        "title": "Hovhannes Galstyan",
        "name": "Hovhannes",
        "surname": "Galstyan",
        "dateOfBirth": "09 Oct., 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1323",
        "title": "Gor Gabrielyan",
        "name": "Gor",
        "surname": "Gabrielyan",
        "dateOfBirth": "27 Apr., 2002",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1322",
        "title": "Armen Vekilyan",
        "name": "Armen",
        "surname": "Vekilyan",
        "dateOfBirth": "23 Apr., 1983",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1321",
        "title": "Erik Eghiazaryan",
        "name": "Erik",
        "surname": "Eghiazaryan",
        "dateOfBirth": "09 Aug., 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1390",
        "title": "Aram Khazaryan",
        "name": "Aram",
        "surname": "Khazaryan",
        "dateOfBirth": "05 Mar., 2002",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1389",
        "title": "Samvel Khachatryan",
        "name": "Samvel",
        "surname": "Khachatryan",
        "dateOfBirth": "27 Mar., 2002",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1388",
        "title": "Eduard Hayrapetyan",
        "name": "Eduard",
        "surname": "Hayrapetyan",
        "dateOfBirth": "20 Aug., 1982",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1387",
        "title": "Artyom khachatryan",
        "name": "Artyom",
        "surname": "khachatryan",
        "dateOfBirth": "26 May, 2001",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1386",
        "title": "Hakob Unjughulyan",
        "name": "Hakob",
        "surname": "Unjughulyan",
        "dateOfBirth": "01 May, 2002",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1385",
        "title": "Karen Hayrapetyan",
        "name": "Karen",
        "surname": "Hayrapetyan",
        "dateOfBirth": "03 Jan., 1997",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1384",
        "title": "Seyran Simonyan",
        "name": "Seyran",
        "surname": "Simonyan",
        "dateOfBirth": "25 Oct., 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1383",
        "title": "Abraham Hovhannisyan",
        "name": "Abraham",
        "surname": "Hovhannisyan",
        "dateOfBirth": "04 Sept. , 1993",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1382",
        "title": "Albert Mkrtchyan",
        "name": "Albert",
        "surname": "Mkrtchyan",
        "dateOfBirth": "22 Oct., 1979",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1381",
        "title": "Gurgen Mirzoyan",
        "name": "Gurgen",
        "surname": "Mirzoyan",
        "dateOfBirth": "20 May, 1990",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "90",
        "title": "Gor Ohanyan",
        "name": "Gor",
        "surname": "Ohanyan",
        "dateOfBirth": "24 Dec., 1992",
        "dateOfDeath": "17Dec.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "89",
        "title": "Vardan Mkrtchyan",
        "name": "Vardan",
        "surname": "Mkrtchyan",
        "dateOfBirth": "12 June, 1995",
        "dateOfDeath": "03Jan.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "108 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "88",
        "title": "Ashot Hovhannisyan",
        "name": "Ashot",
        "surname": "Hovhannisyan",
        "dateOfBirth": "23 June, 1990",
        "dateOfDeath": "18Aug.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "87",
        "title": "Arman Harutyunyan",
        "name": "Arman",
        "surname": "Harutyunyan",
        "dateOfBirth": "09 Jan., 1994",
        "dateOfDeath": "17Jan.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "86",
        "title": "Aghasi grigoryan",
        "name": "Aghasi",
        "surname": "grigoryan",
        "dateOfBirth": "27 Apr., 1996",
        "dateOfDeath": "18Dec.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "85",
        "title": "Sheqspir Hakobyan",
        "name": "Sheqspir",
        "surname": "Hakobyan",
        "dateOfBirth": "16 July, 1990",
        "dateOfDeath": "05Feb.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "84",
        "title": "Samvel Hakobyan",
        "name": "Samvel",
        "surname": "Hakobyan",
        "dateOfBirth": "03 Oct., 1995",
        "dateOfDeath": "26Mar.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "83",
        "title": "Karen Grigoryan",
        "name": "Karen",
        "surname": "Grigoryan",
        "dateOfBirth": "14 Jan., 1994",
        "dateOfDeath": "03Jan.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "82",
        "title": "Erik Grigoryan",
        "name": "Erik",
        "surname": "Grigoryan",
        "dateOfBirth": "05 Aug., 1995",
        "dateOfDeath": "04Dec.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "81",
        "title": "Arman Yepremyan",
        "name": "Arman",
        "surname": "Yepremyan",
        "dateOfBirth": "20 July, 1987",
        "dateOfDeath": "26May,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "81 280 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1580",
        "title": "Արթուր Միրզոյան Արամայիսի",
        "name": "Արթուր",
        "surname": "Միրզոյան Արամայիսի",
        "dateOfBirth": "10 Dec., 2000",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1579",
        "title": "Տիգրան Շահբազյան Տոնականի",
        "name": "Տիգրան",
        "surname": "Շահբազյան Տոնականի",
        "dateOfBirth": "11 June, 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1578",
        "title": "Կարեն Միրզոյան Արթուրի",
        "name": "Կարեն",
        "surname": "Միրզոյան Արթուրի",
        "dateOfBirth": "03 Dec., 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1577",
        "title": "Վազգեն Մկրտչյան Ռուդիկի",
        "name": "Վազգեն",
        "surname": "Մկրտչյան Ռուդիկի",
        "dateOfBirth": "27 Oct., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1576",
        "title": "Կարեն Շահինյան  Արտակի",
        "name": "Կարեն",
        "surname": "Շահինյան  Արտակի",
        "dateOfBirth": "04 May, 1998",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1575",
        "title": "Ալեքսան Սիմոնյան Ռազմիկի",
        "name": "Ալեքսան",
        "surname": "Սիմոնյան Ռազմիկի",
        "dateOfBirth": "21 Aug., 1997",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1574",
        "title": "Արամ Սիմոնյան Սեյրանի",
        "name": "Արամ",
        "surname": "Սիմոնյան Սեյրանի",
        "dateOfBirth": "09 Apr., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1573",
        "title": "Հենրիկ Սողոմոնյան Ռաֆիկի",
        "name": "Հենրիկ",
        "surname": "Սողոմոնյան Ռաֆիկի",
        "dateOfBirth": "12 May, 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1572",
        "title": "Բորիկ  Սուլեյմանյան Տիտալի",
        "name": "Բորիկ",
        "surname": " Սուլեյմանյան Տիտալի",
        "dateOfBirth": "04 Apr., 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1571",
        "title": "Վահե Ուդումյան Հայկազի",
        "name": "Վահե",
        "surname": "Ուդումյան Հայկազի",
        "dateOfBirth": "10 Oct., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "700",
        "title": "Artur Sukiasyan",
        "name": "Artur",
        "surname": "Sukiasyan",
        "dateOfBirth": "02 Nov., 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "699",
        "title": "Aramo Torosyan",
        "name": "Aramo",
        "surname": "Torosyan",
        "dateOfBirth": "24 July, 1999",
        "dateOfDeath": "10Feb.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "698",
        "title": "Radik Torosyan",
        "name": "Radik",
        "surname": "Torosyan",
        "dateOfBirth": "10 June, 1967",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "697",
        "title": "Aleksandr Hayrapetyan",
        "name": "Aleksandr",
        "surname": "Hayrapetyan",
        "dateOfBirth": "02 July, 1990",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "696",
        "title": "Mkrtich Vardanyan",
        "name": "Mkrtich",
        "surname": "Vardanyan",
        "dateOfBirth": "17 July, 1987",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "695",
        "title": "Sasun Hovhannisyan",
        "name": "Sasun",
        "surname": "Hovhannisyan",
        "dateOfBirth": "03 Nov., 1977",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "694",
        "title": "Sahak Varagyan",
        "name": "Sahak",
        "surname": "Varagyan",
        "dateOfBirth": "16 Jan., 1981",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "693",
        "title": "Samvel Hayrapetyan",
        "name": "Samvel",
        "surname": "Hayrapetyan",
        "dateOfBirth": "24 June, 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "692",
        "title": "Narek Grigoryan",
        "name": "Narek",
        "surname": "Grigoryan",
        "dateOfBirth": "06 Aug., 1991",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "691",
        "title": "Senik Safyan",
        "name": "Senik",
        "surname": "Safyan",
        "dateOfBirth": "01 Jan., 1972",
        "dateOfDeath": "10Feb.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2040",
        "title": "Karen Petrosyan",
        "name": "Karen",
        "surname": "Petrosyan",
        "dateOfBirth": "18 Dec., 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2039",
        "title": "Hakob Kartashyan",
        "name": "Hakob",
        "surname": "Kartashyan",
        "dateOfBirth": "24 Nov., 1999",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2038",
        "title": "Zhora Sahakyan",
        "name": "Zhora",
        "surname": "Sahakyan",
        "dateOfBirth": "27 Jan., 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2037",
        "title": "Armen Vardanyan",
        "name": "Armen",
        "surname": "Vardanyan",
        "dateOfBirth": "23 June, 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2036",
        "title": "Garik Danielyan",
        "name": "Garik",
        "surname": "Danielyan",
        "dateOfBirth": "01 Sept. , 1990",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2035",
        "title": "Melkon Davtyan",
        "name": "Melkon",
        "surname": "Davtyan",
        "dateOfBirth": "08 May, 1997",
        "dateOfDeath": "22Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2034",
        "title": "Artashes Gharibyan",
        "name": "Artashes",
        "surname": "Gharibyan",
        "dateOfBirth": "06 Mar., 1999",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2033",
        "title": "Sergey Dertsyan",
        "name": "Sergey",
        "surname": "Dertsyan",
        "dateOfBirth": "08 May, 1986",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2032",
        "title": "Yuri Ohanyan",
        "name": "Yuri",
        "surname": "Ohanyan",
        "dateOfBirth": "26 Mar., 1997",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2031",
        "title": "David Ghulyan",
        "name": "David",
        "surname": "Ghulyan",
        "dateOfBirth": "13 May, 2002",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2340",
        "title": "Arkady Avanesyan",
        "name": "Arkady",
        "surname": "Avanesyan",
        "dateOfBirth": "26 Mar., 1997",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2339",
        "title": "David Avetisyan",
        "name": "David",
        "surname": "Avetisyan",
        "dateOfBirth": "03 Mar., 1987",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2338",
        "title": "Aramayis Sargsyan",
        "name": "Aramayis",
        "surname": "Sargsyan",
        "dateOfBirth": "18 Jan., 1996",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2337",
        "title": "Arsen Sargsyan",
        "name": "Arsen",
        "surname": "Sargsyan",
        "dateOfBirth": "30 June, 1989",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2336",
        "title": "Narek Sargsyan",
        "name": "Narek",
        "surname": "Sargsyan",
        "dateOfBirth": "05 Sept. , 1995",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2335",
        "title": "Abraham Balayan",
        "name": "Abraham",
        "surname": "Balayan",
        "dateOfBirth": "16 Oct., 1997",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2334",
        "title": "Kajik Saryan",
        "name": "Kajik",
        "surname": "Saryan",
        "dateOfBirth": "28 Feb., 1990",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2333",
        "title": "Arsen Martirosyan",
        "name": "Arsen",
        "surname": "Martirosyan",
        "dateOfBirth": "21 Feb., 1988",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2332",
        "title": "Sanasar Abrahamyan",
        "name": "Sanasar",
        "surname": "Abrahamyan",
        "dateOfBirth": "04 Aug., 1988",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2331",
        "title": "Harut Khachatryan",
        "name": "Harut",
        "surname": "Khachatryan",
        "dateOfBirth": "05 Jan., 1998",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1740",
        "title": "Hovik Kotsinyan",
        "name": "Hovik",
        "surname": "Kotsinyan",
        "dateOfBirth": "28 Jan., 2002",
        "dateOfDeath": "11Aug.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1739",
        "title": "Harutyun Lalayan",
        "name": "Harutyun",
        "surname": "Lalayan",
        "dateOfBirth": "06 Oct., 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1738",
        "title": "Hrach Mahtesyan",
        "name": "Hrach",
        "surname": "Mahtesyan",
        "dateOfBirth": "19 Aug., 2001",
        "dateOfDeath": "10Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1737",
        "title": "Սամվել Գասպարյան Սահակի",
        "name": "Սամվել",
        "surname": "Գասպարյան Սահակի",
        "dateOfBirth": "13 Dec., 1994",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1736",
        "title": "Գոռ Աբգարյան Հայկի",
        "name": "Գոռ",
        "surname": "Աբգարյան Հայկի",
        "dateOfBirth": "17 Apr., 2002",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1735",
        "title": "Արթուր Ամիրաղյան Ռաշիդի",
        "name": "Արթուր",
        "surname": "Ամիրաղյան Ռաշիդի",
        "dateOfBirth": "06 Apr., 1983",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1734",
        "title": "Ժիրայր Անդրեասյան Սուլիկոյի",
        "name": "Ժիրայր",
        "surname": "Անդրեասյան Սուլիկոյի",
        "dateOfBirth": "15 July, 1977",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1733",
        "title": "Սամսոն Ամարյան Ռաչիկի",
        "name": "Սամսոն",
        "surname": "Ամարյան Ռաչիկի",
        "dateOfBirth": "15 Nov., 1988",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1732",
        "title": "Ռուստամ Աղայան Վլադիմիրի",
        "name": "Ռուստամ",
        "surname": "Աղայան Վլադիմիրի",
        "dateOfBirth": "25 Jan., 1978",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1731",
        "title": "Ռոբերտ Աբրահամյան Սարգիսի",
        "name": "Ռոբերտ",
        "surname": "Աբրահամյան Սարգիսի",
        "dateOfBirth": "06 July, 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "400",
        "title": "Nver Shahbazyan",
        "name": "Nver",
        "surname": "Shahbazyan",
        "dateOfBirth": "15 June, 1994",
        "dateOfDeath": "02July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "399",
        "title": "Karapet Nazaryan",
        "name": "Karapet",
        "surname": "Nazaryan",
        "dateOfBirth": "08 Sept. , 1991",
        "dateOfDeath": "02July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "398",
        "title": "Hayk Mkrtchyan",
        "name": "Hayk",
        "surname": "Mkrtchyan",
        "dateOfBirth": "07 Aug., 1992",
        "dateOfDeath": "07Sept.,2011",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "397",
        "title": "Ararat Hovakimyan",
        "name": "Ararat",
        "surname": "Hovakimyan",
        "dateOfBirth": "01 Feb., 1991",
        "dateOfDeath": "21Nov.,2019",
        "Status": "Total disability",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "396",
        "title": "Jak Galstyan",
        "name": "Jak",
        "surname": "Galstyan",
        "dateOfBirth": "26 July, 1993",
        "dateOfDeath": "13Oct.,2013",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "395",
        "title": "Gegham Davtyan",
        "name": "Gegham",
        "surname": "Davtyan",
        "dateOfBirth": "18 Sept. , 1991",
        "dateOfDeath": "22July,2011",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "394",
        "title": "Alik Abrahamyan",
        "name": "Alik",
        "surname": "Abrahamyan",
        "dateOfBirth": "05 Jan., 1992",
        "dateOfDeath": "26July,2011",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "393",
        "title": "Vahe Hayriyan",
        "name": "Vahe",
        "surname": "Hayriyan",
        "dateOfBirth": "31 Dec., 1970",
        "dateOfDeath": "22Oct.,2008",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "100 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "392",
        "title": "Hamayak Hambardzumyan",
        "name": "Hamayak",
        "surname": "Hambardzumyan",
        "dateOfBirth": "27 Feb., 1984",
        "dateOfDeath": "11June,2008",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "109 020 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "391",
        "title": "Artak Melkumyan",
        "name": "Artak",
        "surname": "Melkumyan",
        "dateOfBirth": "30 June, 1987",
        "dateOfDeath": "06June,2013",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "68 779 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2220",
        "title": "Ashot Avetisyan",
        "name": "Ashot",
        "surname": "Avetisyan",
        "dateOfBirth": "04 Nov., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2219",
        "title": "Vrej Avetisyan",
        "name": "Vrej",
        "surname": "Avetisyan",
        "dateOfBirth": "21 Dec., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2218",
        "title": "Mikayel Azaryan",
        "name": "Mikayel",
        "surname": "Azaryan",
        "dateOfBirth": "10 Jan., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2217",
        "title": "Artur Barseghyan",
        "name": "Artur",
        "surname": "Barseghyan",
        "dateOfBirth": "07 May, 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2216",
        "title": "Artur Karapetyan",
        "name": "Artur",
        "surname": "Karapetyan",
        "dateOfBirth": "21 Nov., 1990",
        "dateOfDeath": "19Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2215",
        "title": "Martin Martirosyan",
        "name": "Martin",
        "surname": "Martirosyan",
        "dateOfBirth": "21 Oct., 1994",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2214",
        "title": "Gor Khachatryan",
        "name": "Gor",
        "surname": "Khachatryan",
        "dateOfBirth": "18 Apr., 1997",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2213",
        "title": "Artak Jamkochyan",
        "name": "Artak",
        "surname": "Jamkochyan",
        "dateOfBirth": "18 Mar., 1990",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2212",
        "title": "Samvel Kochinyan",
        "name": "Samvel",
        "surname": "Kochinyan",
        "dateOfBirth": "22 Nov., 1980",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2211",
        "title": "Hayk Gevorgyan",
        "name": "Hayk",
        "surname": "Gevorgyan",
        "dateOfBirth": "19 June, 1990",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1980",
        "title": "Hayk Babayan",
        "name": "Hayk",
        "surname": "Babayan",
        "dateOfBirth": "06 May, 1975",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1979",
        "title": "Khachatur Poghosyan",
        "name": "Khachatur",
        "surname": "Poghosyan",
        "dateOfBirth": "09 Oct., 1988",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1978",
        "title": "Garegin Yeranosyan",
        "name": "Garegin",
        "surname": "Yeranosyan",
        "dateOfBirth": "09 Dec., 1982",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1977",
        "title": "Hrach Truzyan",
        "name": "Hrach",
        "surname": "Truzyan",
        "dateOfBirth": "15 Nov., 1998",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1976",
        "title": "Harutyun Vardanyan",
        "name": "Harutyun",
        "surname": "Vardanyan",
        "dateOfBirth": "13 May, 1996",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1975",
        "title": "Gerasim Tonoyan",
        "name": "Gerasim",
        "surname": "Tonoyan",
        "dateOfBirth": "10 Feb., 1959",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1974",
        "title": "Manuk Poghosyan",
        "name": "Manuk",
        "surname": "Poghosyan",
        "dateOfBirth": "20 Oct., 1991",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1973",
        "title": "Badal Safaryan",
        "name": "Badal",
        "surname": "Safaryan",
        "dateOfBirth": "09 Jan., 1965",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1972",
        "title": "Sevak Serobyan",
        "name": "Sevak",
        "surname": "Serobyan",
        "dateOfBirth": "02 Aug., 1987",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1971",
        "title": "Arsen Mardiyan",
        "name": "Arsen",
        "surname": "Mardiyan",
        "dateOfBirth": "05 Aug., 1999",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1760",
        "title": "Arsen Hovhannisyan",
        "name": "Arsen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "01 Sept. , 1983",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1759",
        "title": "Suren Hambardzumyan",
        "name": "Suren",
        "surname": "Hambardzumyan",
        "dateOfBirth": "01 Jan., 1992",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1758",
        "title": "Samvel Revazyan",
        "name": "Samvel",
        "surname": "Revazyan",
        "dateOfBirth": "18 Apr., 1966",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1757",
        "title": "Karen Sargsyan",
        "name": "Karen",
        "surname": "Sargsyan",
        "dateOfBirth": "05 June, 1998",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1756",
        "title": "Artsrun Simonyan",
        "name": "Artsrun",
        "surname": "Simonyan",
        "dateOfBirth": "17 Nov., 1994",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1755",
        "title": "Suren Vantsyan",
        "name": "Suren",
        "surname": "Vantsyan",
        "dateOfBirth": "19 Feb., 1996",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1754",
        "title": "Ervand Khachatryan",
        "name": "Ervand",
        "surname": "Khachatryan",
        "dateOfBirth": "21 July, 1978",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1753",
        "title": "Djivan Khachatryan",
        "name": "Djivan",
        "surname": "Khachatryan",
        "dateOfBirth": "26 Apr., 1994",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1752",
        "title": "Albert Kharatyan",
        "name": "Albert",
        "surname": "Kharatyan",
        "dateOfBirth": "03 Sept. , 1995",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1751",
        "title": "Arsen Madoyan",
        "name": "Arsen",
        "surname": "Madoyan",
        "dateOfBirth": "30 June, 1986",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1420",
        "title": "Ara Khachatryan",
        "name": "Ara",
        "surname": "Khachatryan",
        "dateOfBirth": "06 Nov., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1419",
        "title": "Hovhannes Vasilyan",
        "name": "Hovhannes",
        "surname": "Vasilyan",
        "dateOfBirth": "09 Aug., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1418",
        "title": "Grigori Tashchyan",
        "name": "Grigori",
        "surname": "Tashchyan",
        "dateOfBirth": "22 Feb., 2002",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1417",
        "title": "Narek Shushanyan",
        "name": "Narek",
        "surname": "Shushanyan",
        "dateOfBirth": "16 Jan., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1416",
        "title": "Artur Stepanyan",
        "name": "Artur",
        "surname": "Stepanyan",
        "dateOfBirth": "23 Nov., 1998",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1415",
        "title": "Mher Khochinyan",
        "name": "Mher",
        "surname": "Khochinyan",
        "dateOfBirth": "19 Apr., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1414",
        "title": "Mamikon Kasoyan",
        "name": "Mamikon",
        "surname": "Kasoyan",
        "dateOfBirth": "07 June, 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1413",
        "title": "Vakhtang Ohanyan",
        "name": "Vakhtang",
        "surname": "Ohanyan",
        "dateOfBirth": "19 Oct., 1996",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1412",
        "title": "Gevorg Hunoyan",
        "name": "Gevorg",
        "surname": "Hunoyan",
        "dateOfBirth": "10 Feb., 2021",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1411",
        "title": "Erik Petrosyan",
        "name": "Erik",
        "surname": "Petrosyan",
        "dateOfBirth": "21 Nov., 2001",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2120",
        "title": "Raphael Sepkhanyan",
        "name": "Raphael",
        "surname": "Sepkhanyan",
        "dateOfBirth": "21 Nov., 2000",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2119",
        "title": "Hovik Margaryan",
        "name": "Hovik",
        "surname": "Margaryan",
        "dateOfBirth": "03 Oct., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2118",
        "title": "Gevorg Martirosyan",
        "name": "Gevorg",
        "surname": "Martirosyan",
        "dateOfBirth": "01 June, 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2117",
        "title": "David Matevosyan",
        "name": "David",
        "surname": "Matevosyan",
        "dateOfBirth": "12 June, 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2116",
        "title": "David Mehrabyan",
        "name": "David",
        "surname": "Mehrabyan",
        "dateOfBirth": "08 May, 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2115",
        "title": "Narek Melkonyan",
        "name": "Narek",
        "surname": "Melkonyan",
        "dateOfBirth": "03 July, 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2114",
        "title": "Mikayel Mkrtchyan",
        "name": "Mikayel",
        "surname": "Mkrtchyan",
        "dateOfBirth": "20 Mar., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2113",
        "title": "Vrej Militosyan",
        "name": "Vrej",
        "surname": "Militosyan",
        "dateOfBirth": "10 Jan., 2002",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2112",
        "title": "Tigran Shashikyan",
        "name": "Tigran",
        "surname": "Shashikyan",
        "dateOfBirth": "15 Dec., 2000",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2111",
        "title": "Vazgen Simonyan",
        "name": "Vazgen",
        "surname": "Simonyan",
        "dateOfBirth": "01 Jan., 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1130",
        "title": "Hamlet Sargsyan",
        "name": "Hamlet",
        "surname": "Sargsyan",
        "dateOfBirth": "09 Oct., 1973",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1129",
        "title": "Mrazik Kaloyan",
        "name": "Mrazik",
        "surname": "Kaloyan",
        "dateOfBirth": "04 Feb., 2000",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1128",
        "title": "Vilen Balayan",
        "name": "Vilen",
        "surname": "Balayan",
        "dateOfBirth": "10 Nov., 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1127",
        "title": "Vardges Manukyan",
        "name": "Vardges",
        "surname": "Manukyan",
        "dateOfBirth": "12 Oct., 1987",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1126",
        "title": "Armen Babalyan",
        "name": "Armen",
        "surname": "Babalyan",
        "dateOfBirth": "04 Nov., 1986",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1125",
        "title": "Aram Hakobyan",
        "name": "Aram",
        "surname": "Hakobyan",
        "dateOfBirth": "06 Dec., 2001",
        "dateOfDeath": "20Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1124",
        "title": "Vanuh Harutyunyan",
        "name": "Vanuh",
        "surname": "Harutyunyan",
        "dateOfBirth": "07 Mar., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1123",
        "title": "Garnik Malkhasyan",
        "name": "Garnik",
        "surname": "Malkhasyan",
        "dateOfBirth": "31 July, 1991",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1122",
        "title": "Khachatur Khachatryan",
        "name": "Khachatur",
        "surname": "Khachatryan",
        "dateOfBirth": "22 Oct., 1982",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1121",
        "title": "Rudik Harutyunyan",
        "name": "Rudik",
        "surname": "Harutyunyan",
        "dateOfBirth": "15 July, 1982",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1230",
        "title": "Hakob Rostomyan",
        "name": "Hakob",
        "surname": "Rostomyan",
        "dateOfBirth": "13 Feb., 1989",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1229",
        "title": "Perch Antonyan",
        "name": "Perch",
        "surname": "Antonyan",
        "dateOfBirth": "19 Apr., 1978",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1228",
        "title": "Sargis Poghosyan",
        "name": "Sargis",
        "surname": "Poghosyan",
        "dateOfBirth": "12 Oct., 1982",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1227",
        "title": "Arman Poghosyan",
        "name": "Arman",
        "surname": "Poghosyan",
        "dateOfBirth": "09 Jan., 1992",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1226",
        "title": "Artur Kirakosyan",
        "name": "Artur",
        "surname": "Kirakosyan",
        "dateOfBirth": "18 Jan., 1977",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1225",
        "title": "Arshak Paramazyan",
        "name": "Arshak",
        "surname": "Paramazyan",
        "dateOfBirth": "05 May, 1983",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1224",
        "title": "Artur Khachatryan",
        "name": "Artur",
        "surname": "Khachatryan",
        "dateOfBirth": "16 June, 1992",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1223",
        "title": "Artur Grigoryan",
        "name": "Artur",
        "surname": "Grigoryan",
        "dateOfBirth": "23 Nov., 1986",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1222",
        "title": "Davit Sarukhanyan",
        "name": "Davit",
        "surname": "Sarukhanyan",
        "dateOfBirth": "20 Dec., 1996",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1221",
        "title": "Karen Sargsyan",
        "name": "Karen",
        "surname": "Sargsyan",
        "dateOfBirth": "28 Feb., 1997",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "500",
        "title": "Varoujan Khojoyan",
        "name": "Varoujan",
        "surname": "Khojoyan",
        "dateOfBirth": "22 May, 1978",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "499",
        "title": "Suren Melikyan",
        "name": "Suren",
        "surname": "Melikyan",
        "dateOfBirth": "25 Mar., 1978",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "498",
        "title": "Erik Gevorgyan",
        "name": "Erik",
        "surname": "Gevorgyan",
        "dateOfBirth": "02 Aug., 1997",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "497",
        "title": "Smbat Mazmanyan",
        "name": "Smbat",
        "surname": "Mazmanyan",
        "dateOfBirth": "14 Apr., 1998",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "496",
        "title": "Harutyunyan Harutyun",
        "name": "Harutyunyan",
        "surname": "Harutyun",
        "dateOfBirth": "03 Nov., 1991",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "495",
        "title": "Kerob Chatikyan",
        "name": "Kerob",
        "surname": "Chatikyan",
        "dateOfBirth": "05 Nov., 2000",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "494",
        "title": "Moush Hakobyan",
        "name": "Moush",
        "surname": "Hakobyan",
        "dateOfBirth": "23 July, 1999",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "493",
        "title": "Hamlet Tovmasyan",
        "name": "Hamlet",
        "surname": "Tovmasyan",
        "dateOfBirth": "23 Jan., 1980",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "492",
        "title": "Hovik Tamazyan",
        "name": "Hovik",
        "surname": "Tamazyan",
        "dateOfBirth": "29 Apr., 1991",
        "dateOfDeath": "16Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "491",
        "title": "Vardan Sargsyan",
        "name": "Vardan",
        "surname": "Sargsyan",
        "dateOfBirth": "16 Nov., 1987",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1470",
        "title": "Suren Melikbekyan",
        "name": "Suren",
        "surname": "Melikbekyan",
        "dateOfBirth": "18 Oct., 1998",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1469",
        "title": "Hayk Hakobyan",
        "name": "Hayk",
        "surname": "Hakobyan",
        "dateOfBirth": "30 Mar., 1996",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1468",
        "title": "Norayr Mayilyan",
        "name": "Norayr",
        "surname": "Mayilyan",
        "dateOfBirth": "14 Oct., 1999",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1467",
        "title": "Shahen Grigoryan",
        "name": "Shahen",
        "surname": "Grigoryan",
        "dateOfBirth": "22 June, 1991",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1466",
        "title": "Vrej Mukuchyan",
        "name": "Vrej",
        "surname": "Mukuchyan",
        "dateOfBirth": "04 Nov., 2000",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1465",
        "title": "Gor Gevorgyan",
        "name": "Gor",
        "surname": "Gevorgyan",
        "dateOfBirth": "22 Mar., 1992",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1464",
        "title": "Artashes Martirosyan",
        "name": "Artashes",
        "surname": "Martirosyan",
        "dateOfBirth": "17 Mar., 2002",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1463",
        "title": "Armen Hayrapetyan",
        "name": "Armen",
        "surname": "Hayrapetyan",
        "dateOfBirth": "26 June, 1988",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1462",
        "title": "Norik Mkrtchyan",
        "name": "Norik",
        "surname": "Mkrtchyan",
        "dateOfBirth": "09 Feb., 1987",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1461",
        "title": "Artyom Mkrtchyan",
        "name": "Artyom",
        "surname": "Mkrtchyan",
        "dateOfBirth": "28 May, 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1870",
        "title": "Hayk Mikaelyan",
        "name": "Hayk",
        "surname": "Mikaelyan",
        "dateOfBirth": "16 May, 1984",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1869",
        "title": "Armen Khachatryan",
        "name": "Armen",
        "surname": "Khachatryan",
        "dateOfBirth": "20 Aug., 1975",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1868",
        "title": "Armen Mirigyan",
        "name": "Armen",
        "surname": "Mirigyan",
        "dateOfBirth": "02 Aug., 1996",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1867",
        "title": "Hovhannes Khachatryan",
        "name": "Hovhannes",
        "surname": "Khachatryan",
        "dateOfBirth": "02 July, 1983",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1866",
        "title": "Hovhannes Khachatryan",
        "name": "Hovhannes",
        "surname": "Khachatryan",
        "dateOfBirth": "15 Aug., 1991",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1865",
        "title": "Kim Khachatryan",
        "name": "Kim",
        "surname": "Khachatryan",
        "dateOfBirth": "17 Mar., 1995",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1864",
        "title": "Melik Khachatryan",
        "name": "Melik",
        "surname": "Khachatryan",
        "dateOfBirth": "08 Oct., 2020",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1863",
        "title": "Vahram Khachatryan",
        "name": "Vahram",
        "surname": "Khachatryan",
        "dateOfBirth": "26 Jan., 1988",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1862",
        "title": "Ruben Karakhanyan",
        "name": "Ruben",
        "surname": "Karakhanyan",
        "dateOfBirth": "08 Feb., 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1861",
        "title": "Seyran Gharibyan",
        "name": "Seyran",
        "surname": "Gharibyan",
        "dateOfBirth": "07 June, 1983",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2290",
        "title": "Davit Grigoryan",
        "name": "Davit",
        "surname": "Grigoryan",
        "dateOfBirth": "28 June, 1996",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2289",
        "title": "Ararat Manyan",
        "name": "Ararat",
        "surname": "Manyan",
        "dateOfBirth": "01 Feb., 2002",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2288",
        "title": "Ashot Matevosyan",
        "name": "Ashot",
        "surname": "Matevosyan",
        "dateOfBirth": "07 May, 1978",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2287",
        "title": "Arthur Grigoryan",
        "name": "Arthur",
        "surname": "Grigoryan",
        "dateOfBirth": "09 June, 1978",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2286",
        "title": "Stepan Hambardzumyan",
        "name": "Stepan",
        "surname": "Hambardzumyan",
        "dateOfBirth": "04 Sept. , 1965",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2285",
        "title": "Yurik Aperyan",
        "name": "Yurik",
        "surname": "Aperyan",
        "dateOfBirth": "01 Nov., 1961",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2284",
        "title": "Aleksandr Armaghanyan",
        "name": "Aleksandr",
        "surname": "Armaghanyan",
        "dateOfBirth": "02 Mar., 1967",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2283",
        "title": "Arayik Asatryan",
        "name": "Arayik",
        "surname": "Asatryan",
        "dateOfBirth": "18 July, 1969",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2282",
        "title": "Suren Hasratyan",
        "name": "Suren",
        "surname": "Hasratyan",
        "dateOfBirth": "29 Feb., 1960",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2281",
        "title": "Zohrak Harutyunyan",
        "name": "Zohrak",
        "surname": "Harutyunyan",
        "dateOfBirth": "24 Oct., 1987",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1340",
        "title": "Arman Abrahamyan",
        "name": "Arman",
        "surname": "Abrahamyan",
        "dateOfBirth": "17 July, 1979",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1339",
        "title": "Davit Danielyan",
        "name": "Davit",
        "surname": "Danielyan",
        "dateOfBirth": "27 Sept. , 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1338",
        "title": "Armen Baghdasaryan",
        "name": "Armen",
        "surname": "Baghdasaryan",
        "dateOfBirth": "17 Nov., 2001",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1337",
        "title": "Gor Avetikyan",
        "name": "Gor",
        "surname": "Avetikyan",
        "dateOfBirth": "06 July, 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1336",
        "title": "Paylak Avetisyan",
        "name": "Paylak",
        "surname": "Avetisyan",
        "dateOfBirth": "09 Feb., 1982",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1335",
        "title": "Hayk Arshakyan",
        "name": "Hayk",
        "surname": "Arshakyan",
        "dateOfBirth": "17 Apr., 1992",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1334",
        "title": "Artem Antonyan",
        "name": "Artem",
        "surname": "Antonyan",
        "dateOfBirth": "08 Feb., 1978",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1333",
        "title": "Sargis Ananyan",
        "name": "Sargis",
        "surname": "Ananyan",
        "dateOfBirth": "19 Jan., 1996",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1332",
        "title": "Sasun Hayrapetyan",
        "name": "Sasun",
        "surname": "Hayrapetyan",
        "dateOfBirth": "27 Nov., 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1331",
        "title": "Arman Khachatryan",
        "name": "Arman",
        "surname": "Khachatryan",
        "dateOfBirth": "21 Jan., 1998",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1840",
        "title": "Gevorg Kirakosyan",
        "name": "Gevorg",
        "surname": "Kirakosyan",
        "dateOfBirth": "11 May, 1988",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1839",
        "title": "Igor Poghosyan",
        "name": "Igor",
        "surname": "Poghosyan",
        "dateOfBirth": "06 July, 1997",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1838",
        "title": "Shirak Hambardzumyan",
        "name": "Shirak",
        "surname": "Hambardzumyan",
        "dateOfBirth": "01 May, 1997",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1837",
        "title": "Tigran Petrosyan",
        "name": "Tigran",
        "surname": "Petrosyan",
        "dateOfBirth": "28 Aug., 1996",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1836",
        "title": "Ashot Avanesyan",
        "name": "Ashot",
        "surname": "Avanesyan",
        "dateOfBirth": "07 Nov., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1835",
        "title": "Gegham Grigoryan",
        "name": "Gegham",
        "surname": "Grigoryan",
        "dateOfBirth": "28 Feb., 2002",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1834",
        "title": "Gurgen Avetisyan",
        "name": "Gurgen",
        "surname": "Avetisyan",
        "dateOfBirth": "15 Feb., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1833",
        "title": "Vahagn Barseghyan",
        "name": "Vahagn",
        "surname": "Barseghyan",
        "dateOfBirth": "22 Feb., 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1832",
        "title": "Davit Danielyan",
        "name": "Davit",
        "surname": "Danielyan",
        "dateOfBirth": "27 Sept. , 2000",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1831",
        "title": "Lyova Danoyan",
        "name": "Lyova",
        "surname": "Danoyan",
        "dateOfBirth": "11 Oct., 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1830",
        "title": "Arman Yegoryan",
        "name": "Arman",
        "surname": "Yegoryan",
        "dateOfBirth": "11 Aug., 2000",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1829",
        "title": "Aram Yelinyan",
        "name": "Aram",
        "surname": "Yelinyan",
        "dateOfBirth": "14 Apr., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1828",
        "title": "Vaspurakan Gaboyan",
        "name": "Vaspurakan",
        "surname": "Gaboyan",
        "dateOfBirth": "09 Nov., 2000",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1827",
        "title": "Yura Gabrielyan",
        "name": "Yura",
        "surname": "Gabrielyan",
        "dateOfBirth": "22 Feb., 2002",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1826",
        "title": "Arman Galstyan",
        "name": "Arman",
        "surname": "Galstyan",
        "dateOfBirth": "09 Dec., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1825",
        "title": "Arsen Gasparyan",
        "name": "Arsen",
        "surname": "Gasparyan",
        "dateOfBirth": "04 Sept. , 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1824",
        "title": "Karen Gasparyan",
        "name": "Karen",
        "surname": "Gasparyan",
        "dateOfBirth": "02 July, 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1823",
        "title": "Davit Ananyan",
        "name": "Davit",
        "surname": "Ananyan",
        "dateOfBirth": "17 Oct., 1979",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1822",
        "title": "Vahe Nikoghosyan",
        "name": "Vahe",
        "surname": "Nikoghosyan",
        "dateOfBirth": "30 Sept. , 2001",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1821",
        "title": "Zohrab Ozmanyan",
        "name": "Zohrab",
        "surname": "Ozmanyan",
        "dateOfBirth": "13 Aug., 1998",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2170",
        "title": "Nver Hovsepyan",
        "name": "Nver",
        "surname": "Hovsepyan",
        "dateOfBirth": "31 Mar., 1984",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2169",
        "title": "Seryozha Galoyan",
        "name": "Seryozha",
        "surname": "Galoyan",
        "dateOfBirth": "06 June, 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2168",
        "title": "Arkadik Grigoryan",
        "name": "Arkadik",
        "surname": "Grigoryan",
        "dateOfBirth": "30 Nov., 1986",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2167",
        "title": "Jivan Grigoryan",
        "name": "Jivan",
        "surname": "Grigoryan",
        "dateOfBirth": "04 Nov., 1991",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2166",
        "title": "Sevak Harutyunyan",
        "name": "Sevak",
        "surname": "Harutyunyan",
        "dateOfBirth": "23 Apr., 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2165",
        "title": "Firdus Hovsepyan",
        "name": "Firdus",
        "surname": "Hovsepyan",
        "dateOfBirth": "15 June, 1994",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2164",
        "title": "Tigran Martirosyan",
        "name": "Tigran",
        "surname": "Martirosyan",
        "dateOfBirth": "26 May, 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2163",
        "title": "Samvel Mkrtchyan",
        "name": "Samvel",
        "surname": "Mkrtchyan",
        "dateOfBirth": "10 June, 1985",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2162",
        "title": "Andranik Mkhoyan",
        "name": "Andranik",
        "surname": "Mkhoyan",
        "dateOfBirth": "17 Aug., 1990",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2161",
        "title": "Seryozha Varosyan",
        "name": "Seryozha",
        "surname": "Varosyan",
        "dateOfBirth": "17 Jan., 1991",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "620",
        "title": "David Hakobyan",
        "name": "David",
        "surname": "Hakobyan",
        "dateOfBirth": "05 Sept. , 1995",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "619",
        "title": "Eduard Shahnazaryan",
        "name": "Eduard",
        "surname": "Shahnazaryan",
        "dateOfBirth": "06 Feb., 1994",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "618",
        "title": "Gagik Grigoryan",
        "name": "Gagik",
        "surname": "Grigoryan",
        "dateOfBirth": "02 July, 1961",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "617",
        "title": "Alexander Aharonyan",
        "name": "Alexander",
        "surname": "Aharonyan",
        "dateOfBirth": "25 Dec., 2000",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "616",
        "title": "Emil Adamyan",
        "name": "Emil",
        "surname": "Adamyan",
        "dateOfBirth": "17 May, 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "615",
        "title": "Zakar Serobyan",
        "name": "Zakar",
        "surname": "Serobyan",
        "dateOfBirth": "11 Oct., 1997",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "614",
        "title": "Artyom Saghatelyan",
        "name": "Artyom",
        "surname": "Saghatelyan",
        "dateOfBirth": "15 Oct., 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "613",
        "title": "Vahe Meliqyan",
        "name": "Vahe",
        "surname": "Meliqyan",
        "dateOfBirth": "19 Sept. , 1996",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "612",
        "title": "Hayk Ghazaryan",
        "name": "Hayk",
        "surname": "Ghazaryan",
        "dateOfBirth": "18 Aug., 1989",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "611",
        "title": "Albert Martirosyan",
        "name": "Albert",
        "surname": "Martirosyan",
        "dateOfBirth": "02 Aug., 1995",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1440",
        "title": "Vahram Khlghatyan",
        "name": "Vahram",
        "surname": "Khlghatyan",
        "dateOfBirth": "17 Sept. , 1989",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1439",
        "title": "Davit Khechoyan",
        "name": "Davit",
        "surname": "Khechoyan",
        "dateOfBirth": "21 Mar., 1993",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1438",
        "title": "Alexandr Osipyan",
        "name": "Alexandr",
        "surname": "Osipyan",
        "dateOfBirth": "26 Apr., 1985",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1437",
        "title": "Rafik Khazaryan",
        "name": "Rafik",
        "surname": "Khazaryan",
        "dateOfBirth": "23 Aug., 1982",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1436",
        "title": "Tigran Khanumyan",
        "name": "Tigran",
        "surname": "Khanumyan",
        "dateOfBirth": "03 Sept. , 1972",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1435",
        "title": "Garik Sayadyan",
        "name": "Garik",
        "surname": "Sayadyan",
        "dateOfBirth": "28 Nov., 1984",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1434",
        "title": "Adam Khalatyan",
        "name": "Adam",
        "surname": "Khalatyan",
        "dateOfBirth": "26 July, 1993",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1433",
        "title": "Gor Sargsyan",
        "name": "Gor",
        "surname": "Sargsyan",
        "dateOfBirth": "02 Feb., 1985",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1432",
        "title": "Mkitar Poghosyan",
        "name": "Mkitar",
        "surname": "Poghosyan",
        "dateOfBirth": "01 Nov., 1993",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1431",
        "title": "Harutyun Khachatryan",
        "name": "Harutyun",
        "surname": "Khachatryan",
        "dateOfBirth": "17 June, 1993",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1880",
        "title": "Aramayis Mkrtchyan",
        "name": "Aramayis",
        "surname": "Mkrtchyan",
        "dateOfBirth": "19 May, 1970",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1879",
        "title": "Vladimir Gurdjiyan",
        "name": "Vladimir",
        "surname": "Gurdjiyan",
        "dateOfBirth": "14 Sept. , 1995",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1878",
        "title": "Razmik Mkrtchyan",
        "name": "Razmik",
        "surname": "Mkrtchyan",
        "dateOfBirth": "17 Feb., 1996",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1877",
        "title": "Arayik Martirosyan",
        "name": "Arayik",
        "surname": "Martirosyan",
        "dateOfBirth": "18 Sept. , 1989",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1876",
        "title": "Yervand Hajiyan",
        "name": "Yervand",
        "surname": "Hajiyan",
        "dateOfBirth": "14 Dec., 1997",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1875",
        "title": "Vardan Mnatsakanyan",
        "name": "Vardan",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "19 June, 1989",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1874",
        "title": "Kamo Hovsepyan",
        "name": "Kamo",
        "surname": "Hovsepyan",
        "dateOfBirth": "12 Nov., 1976",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1873",
        "title": "Yuri Ohanyan",
        "name": "Yuri",
        "surname": "Ohanyan",
        "dateOfBirth": "01 Apr., 1998",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1872",
        "title": "Armen Petoyan",
        "name": "Armen",
        "surname": "Petoyan",
        "dateOfBirth": "13 Oct., 1969",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1871",
        "title": "Khachatur Khachaturyan",
        "name": "Khachatur",
        "surname": "Khachaturyan",
        "dateOfBirth": "31 Jan., 1990",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1380",
        "title": "Harutyun Semerdjyan",
        "name": "Harutyun",
        "surname": "Semerdjyan",
        "dateOfBirth": "26 Sept. , 1992",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1379",
        "title": "Garik Melkonyan",
        "name": "Garik",
        "surname": "Melkonyan",
        "dateOfBirth": "21 June, 1991",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1378",
        "title": "Jora Sargsyan",
        "name": "Jora",
        "surname": "Sargsyan",
        "dateOfBirth": "09 July, 2000",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1377",
        "title": "Ashot Petrosyan",
        "name": "Ashot",
        "surname": "Petrosyan",
        "dateOfBirth": "07 July, 1997",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1376",
        "title": "Ruben Martirosyan",
        "name": "Ruben",
        "surname": "Martirosyan",
        "dateOfBirth": "15 Feb., 1972",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1375",
        "title": "Artavazd Martirosyan",
        "name": "Artavazd",
        "surname": "Martirosyan",
        "dateOfBirth": "27 May, 1993",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1374",
        "title": "Arsen Martirosyan",
        "name": "Arsen",
        "surname": "Martirosyan",
        "dateOfBirth": "29 May, 1995",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1373",
        "title": "Seryoja Margaryan",
        "name": "Seryoja",
        "surname": "Margaryan",
        "dateOfBirth": "24 Aug., 1991",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1372",
        "title": "Aram Baghdasaryan",
        "name": "Aram",
        "surname": "Baghdasaryan",
        "dateOfBirth": "20 July, 1969",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1371",
        "title": "Andranik Navoyan",
        "name": "Andranik",
        "surname": "Navoyan",
        "dateOfBirth": "18 Sept. , 1995",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "650",
        "title": "Norayr Mnacakanyan",
        "name": "Norayr",
        "surname": "Mnacakanyan",
        "dateOfBirth": "12 Oct., 2020",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "649",
        "title": "David Tevanyan",
        "name": "David",
        "surname": "Tevanyan",
        "dateOfBirth": "30 May, 1989",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "648",
        "title": "Hovik Simonyan",
        "name": "Hovik",
        "surname": "Simonyan",
        "dateOfBirth": "28 Feb., 1986",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "647",
        "title": "Suren Babayan",
        "name": "Suren",
        "surname": "Babayan",
        "dateOfBirth": "16 Dec., 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "646",
        "title": "Shavarsh Harutyunyan",
        "name": "Shavarsh",
        "surname": "Harutyunyan",
        "dateOfBirth": "24 May, 1999",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "645",
        "title": "Masis Harutyunyan",
        "name": "Masis",
        "surname": "Harutyunyan",
        "dateOfBirth": "01 Jan., 1996",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "644",
        "title": "Edgar Harutyunyan",
        "name": "Edgar",
        "surname": "Harutyunyan",
        "dateOfBirth": "08 Sept. , 1982",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "643",
        "title": "Narek Hakobyan",
        "name": "Narek",
        "surname": "Hakobyan",
        "dateOfBirth": "02 Feb., 1991",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "642",
        "title": "Gor Hakobyan",
        "name": "Gor",
        "surname": "Hakobyan",
        "dateOfBirth": "21 July, 2000",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "641",
        "title": "Vlad Grigoryan",
        "name": "Vlad",
        "surname": "Grigoryan",
        "dateOfBirth": "11 Aug., 1999",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1180",
        "title": "Gnel Harutyunyan",
        "name": "Gnel",
        "surname": "Harutyunyan",
        "dateOfBirth": "25 Sept. , 1977",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1179",
        "title": "Norayr Haroyan",
        "name": "Norayr",
        "surname": "Haroyan",
        "dateOfBirth": "04 Feb., 1987",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1178",
        "title": "Shaliko Gevorgyan",
        "name": "Shaliko",
        "surname": "Gevorgyan",
        "dateOfBirth": "20 Mar., 1995",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1177",
        "title": "Hakob Hambardzumyan",
        "name": "Hakob",
        "surname": "Hambardzumyan",
        "dateOfBirth": "28 Oct., 1996",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1176",
        "title": "Taron Hakobyan",
        "name": "Taron",
        "surname": "Hakobyan",
        "dateOfBirth": "09 Sept. , 1987",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1175",
        "title": "Tigran Avagyan",
        "name": "Tigran",
        "surname": "Avagyan",
        "dateOfBirth": "22 Feb., 1996",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1174",
        "title": "Edvard Hakobyan",
        "name": "Edvard",
        "surname": "Hakobyan",
        "dateOfBirth": "01 Aug., 1993",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1173",
        "title": "Karen Grigoryan",
        "name": "Karen",
        "surname": "Grigoryan",
        "dateOfBirth": "26 Jan., 1988",
        "dateOfDeath": "15Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1172",
        "title": "Gevorg Kirakosyan",
        "name": "Gevorg",
        "surname": "Kirakosyan",
        "dateOfBirth": "22 Dec., 1996",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1171",
        "title": "Erik Grigoryan",
        "name": "Erik",
        "surname": "Grigoryan",
        "dateOfBirth": "26 Aug., 1983",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1820",
        "title": "Arkadi Palikyan",
        "name": "Arkadi",
        "surname": "Palikyan",
        "dateOfBirth": "01 Mar., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1819",
        "title": "Gevorg Gevorgyan",
        "name": "Gevorg",
        "surname": "Gevorgyan",
        "dateOfBirth": "19 July, 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1818",
        "title": "Hayk Mkrtchyan",
        "name": "Hayk",
        "surname": "Mkrtchyan",
        "dateOfBirth": "05 Dec., 1997",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1817",
        "title": "Jora Gevorgyan",
        "name": "Jora",
        "surname": "Gevorgyan",
        "dateOfBirth": "01 May, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1816",
        "title": "Samvel Gevorgyan",
        "name": "Samvel",
        "surname": "Gevorgyan",
        "dateOfBirth": "27 Apr., 1999",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1815",
        "title": "Hovhannes Korkotyan",
        "name": "Hovhannes",
        "surname": "Korkotyan",
        "dateOfBirth": "02 Feb., 1985",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1814",
        "title": "Karen Israelyan",
        "name": "Karen",
        "surname": "Israelyan",
        "dateOfBirth": "02 Jan., 1990",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1813",
        "title": "Tigran Gevorgyan",
        "name": "Tigran",
        "surname": "Gevorgyan",
        "dateOfBirth": "22 Dec., 1998",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1812",
        "title": "Ashot Hovhannisyan",
        "name": "Ashot",
        "surname": "Hovhannisyan",
        "dateOfBirth": "13 Aug., 1994",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1811",
        "title": "Davit Grigoryan",
        "name": "Davit",
        "surname": "Grigoryan",
        "dateOfBirth": "10 June, 1997",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2200",
        "title": "Armen Grigoryan",
        "name": "Armen",
        "surname": "Grigoryan",
        "dateOfBirth": "11 Oct., 1976",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2199",
        "title": "Tatul Otaryan",
        "name": "Tatul",
        "surname": "Otaryan",
        "dateOfBirth": "29 Apr., 1986",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2198",
        "title": "Khachik Karapetyan",
        "name": "Khachik",
        "surname": "Karapetyan",
        "dateOfBirth": "04 July, 1978",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2197",
        "title": "Artem Aloyan",
        "name": "Artem",
        "surname": "Aloyan",
        "dateOfBirth": "11 Aug., 1996",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2196",
        "title": "Khachatur Aghajanyan",
        "name": "Khachatur",
        "surname": "Aghajanyan",
        "dateOfBirth": "29 June, 1997",
        "dateOfDeath": "17Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2195",
        "title": "Lyeva Galstyan",
        "name": "Lyeva",
        "surname": "Galstyan",
        "dateOfBirth": "16 May, 1998",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2194",
        "title": "Tigran Melkonyan",
        "name": "Tigran",
        "surname": "Melkonyan",
        "dateOfBirth": "01 Nov., 1997",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2193",
        "title": "Lentush Gyozalyan",
        "name": "Lentush",
        "surname": "Gyozalyan",
        "dateOfBirth": "04 Sept. , 1993",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2192",
        "title": "Seyran Petrosyan",
        "name": "Seyran",
        "surname": "Petrosyan",
        "dateOfBirth": "08 May, 2001",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2191",
        "title": "Vardan Petrosyan",
        "name": "Vardan",
        "surname": "Petrosyan",
        "dateOfBirth": "26 Sept. , 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1560",
        "title": "Արամ Այվազյան Նվերի",
        "name": "Արամ",
        "surname": "Այվազյան Նվերի",
        "dateOfBirth": "06 Dec., 1997",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1559",
        "title": "Տիգրան Բադալյան Դավիթի",
        "name": "Տիգրան",
        "surname": "Բադալյան Դավիթի",
        "dateOfBirth": "11 Jan., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1558",
        "title": "Արտակ Բադեյան Վահանի",
        "name": "Արտակ",
        "surname": "Բադեյան Վահանի",
        "dateOfBirth": "29 Mar., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1557",
        "title": "Արեգ Բարսեղյան Արթուրի",
        "name": "Արեգ",
        "surname": "Բարսեղյան Արթուրի",
        "dateOfBirth": "12 Dec., 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1556",
        "title": "Նարեկ Բաղդասարյան Արտյոմի",
        "name": "Նարեկ",
        "surname": "Բաղդասարյան Արտյոմի",
        "dateOfBirth": "17 May, 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1555",
        "title": "Թաթուլ Ղազարյան Լավրենտի",
        "name": "Թաթուլ",
        "surname": "Ղազարյան Լավրենտի",
        "dateOfBirth": "25 May, 1987",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1554",
        "title": "Ժորա Քթրյան Սամվելի",
        "name": "Ժորա",
        "surname": "Քթրյան Սամվելի",
        "dateOfBirth": "15 July, 1996",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1553",
        "title": "Ալբերտ Բախշյան Արմենի",
        "name": "Ալբերտ",
        "surname": "Բախշյան Արմենի",
        "dateOfBirth": "20 Aug., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1552",
        "title": "Նվեր Չոբանյան Տիգրանի",
        "name": "Նվեր",
        "surname": "Չոբանյան Տիգրանի",
        "dateOfBirth": "16 Nov., 2001",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1551",
        "title": "Դավիթ Դավթյան Արմանի",
        "name": "Դավիթ",
        "surname": "Դավթյան Արմանի",
        "dateOfBirth": "16 Jan., 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2250",
        "title": "Elvis Israelyan",
        "name": "Elvis",
        "surname": "Israelyan",
        "dateOfBirth": "29 July, 1998",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2249",
        "title": "Narek Manukyan",
        "name": "Narek",
        "surname": "Manukyan",
        "dateOfBirth": "18 Oct., 1992",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2248",
        "title": "Vahe Melkonyan",
        "name": "Vahe",
        "surname": "Melkonyan",
        "dateOfBirth": "30 Oct., 1996",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2247",
        "title": "Grigor Mnatsakanyan",
        "name": "Grigor",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "06 Feb., 1995",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2246",
        "title": "Samvel Baghdasaryan",
        "name": "Samvel",
        "surname": "Baghdasaryan",
        "dateOfBirth": "17 Apr., 1984",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2245",
        "title": "Vachagan Darbinyan",
        "name": "Vachagan",
        "surname": "Darbinyan",
        "dateOfBirth": "12 Sept. , 1992",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2244",
        "title": "Vanik Grigoryan",
        "name": "Vanik",
        "surname": "Grigoryan",
        "dateOfBirth": "29 Oct., 1971",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2243",
        "title": "Janibek Nikoghosyan",
        "name": "Janibek",
        "surname": "Nikoghosyan",
        "dateOfBirth": "08 Aug., 1979",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2242",
        "title": "Babken Petrosyan",
        "name": "Babken",
        "surname": "Petrosyan",
        "dateOfBirth": "05 Dec., 1993",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2241",
        "title": "Hovhannes Petrosyan",
        "name": "Hovhannes",
        "surname": "Petrosyan",
        "dateOfBirth": "25 Sept. , 1996",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "970",
        "title": "Vahan Tadevosyan",
        "name": "Vahan",
        "surname": "Tadevosyan",
        "dateOfBirth": "18 Nov., 1987",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "969",
        "title": "Sargis Iskandaryan",
        "name": "Sargis",
        "surname": "Iskandaryan",
        "dateOfBirth": "26 Nov., 1989",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "968",
        "title": "Hayk Sukiasyan",
        "name": "Hayk",
        "surname": "Sukiasyan",
        "dateOfBirth": "01 Aug., 1996",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "967",
        "title": "Zakara Simonyan",
        "name": "Zakara",
        "surname": "Simonyan",
        "dateOfBirth": "14 Oct., 1979",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "966",
        "title": "Gor Simonyan",
        "name": "Gor",
        "surname": "Simonyan",
        "dateOfBirth": "07 July, 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "965",
        "title": "Hovhannes Hovhannesyan",
        "name": "Hovhannes",
        "surname": "Hovhannesyan",
        "dateOfBirth": "09 Feb., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "964",
        "title": "Erik Saryan",
        "name": "Erik",
        "surname": "Saryan",
        "dateOfBirth": "05 June, 2002",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "963",
        "title": "Roland Sargsyan",
        "name": "Roland",
        "surname": "Sargsyan",
        "dateOfBirth": "25 Sept. , 1981",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "962",
        "title": "Ara Hovakimyan",
        "name": "Ara",
        "surname": "Hovakimyan",
        "dateOfBirth": "31 Dec., 1998",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "961",
        "title": "Aleksandr Sargsyan",
        "name": "Aleksandr",
        "surname": "Sargsyan",
        "dateOfBirth": "18 Feb., 1988",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1900",
        "title": "Karen Asryan",
        "name": "Karen",
        "surname": "Asryan",
        "dateOfBirth": "17 Jan., 1982",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1899",
        "title": "Artashes Beglaryan",
        "name": "Artashes",
        "surname": "Beglaryan",
        "dateOfBirth": "18 July, 1988",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1898",
        "title": "Hayk Harutyunyan",
        "name": "Hayk",
        "surname": "Harutyunyan",
        "dateOfBirth": "15 Aug., 1995",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1897",
        "title": "Mkhitar Avagimyan",
        "name": "Mkhitar",
        "surname": "Avagimyan",
        "dateOfBirth": "08 July, 1973",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1896",
        "title": "Sevak Martirosyan",
        "name": "Sevak",
        "surname": "Martirosyan",
        "dateOfBirth": "20 Apr., 1990",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1895",
        "title": "Mkhitar Asryan",
        "name": "Mkhitar",
        "surname": "Asryan",
        "dateOfBirth": "01 Dec., 1983",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1894",
        "title": "Mkhitar Beglaryan",
        "name": "Mkhitar",
        "surname": "Beglaryan",
        "dateOfBirth": "12 Feb., 1990",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1893",
        "title": "Hayk Havatyan",
        "name": "Hayk",
        "surname": "Havatyan",
        "dateOfBirth": "28 Feb., 1984",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1892",
        "title": "Erik Gabrielyan",
        "name": "Erik",
        "surname": "Gabrielyan",
        "dateOfBirth": "14 Mar., 1979",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1891",
        "title": "Armen Sarkisyan",
        "name": "Armen",
        "surname": "Sarkisyan",
        "dateOfBirth": "06 Dec., 1978",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "660",
        "title": "Edgar Margaryan",
        "name": "Edgar",
        "surname": "Margaryan",
        "dateOfBirth": "19 Feb., 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "659",
        "title": "Edvard Maranjyan",
        "name": "Edvard",
        "surname": "Maranjyan",
        "dateOfBirth": "14 Apr., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "658",
        "title": "Pavel Manoukyan",
        "name": "Pavel",
        "surname": "Manoukyan",
        "dateOfBirth": "05 Mar., 2001",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "657",
        "title": "Erik Abrahamyan",
        "name": "Erik",
        "surname": "Abrahamyan",
        "dateOfBirth": "01 Mar., 2001",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "656",
        "title": "Arman Malkhasyan",
        "name": "Arman",
        "surname": "Malkhasyan",
        "dateOfBirth": "20 Sept. , 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "655",
        "title": "Aristakes Stepanyan",
        "name": "Aristakes",
        "surname": "Stepanyan",
        "dateOfBirth": "10 Feb., 1993",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "654",
        "title": "Vardan Hovhannisyan",
        "name": "Vardan",
        "surname": "Hovhannisyan",
        "dateOfBirth": "04 Oct., 2001",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "653",
        "title": "Gevorg Petrosyan",
        "name": "Gevorg",
        "surname": "Petrosyan",
        "dateOfBirth": "11 Oct., 2020",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "652",
        "title": "Apaven Stepanyan",
        "name": "Apaven",
        "surname": "Stepanyan",
        "dateOfBirth": "06 July, 1969",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "651",
        "title": "Harout Muradyan",
        "name": "Harout",
        "surname": "Muradyan",
        "dateOfBirth": "24 Nov., 2000",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2300",
        "title": "Igor Babayan",
        "name": "Igor",
        "surname": "Babayan",
        "dateOfBirth": "07 Feb., 1973",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2299",
        "title": "Artak Mirzoyan",
        "name": "Artak",
        "surname": "Mirzoyan",
        "dateOfBirth": "16 Aug., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2298",
        "title": "Mkrtich Hakobyan",
        "name": "Mkrtich",
        "surname": "Hakobyan",
        "dateOfBirth": "26 Feb., 1986",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2297",
        "title": "Aram Minasyan",
        "name": "Aram",
        "surname": "Minasyan",
        "dateOfBirth": "30 May, 1980",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2296",
        "title": "Tigran Avagyan",
        "name": "Tigran",
        "surname": "Avagyan",
        "dateOfBirth": "12 Feb., 1991",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2295",
        "title": "Roland Babayan",
        "name": "Roland",
        "surname": "Babayan",
        "dateOfBirth": "17 Sept. , 1960",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2294",
        "title": "Karen Hovhannisyan",
        "name": "Karen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "17 May, 1996",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2293",
        "title": "Mkrtich Gharibyan",
        "name": "Mkrtich",
        "surname": "Gharibyan",
        "dateOfBirth": "22 Feb., 1992",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2292",
        "title": "Baghdasar Gharibyan",
        "name": "Baghdasar",
        "surname": "Gharibyan",
        "dateOfBirth": "12 June, 1997",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2291",
        "title": "Sayat-Nova Barseghyan",
        "name": "Sayat-Nova",
        "surname": "Barseghyan",
        "dateOfBirth": "12 June, 1996",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1260",
        "title": "Hayk Gharsyan",
        "name": "Hayk",
        "surname": "Gharsyan",
        "dateOfBirth": "26 Dec., 1995",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1259",
        "title": "Vachagan Manoukyan",
        "name": "Vachagan",
        "surname": "Manoukyan",
        "dateOfBirth": "07 July, 1991",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1258",
        "title": "Lyova Khachatryan",
        "name": "Lyova",
        "surname": "Khachatryan",
        "dateOfBirth": "22 May, 1991",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1257",
        "title": "Hayk Khachatryan",
        "name": "Hayk",
        "surname": "Khachatryan",
        "dateOfBirth": "27 Dec., 1986",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1256",
        "title": "Davit Manoukyan",
        "name": "Davit",
        "surname": "Manoukyan",
        "dateOfBirth": "08 Jan., 1987",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1255",
        "title": "Vyacheslav Voskovski",
        "name": "Vyacheslav",
        "surname": "Voskovski",
        "dateOfBirth": "03 Aug., 1975",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1254",
        "title": "Mesrop Vardanyan",
        "name": "Mesrop",
        "surname": "Vardanyan",
        "dateOfBirth": "13 Mar., 1990",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1253",
        "title": "Suren Vahanyan",
        "name": "Suren",
        "surname": "Vahanyan",
        "dateOfBirth": "16 Sept. , 1994",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1252",
        "title": "Melik Mamikonyan",
        "name": "Melik",
        "surname": "Mamikonyan",
        "dateOfBirth": "04 July, 1997",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1251",
        "title": "Taron Stepanyan",
        "name": "Taron",
        "surname": "Stepanyan",
        "dateOfBirth": "04 Nov., 1992",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "640",
        "title": "Karen Grigoryan",
        "name": "Karen",
        "surname": "Grigoryan",
        "dateOfBirth": "19 Aug., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "639",
        "title": "Ishkhan Grigoryan",
        "name": "Ishkhan",
        "surname": "Grigoryan",
        "dateOfBirth": "19 Mar., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "638",
        "title": "Alen Vardanyan",
        "name": "Alen",
        "surname": "Vardanyan",
        "dateOfBirth": "18 Aug., 2001",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "637",
        "title": "Artashes Khalatyan",
        "name": "Artashes",
        "surname": "Khalatyan",
        "dateOfBirth": "17 Sept. , 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "636",
        "title": "Manuk Torosyan",
        "name": "Manuk",
        "surname": "Torosyan",
        "dateOfBirth": "09 May, 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "635",
        "title": "Hovhannes Grigoryan",
        "name": "Hovhannes",
        "surname": "Grigoryan",
        "dateOfBirth": "28 Oct., 1984",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "634",
        "title": "Arzyman Torosyan",
        "name": "Arzyman",
        "surname": "Torosyan",
        "dateOfBirth": "12 Apr., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "633",
        "title": "Grigor Tagvoryan",
        "name": "Grigor",
        "surname": "Tagvoryan",
        "dateOfBirth": "19 Sept. , 1999",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "632",
        "title": "Hayk Grigoryan",
        "name": "Hayk",
        "surname": "Grigoryan",
        "dateOfBirth": "17 Feb., 1985",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "631",
        "title": "Lyudvig Haroutyunyan",
        "name": "Lyudvig",
        "surname": "Haroutyunyan",
        "dateOfBirth": "14 Dec., 1998",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2270",
        "title": "Robert Sargsyan",
        "name": "Robert",
        "surname": "Sargsyan",
        "dateOfBirth": "17 Nov., 1992",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2269",
        "title": "Mnatsakan Shaboyan",
        "name": "Mnatsakan",
        "surname": "Shaboyan",
        "dateOfBirth": "02 Sept. , 1988",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2268",
        "title": "Zhora Khachatryan",
        "name": "Zhora",
        "surname": "Khachatryan",
        "dateOfBirth": "22 May, 1997",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2267",
        "title": "Harutyun Khachatryan",
        "name": "Harutyun",
        "surname": "Khachatryan",
        "dateOfBirth": "15 Sept. , 1997",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2266",
        "title": "Smbat Baghdasaryan",
        "name": "Smbat",
        "surname": "Baghdasaryan",
        "dateOfBirth": "14 Aug., 1991",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2265",
        "title": "Garnik Antonyan",
        "name": "Garnik",
        "surname": "Antonyan",
        "dateOfBirth": "19 Oct., 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2264",
        "title": "Arthur Ghazaryan",
        "name": "Arthur",
        "surname": "Ghazaryan",
        "dateOfBirth": "10 Feb., 1976",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2263",
        "title": "Vanik Rafayelyan",
        "name": "Vanik",
        "surname": "Rafayelyan",
        "dateOfBirth": "31 July, 1993",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2262",
        "title": "Zohrab Kocharyan",
        "name": "Zohrab",
        "surname": "Kocharyan",
        "dateOfBirth": "15 May, 1991",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2261",
        "title": "Sevak Ohanyan",
        "name": "Sevak",
        "surname": "Ohanyan",
        "dateOfBirth": "04 Oct., 1985",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1190",
        "title": "Hayk Ghrimyan",
        "name": "Hayk",
        "surname": "Ghrimyan",
        "dateOfBirth": "12 Jan., 1992",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1189",
        "title": "Mushegh Petrosyan",
        "name": "Mushegh",
        "surname": "Petrosyan",
        "dateOfBirth": "16 Mar., 1988",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1188",
        "title": "Gevorg Petrosyan",
        "name": "Gevorg",
        "surname": "Petrosyan",
        "dateOfBirth": "09 Jan., 1990",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1187",
        "title": "Edvard Petrosyan",
        "name": "Edvard",
        "surname": "Petrosyan",
        "dateOfBirth": "07 July, 1991",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1186",
        "title": "Gevorg Kandalyan",
        "name": "Gevorg",
        "surname": "Kandalyan",
        "dateOfBirth": "12 July, 1998",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1185",
        "title": "Davit Arzumanyan",
        "name": "Davit",
        "surname": "Arzumanyan",
        "dateOfBirth": "21 Jan., 1986",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1184",
        "title": "Tigran Hovhannisyan",
        "name": "Tigran",
        "surname": "Hovhannisyan",
        "dateOfBirth": "30 Jan., 1988",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1183",
        "title": "Hambardzum Hovhannisyan",
        "name": "Hambardzum",
        "surname": "Hovhannisyan",
        "dateOfBirth": "18 Apr., 1994",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1182",
        "title": "Gevorg Hovhannisyan",
        "name": "Gevorg",
        "surname": "Hovhannisyan",
        "dateOfBirth": "10 June, 1991",
        "dateOfDeath": "18Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1181",
        "title": "Vardan Hayrapetyan",
        "name": "Vardan",
        "surname": "Hayrapetyan",
        "dateOfBirth": "29 June, 1987",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "570",
        "title": "Hovhannes Haroutyunyan",
        "name": "Hovhannes",
        "surname": "Haroutyunyan",
        "dateOfBirth": "06 Aug., 1991",
        "dateOfDeath": "11Oct.,2000",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "569",
        "title": "Davit Grigoryan",
        "name": "Davit",
        "surname": "Grigoryan",
        "dateOfBirth": "05 Oct., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "568",
        "title": "Artak Sargsyan",
        "name": "Artak",
        "surname": "Sargsyan",
        "dateOfBirth": "23 Feb., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "567",
        "title": "Arsen Sargsyan",
        "name": "Arsen",
        "surname": "Sargsyan",
        "dateOfBirth": "23 July, 2001",
        "dateOfDeath": "23Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "566",
        "title": "Abraham Sadoyan",
        "name": "Abraham",
        "surname": "Sadoyan",
        "dateOfBirth": "24 Apr., 2002",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "565",
        "title": "Aren Petrosyan",
        "name": "Aren",
        "surname": "Petrosyan",
        "dateOfBirth": "08 Aug., 2001",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "564",
        "title": "Vahan Babayan",
        "name": "Vahan",
        "surname": "Babayan",
        "dateOfBirth": "26 Nov., 1987",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "563",
        "title": "Rafik Ayvazyan",
        "name": "Rafik",
        "surname": "Ayvazyan",
        "dateOfBirth": "27 Feb., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "562",
        "title": "Vahan Aghakhanyan",
        "name": "Vahan",
        "surname": "Aghakhanyan",
        "dateOfBirth": "15 Dec., 2000",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "561",
        "title": "Vardan Ashughatoyan",
        "name": "Vardan",
        "surname": "Ashughatoyan",
        "dateOfBirth": "30 June, 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1650",
        "title": "Ռուբիկ Գուլեյան Մելքոնի",
        "name": "Ռուբիկ",
        "surname": "Գուլեյան Մելքոնի",
        "dateOfBirth": "28 Nov., 2000",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1649",
        "title": "Ռազմիկ Սահակյան Հենրիի",
        "name": "Ռազմիկ",
        "surname": "Սահակյան Հենրիի",
        "dateOfBirth": "23 Oct., 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1648",
        "title": "Արտաշես Հակոբյան Գևորգի",
        "name": "Արտաշես",
        "surname": "Հակոբյան Գևորգի",
        "dateOfBirth": "12 Sept. , 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1647",
        "title": "Վահե Սարգսյան Սարգսի",
        "name": "Վահե",
        "surname": "Սարգսյան Սարգսի",
        "dateOfBirth": "18 Oct., 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1646",
        "title": "Հակոբ Հարությունյան Արայիկի",
        "name": "Հակոբ",
        "surname": "Հարությունյան Արայիկի",
        "dateOfBirth": "12 Dec., 2000",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1645",
        "title": "Վարուժան Սարգսյան Գևորգի",
        "name": "Վարուժան",
        "surname": "Սարգսյան Գևորգի",
        "dateOfBirth": "26 Oct., 2000",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1644",
        "title": "Սարգիս Սառիկյան Ավետիքի",
        "name": "Սարգիս",
        "surname": "Սառիկյան Ավետիքի",
        "dateOfBirth": "21 Sept. , 2001",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1643",
        "title": "Ղազար Սարուխանյան Սուրենի",
        "name": "Ղազար",
        "surname": "Սարուխանյան Սուրենի",
        "dateOfBirth": "29 May, 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1642",
        "title": "Արզուման Շմավոնյան Շմավոնի",
        "name": "Արզուման",
        "surname": "Շմավոնյան Շմավոնի",
        "dateOfBirth": "21 Jan., 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1641",
        "title": "Տիգրան Հարությունյան Ժորայի",
        "name": "Տիգրան",
        "surname": "Հարությունյան Ժորայի",
        "dateOfBirth": "27 Jan., 1999",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1040",
        "title": "Arman Ohanyan",
        "name": "Arman",
        "surname": "Ohanyan",
        "dateOfBirth": "30 June, 1996",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1039",
        "title": "Andranik Grigoryan",
        "name": "Andranik",
        "surname": "Grigoryan",
        "dateOfBirth": "07 Dec., 1993",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1038",
        "title": "Robert Hovhannisyan",
        "name": "Robert",
        "surname": "Hovhannisyan",
        "dateOfBirth": "22 Oct., 1998",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1037",
        "title": "Aram Parsadanyan",
        "name": "Aram",
        "surname": "Parsadanyan",
        "dateOfBirth": "13 July, 1972",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1036",
        "title": "Taron Givargizyan",
        "name": "Taron",
        "surname": "Givargizyan",
        "dateOfBirth": "29 Dec., 1996",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1035",
        "title": "Rafik Gasparyan",
        "name": "Rafik",
        "surname": "Gasparyan",
        "dateOfBirth": "08 Sept. , 1998",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1034",
        "title": "Azat Hovhannisyan",
        "name": "Azat",
        "surname": "Hovhannisyan",
        "dateOfBirth": "01 July, 1996",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1033",
        "title": "Hayk Gasparyan",
        "name": "Hayk",
        "surname": "Gasparyan",
        "dateOfBirth": "02 Sept. , 1984",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1032",
        "title": "Arman Ohanyan",
        "name": "Arman",
        "surname": "Ohanyan",
        "dateOfBirth": "03 Sept. , 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1031",
        "title": "Mkrtich Aghanyan",
        "name": "Mkrtich",
        "surname": "Aghanyan",
        "dateOfBirth": "03 Oct., 1990",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2400",
        "title": "Varuzhan Brutyan",
        "name": "Varuzhan",
        "surname": "Brutyan",
        "dateOfBirth": "11 Mar., 1968",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2399",
        "title": "Kamo Arakelyan",
        "name": "Kamo",
        "surname": "Arakelyan",
        "dateOfBirth": "10 June, 1985",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2398",
        "title": "Armenak Madatyan",
        "name": "Armenak",
        "surname": "Madatyan",
        "dateOfBirth": "11 July, 1965",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2397",
        "title": "Hovik Avakyan",
        "name": "Hovik",
        "surname": "Avakyan",
        "dateOfBirth": "15 Feb., 1968",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2396",
        "title": "Abrik Matevosyan",
        "name": "Abrik",
        "surname": "Matevosyan",
        "dateOfBirth": "02 Nov., 1971",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2395",
        "title": "David Jalavyan",
        "name": "David",
        "surname": "Jalavyan",
        "dateOfBirth": "03 Dec., 1999",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2394",
        "title": "Zaven Knyazyan",
        "name": "Zaven",
        "surname": "Knyazyan",
        "dateOfBirth": "13 Nov., 1983",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2393",
        "title": "Hovhannes Margaryan",
        "name": "Hovhannes",
        "surname": "Margaryan",
        "dateOfBirth": "25 Nov., 1987",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2392",
        "title": "Eduard Martirosyan",
        "name": "Eduard",
        "surname": "Martirosyan",
        "dateOfBirth": "18 Sept. , 1973",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2391",
        "title": "Yakov Afiyan",
        "name": "Yakov",
        "surname": "Afiyan",
        "dateOfBirth": "01 Aug., 1989",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "610",
        "title": "Vahe Gharibyan",
        "name": "Vahe",
        "surname": "Gharibyan",
        "dateOfBirth": "18 June, 1988",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "609",
        "title": "Mher Potoyan",
        "name": "Mher",
        "surname": "Potoyan",
        "dateOfBirth": "30 Jan., 1998",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "608",
        "title": "Vahagn Tumasyan",
        "name": "Vahagn",
        "surname": "Tumasyan",
        "dateOfBirth": "05 Aug., 1981",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "607",
        "title": "Roland Nersisyan",
        "name": "Roland",
        "surname": "Nersisyan",
        "dateOfBirth": "10 Jan., 1984",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "606",
        "title": "David Nazaryan",
        "name": "David",
        "surname": "Nazaryan",
        "dateOfBirth": "09 July, 1985",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "605",
        "title": "Mher Manucharyan",
        "name": "Mher",
        "surname": "Manucharyan",
        "dateOfBirth": "12 Nov., 2020",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "604",
        "title": "David Kirakosyan",
        "name": "David",
        "surname": "Kirakosyan",
        "dateOfBirth": "24 June, 2000",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "603",
        "title": "Sergey Hovhannisyan",
        "name": "Sergey",
        "surname": "Hovhannisyan",
        "dateOfBirth": "23 Apr., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "602",
        "title": "Vardan Musayelyan",
        "name": "Vardan",
        "surname": "Musayelyan",
        "dateOfBirth": "08 June, 2002",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "601",
        "title": "Hamlet Hovhannisyan",
        "name": "Hamlet",
        "surname": "Hovhannisyan",
        "dateOfBirth": "27 Oct., 1994",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "750",
        "title": "Samad Sloyan",
        "name": "Samad",
        "surname": "Sloyan",
        "dateOfBirth": "01 Mar., 2001",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "749",
        "title": "Hovhannes Melkumyan",
        "name": "Hovhannes",
        "surname": "Melkumyan",
        "dateOfBirth": "30 Apr., 1977",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "748",
        "title": "Vahag Mkheyan",
        "name": "Vahag",
        "surname": "Mkheyan",
        "dateOfBirth": "26 Mar., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "747",
        "title": "Vahe Mkrtchyan",
        "name": "Vahe",
        "surname": "Mkrtchyan",
        "dateOfBirth": "25 Apr., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "746",
        "title": "Aram Baghdasaryan",
        "name": "Aram",
        "surname": "Baghdasaryan",
        "dateOfBirth": "01 Apr., 2002",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "745",
        "title": "David Mosoyan",
        "name": "David",
        "surname": "Mosoyan",
        "dateOfBirth": "28 Aug., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "744",
        "title": "Aleksan Saghatelyan",
        "name": "Aleksan",
        "surname": "Saghatelyan",
        "dateOfBirth": "16 July, 1996",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "743",
        "title": "David Baghdasaryan",
        "name": "David",
        "surname": "Baghdasaryan",
        "dateOfBirth": "19 Apr., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "742",
        "title": "Giorgi Minasyan",
        "name": "Giorgi",
        "surname": "Minasyan",
        "dateOfBirth": "03 Oct., 1979",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "741",
        "title": "Vahe Aghababyan",
        "name": "Vahe",
        "surname": "Aghababyan",
        "dateOfBirth": "29 Oct., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1660",
        "title": "Ռոբերտ Մկրտչյան Էդգարի",
        "name": "Ռոբերտ",
        "surname": "Մկրտչյան Էդգարի",
        "dateOfBirth": "07 Mar., 2002",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1659",
        "title": "Արման Գևորգյան Արթուրի",
        "name": "Արման",
        "surname": "Գևորգյան Արթուրի",
        "dateOfBirth": "05 Mar., 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1658",
        "title": "Գևորգ Գևորգյան Կարապետի",
        "name": "Գևորգ",
        "surname": "Գևորգյան Կարապետի",
        "dateOfBirth": "14 Feb., 2002",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1657",
        "title": "Բորիկ Մուսինյան Սուրենի",
        "name": "Բորիկ",
        "surname": "Մուսինյան Սուրենի",
        "dateOfBirth": "30 Sept. , 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1656",
        "title": "Վարդան  Մովսեսյան Սերյոժայի",
        "name": "Վարդան",
        "surname": " Մովսեսյան Սերյոժայի",
        "dateOfBirth": "30 Nov., 1983",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1655",
        "title": "Մանվել Գևորգյան Ռուդիկի",
        "name": "Մանվել",
        "surname": "Գևորգյան Ռուդիկի",
        "dateOfBirth": "16 May, 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1654",
        "title": "Բենիամին Նալբանդյան Գառնիկի",
        "name": "Բենիամին",
        "surname": "Նալբանդյան Գառնիկի",
        "dateOfBirth": "08 Jan., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1653",
        "title": "Սարգիս Գևորգյան Արայիկի",
        "name": "Սարգիս",
        "surname": "Գևորգյան Արայիկի",
        "dateOfBirth": "24 May, 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1652",
        "title": "Գևորգ Քառյան Գրիգորի",
        "name": "Գևորգ",
        "surname": "Քառյան Գրիգորի",
        "dateOfBirth": "03 Aug., 2001",
        "dateOfDeath": "11Oct.,2000",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1651",
        "title": "Արմեն Սահակյան Սամվելի",
        "name": "Արմեն",
        "surname": "Սահակյան Սամվելի",
        "dateOfBirth": "03 July, 2001",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1100",
        "title": "Arman Khachatryan",
        "name": "Arman",
        "surname": "Khachatryan",
        "dateOfBirth": "07 Mar., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1099",
        "title": "Erik Yeganyan",
        "name": "Erik",
        "surname": "Yeganyan",
        "dateOfBirth": "13 Jan., 1981",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1098",
        "title": "Arsen Manasyan",
        "name": "Arsen",
        "surname": "Manasyan",
        "dateOfBirth": "17 June, 1999",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1097",
        "title": "Grigor Chghrikyan",
        "name": "Grigor",
        "surname": "Chghrikyan",
        "dateOfBirth": "14 Jan., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1096",
        "title": "Sargis Tsaturyan",
        "name": "Sargis",
        "surname": "Tsaturyan",
        "dateOfBirth": "14 Feb., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1095",
        "title": "Valentin Burnusuzyan",
        "name": "Valentin",
        "surname": "Burnusuzyan",
        "dateOfBirth": "29 Nov., 1985",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1094",
        "title": "Gor Baroyan",
        "name": "Gor",
        "surname": "Baroyan",
        "dateOfBirth": "01 Oct., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1093",
        "title": "Nver Badoyan",
        "name": "Nver",
        "surname": "Badoyan",
        "dateOfBirth": "15 Aug., 1972",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1092",
        "title": "Artyom Malkhasyan",
        "name": "Artyom",
        "surname": "Malkhasyan",
        "dateOfBirth": "18 May, 1982",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1091",
        "title": "Ashot Baboyan",
        "name": "Ashot",
        "surname": "Baboyan",
        "dateOfBirth": "27 Aug., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "730",
        "title": "Armen Stepanyan",
        "name": "Armen",
        "surname": "Stepanyan",
        "dateOfBirth": "10 Feb., 1997",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "729",
        "title": "Arman Badasyan",
        "name": "Arman",
        "surname": "Badasyan",
        "dateOfBirth": "21 July, 1992",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "728",
        "title": "Sarmen Ayvazyan",
        "name": "Sarmen",
        "surname": "Ayvazyan",
        "dateOfBirth": "01 Oct., 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "727",
        "title": "Gor Ghevondyan",
        "name": "Gor",
        "surname": "Ghevondyan",
        "dateOfBirth": "11 Oct., 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "726",
        "title": "Davit Ghazaryan",
        "name": "Davit",
        "surname": "Ghazaryan",
        "dateOfBirth": "05 May, 1989",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "725",
        "title": "Galust Nazaryan",
        "name": "Galust",
        "surname": "Nazaryan",
        "dateOfBirth": "08 Sept. , 1999",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "724",
        "title": "Shahen Ghanadyan",
        "name": "Shahen",
        "surname": "Ghanadyan",
        "dateOfBirth": "02 Dec., 1978",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "723",
        "title": "Hovhannes Yeliseev",
        "name": "Hovhannes",
        "surname": "Yeliseev",
        "dateOfBirth": "12 June, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "722",
        "title": "Seryozha Panosyan",
        "name": "Seryozha",
        "surname": "Panosyan",
        "dateOfBirth": "15 Apr., 2020",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "721",
        "title": "David Khachatryan",
        "name": "David",
        "surname": "Khachatryan",
        "dateOfBirth": "05 Sept. , 1991",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "840",
        "title": "David Gevorgyan",
        "name": "David",
        "surname": "Gevorgyan",
        "dateOfBirth": "24 Feb., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "839",
        "title": "Artak Enokyan",
        "name": "Artak",
        "surname": "Enokyan",
        "dateOfBirth": "10 Sept. , 2000",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "838",
        "title": "Levon Stepanyan",
        "name": "Levon",
        "surname": "Stepanyan",
        "dateOfBirth": "03 July, 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "837",
        "title": "Levon Avetisyan",
        "name": "Levon",
        "surname": "Avetisyan",
        "dateOfBirth": "18 Mar., 1990",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "836",
        "title": "David Yakovlev",
        "name": "David",
        "surname": "Yakovlev",
        "dateOfBirth": "18 June, 2001",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "835",
        "title": "Gor Ghazaryan",
        "name": "Gor",
        "surname": "Ghazaryan",
        "dateOfBirth": "11 Dec., 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "834",
        "title": "Hovhannes Ghambaryan",
        "name": "Hovhannes",
        "surname": "Ghambaryan",
        "dateOfBirth": "18 July, 2000",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "833",
        "title": "Artak Balbabyan",
        "name": "Artak",
        "surname": "Balbabyan",
        "dateOfBirth": "01 Aug., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "832",
        "title": "Vyacheslav Badalyan",
        "name": "Vyacheslav",
        "surname": "Badalyan",
        "dateOfBirth": "27 May, 1998",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "831",
        "title": "Ashot Khachatryan",
        "name": "Ashot",
        "surname": "Khachatryan",
        "dateOfBirth": "12 Jan., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "630",
        "title": "David Hakobyan",
        "name": "David",
        "surname": "Hakobyan",
        "dateOfBirth": "23 Dec., 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "629",
        "title": "Armen Galeyan",
        "name": "Armen",
        "surname": "Galeyan",
        "dateOfBirth": "24 Apr., 1985",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "628",
        "title": "Arsen Eghoyan",
        "name": "Arsen",
        "surname": "Eghoyan",
        "dateOfBirth": "01 Aug., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "627",
        "title": "Grigor Davtyan",
        "name": "Grigor",
        "surname": "Davtyan",
        "dateOfBirth": "15 July, 2000",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "626",
        "title": "Armen Dadamyan",
        "name": "Armen",
        "surname": "Dadamyan",
        "dateOfBirth": "09 June, 1980",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "625",
        "title": "Noy Tevanyan",
        "name": "Noy",
        "surname": "Tevanyan",
        "dateOfBirth": "19 Sept. , 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "624",
        "title": "David Hakobyan",
        "name": "David",
        "surname": "Hakobyan",
        "dateOfBirth": "22 Apr., 2001",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "623",
        "title": "Arman Asryan",
        "name": "Arman",
        "surname": "Asryan",
        "dateOfBirth": "09 Aug., 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "622",
        "title": "David Aleyan",
        "name": "David",
        "surname": "Aleyan",
        "dateOfBirth": "21 Nov., 2001",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "621",
        "title": "Hakob Shaxmuradyan",
        "name": "Hakob",
        "surname": "Shaxmuradyan",
        "dateOfBirth": "28 June, 1995",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "670",
        "title": "Artur Atoyan",
        "name": "Artur",
        "surname": "Atoyan",
        "dateOfBirth": "16 Sept. , 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "669",
        "title": "Vahagn Asatryan",
        "name": "Vahagn",
        "surname": "Asatryan",
        "dateOfBirth": "18 Jan., 1977",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "668",
        "title": "Ashot Bakhdagyulyan",
        "name": "Ashot",
        "surname": "Bakhdagyulyan",
        "dateOfBirth": "20 Feb., 1973",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "667",
        "title": "Aren Arzumanyan",
        "name": "Aren",
        "surname": "Arzumanyan",
        "dateOfBirth": "11 Oct., 2000",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "666",
        "title": "Arman Archunts",
        "name": "Arman",
        "surname": "Archunts",
        "dateOfBirth": "26 Jan., 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "665",
        "title": "Makich Badalyan",
        "name": "Makich",
        "surname": "Badalyan",
        "dateOfBirth": "08 Feb., 2002",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "664",
        "title": "Henrik Aramyan",
        "name": "Henrik",
        "surname": "Aramyan",
        "dateOfBirth": "21 July, 2000",
        "dateOfDeath": "19May,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "663",
        "title": "Anton Antonyan",
        "name": "Anton",
        "surname": "Antonyan",
        "dateOfBirth": "30 Oct., 1984",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "662",
        "title": "Zarzand Nersisyan",
        "name": "Zarzand",
        "surname": "Nersisyan",
        "dateOfBirth": "12 Jan., 1989",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "661",
        "title": "Mkhitar Amirkhanyan",
        "name": "Mkhitar",
        "surname": "Amirkhanyan",
        "dateOfBirth": "04 Dec., 1989",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "540",
        "title": "Narek Vardanyan",
        "name": "Narek",
        "surname": "Vardanyan",
        "dateOfBirth": "19 Apr., 1993",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "539",
        "title": "Garik Gapoyan",
        "name": "Garik",
        "surname": "Gapoyan",
        "dateOfBirth": "20 Apr., 1989",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "538",
        "title": "Artak Khemchyan",
        "name": "Artak",
        "surname": "Khemchyan",
        "dateOfBirth": "06 June, 1982",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "537",
        "title": "Minas Ghazaryan",
        "name": "Minas",
        "surname": "Ghazaryan",
        "dateOfBirth": "03 Jan., 1965",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "536",
        "title": "Gevorg Karapetyan",
        "name": "Gevorg",
        "surname": "Karapetyan",
        "dateOfBirth": "10 July, 1999",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "535",
        "title": "Sevak Bazunc",
        "name": "Sevak",
        "surname": "Bazunc",
        "dateOfBirth": "30 Oct., 1995",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "534",
        "title": "Gevorg Baghdishyan",
        "name": "Gevorg",
        "surname": "Baghdishyan",
        "dateOfBirth": "02 Sept. , 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "533",
        "title": "Sergey Hovhannisyan",
        "name": "Sergey",
        "surname": "Hovhannisyan",
        "dateOfBirth": "04 Apr., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "532",
        "title": "Garnik Hovhannisyan",
        "name": "Garnik",
        "surname": "Hovhannisyan",
        "dateOfBirth": "23 Dec., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "531",
        "title": "Ashot Ghaltakhchyan",
        "name": "Ashot",
        "surname": "Ghaltakhchyan",
        "dateOfBirth": "31 Aug., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1270",
        "title": "Jora Dallaqyan",
        "name": "Jora",
        "surname": "Dallaqyan",
        "dateOfBirth": "20 Nov., 1992",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1269",
        "title": "Karen Balyan",
        "name": "Karen",
        "surname": "Balyan",
        "dateOfBirth": "26 Aug., 1984",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1268",
        "title": "Mikayel Babakhanyan",
        "name": "Mikayel",
        "surname": "Babakhanyan",
        "dateOfBirth": "16 Dec., 1997",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1267",
        "title": "Armen Martirosyan",
        "name": "Armen",
        "surname": "Martirosyan",
        "dateOfBirth": "24 Apr., 1987",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1266",
        "title": "Karen Ayvazyan",
        "name": "Karen",
        "surname": "Ayvazyan",
        "dateOfBirth": "10 Dec., 1982",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1265",
        "title": "Arman Zkoyan",
        "name": "Arman",
        "surname": "Zkoyan",
        "dateOfBirth": "03 Mar., 1992",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1264",
        "title": "Sevak Khorenyan",
        "name": "Sevak",
        "surname": "Khorenyan",
        "dateOfBirth": "08 Oct., 1987",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1263",
        "title": "Manik Margaryan",
        "name": "Manik",
        "surname": "Margaryan",
        "dateOfBirth": "10 Aug., 1985",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1262",
        "title": "Hayk Mardanyan",
        "name": "Hayk",
        "surname": "Mardanyan",
        "dateOfBirth": "09 Dec., 1979",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1261",
        "title": "Vladimir Ayvazyan",
        "name": "Vladimir",
        "surname": "Ayvazyan",
        "dateOfBirth": "22 Jan., 1982",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1790",
        "title": "Andranik Movsisyan",
        "name": "Andranik",
        "surname": "Movsisyan",
        "dateOfBirth": "07 Aug., 1987",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1789",
        "title": "Artur Nikoghosyan",
        "name": "Artur",
        "surname": "Nikoghosyan",
        "dateOfBirth": "15 Oct., 1991",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1788",
        "title": "Rafik Ordoyan",
        "name": "Rafik",
        "surname": "Ordoyan",
        "dateOfBirth": "26 Nov., 1994",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1787",
        "title": "Shavarsh Muradyan",
        "name": "Shavarsh",
        "surname": "Muradyan",
        "dateOfBirth": "18 Nov., 1996",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1786",
        "title": "Gegham Musheghyan",
        "name": "Gegham",
        "surname": "Musheghyan",
        "dateOfBirth": "04 Sept. , 1969",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1785",
        "title": "Moris Grigoryan",
        "name": "Moris",
        "surname": "Grigoryan",
        "dateOfBirth": "01 July, 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1784",
        "title": "Harutyun Harutyunyan",
        "name": "Harutyun",
        "surname": "Harutyunyan",
        "dateOfBirth": "03 June, 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1783",
        "title": "Tigran Harutyunyan",
        "name": "Tigran",
        "surname": "Harutyunyan",
        "dateOfBirth": "07 Sept. , 1997",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1782",
        "title": "David Hovhannisyan",
        "name": "David",
        "surname": "Hovhannisyan",
        "dateOfBirth": "21 Dec., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1781",
        "title": "Harutyun Hovhannisyan",
        "name": "Harutyun",
        "surname": "Hovhannisyan",
        "dateOfBirth": "26 Dec., 1998",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1360",
        "title": "Rustam Galstyan",
        "name": "Rustam",
        "surname": "Galstyan",
        "dateOfBirth": "30 Sept. , 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1359",
        "title": "Vahe Mirzoyan",
        "name": "Vahe",
        "surname": "Mirzoyan",
        "dateOfBirth": "01 July, 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1358",
        "title": "Hrant Eghiazaryan",
        "name": "Hrant",
        "surname": "Eghiazaryan",
        "dateOfBirth": "19 Sept. , 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1357",
        "title": "Harutyun Melqonyan",
        "name": "Harutyun",
        "surname": "Melqonyan",
        "dateOfBirth": "01 July, 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1356",
        "title": "Boris Esayan",
        "name": "Boris",
        "surname": "Esayan",
        "dateOfBirth": "29 Oct., 2000",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1355",
        "title": "Avetik Melkonyan",
        "name": "Avetik",
        "surname": "Melkonyan",
        "dateOfBirth": "06 Aug., 2001",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1354",
        "title": "Hovhannes Beyrutyan",
        "name": "Hovhannes",
        "surname": "Beyrutyan",
        "dateOfBirth": "07 Sept. , 1997",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1353",
        "title": "Davit Madoyan",
        "name": "Davit",
        "surname": "Madoyan",
        "dateOfBirth": "22 Aug., 2001",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1352",
        "title": "Rafayel Khachatryan",
        "name": "Rafayel",
        "surname": "Khachatryan",
        "dateOfBirth": "29 May, 2002",
        "dateOfDeath": "25Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1351",
        "title": "Narek Badalyan",
        "name": "Narek",
        "surname": "Badalyan",
        "dateOfBirth": "06 Oct., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2390",
        "title": "Arkady Miskaryan",
        "name": "Arkady",
        "surname": "Miskaryan",
        "dateOfBirth": "23 Sept. , 1975",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2389",
        "title": "Avetis Avetisyan",
        "name": "Avetis",
        "surname": "Avetisyan",
        "dateOfBirth": "27 Mar., 1990",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2388",
        "title": "Nareg Agayan",
        "name": "Nareg",
        "surname": "Agayan",
        "dateOfBirth": "30 May, 1994",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2387",
        "title": "Vardan Gevorgyan",
        "name": "Vardan",
        "surname": "Gevorgyan",
        "dateOfBirth": "12 June, 1977",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2386",
        "title": "Hovik Grigoryan",
        "name": "Hovik",
        "surname": "Grigoryan",
        "dateOfBirth": "21 May, 1996",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2385",
        "title": "Zakar Sargsyan",
        "name": "Zakar",
        "surname": "Sargsyan",
        "dateOfBirth": "16 Jan., 1996",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2384",
        "title": "Levon Israelyan",
        "name": "Levon",
        "surname": "Israelyan",
        "dateOfBirth": "28 Dec., 1979",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2383",
        "title": "Artak Khachatryan",
        "name": "Artak",
        "surname": "Khachatryan",
        "dateOfBirth": "23 Apr., 1993",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2382",
        "title": "Nikolay Israelyan",
        "name": "Nikolay",
        "surname": "Israelyan",
        "dateOfBirth": "31 May, 1990",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2381",
        "title": "Martin Martirosyan",
        "name": "Martin",
        "surname": "Martirosyan",
        "dateOfBirth": "02 June, 1978",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2230",
        "title": "Arsen Grigoryan",
        "name": "Arsen",
        "surname": "Grigoryan",
        "dateOfBirth": "24 Oct., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2229",
        "title": "Mher Grigoryan",
        "name": "Mher",
        "surname": "Grigoryan",
        "dateOfBirth": "10 Apr., 2002",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2228",
        "title": "Arman Harutyunyan",
        "name": "Arman",
        "surname": "Harutyunyan",
        "dateOfBirth": "06 Nov., 2001",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2227",
        "title": "Vahe Atabekyan",
        "name": "Vahe",
        "surname": "Atabekyan",
        "dateOfBirth": "01 June, 1964",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2226",
        "title": "Vahagn Avanesyan",
        "name": "Vahagn",
        "surname": "Avanesyan",
        "dateOfBirth": "06 Apr., 2002",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2225",
        "title": "Marat Barseghyan",
        "name": "Marat",
        "surname": "Barseghyan",
        "dateOfBirth": "18 Feb., 2002",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2224",
        "title": "Karen Sahakyan",
        "name": "Karen",
        "surname": "Sahakyan",
        "dateOfBirth": "28 Oct., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2223",
        "title": "Tigran Mkhitaryan",
        "name": "Tigran",
        "surname": "Mkhitaryan",
        "dateOfBirth": "05 Jan., 2002",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2222",
        "title": "Mekhak Tonoyan",
        "name": "Mekhak",
        "surname": "Tonoyan",
        "dateOfBirth": "30 Oct., 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2221",
        "title": "Lyudvig Avdalyan",
        "name": "Lyudvig",
        "surname": "Avdalyan",
        "dateOfBirth": "03 Apr., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2240",
        "title": "Samvel Pochikyan",
        "name": "Samvel",
        "surname": "Pochikyan",
        "dateOfBirth": "02 Aug., 1986",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2239",
        "title": "Ruslan Torosyan",
        "name": "Ruslan",
        "surname": "Torosyan",
        "dateOfBirth": "26 Feb., 1997",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2238",
        "title": "Tigran Khachatryan",
        "name": "Tigran",
        "surname": "Khachatryan",
        "dateOfBirth": "08 June, 1998",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2237",
        "title": "Vardan Khachatryan",
        "name": "Vardan",
        "surname": "Khachatryan",
        "dateOfBirth": "05 Sept. , 1990",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2236",
        "title": "Harutyun Shahnazaryan",
        "name": "Harutyun",
        "surname": "Shahnazaryan",
        "dateOfBirth": "04 July, 1977",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2235",
        "title": "Tigran Asatryan",
        "name": "Tigran",
        "surname": "Asatryan",
        "dateOfBirth": "15 Oct., 1970",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2234",
        "title": "Gevorg Kirakosyan",
        "name": "Gevorg",
        "surname": "Kirakosyan",
        "dateOfBirth": "20 Jan., 1989",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2233",
        "title": "Mkrtich Sargsyan",
        "name": "Mkrtich",
        "surname": "Sargsyan",
        "dateOfBirth": "13 Jan., 1975",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2232",
        "title": "Aram Simonyan",
        "name": "Aram",
        "surname": "Simonyan",
        "dateOfBirth": "25 Feb., 2001",
        "dateOfDeath": "27Feb.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2231",
        "title": "Avag Avetisyan",
        "name": "Avag",
        "surname": "Avetisyan",
        "dateOfBirth": "13 Oct., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1810",
        "title": "Davit Grgoyan",
        "name": "Davit",
        "surname": "Grgoyan",
        "dateOfBirth": "04 May, 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1809",
        "title": "Artak Hadjoyan",
        "name": "Artak",
        "surname": "Hadjoyan",
        "dateOfBirth": "17 July, 1983",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1808",
        "title": "Razmik Grigoryan",
        "name": "Razmik",
        "surname": "Grigoryan",
        "dateOfBirth": "22 Sept. , 1998",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1807",
        "title": "Armen Hayrapetyan",
        "name": "Armen",
        "surname": "Hayrapetyan",
        "dateOfBirth": "30 Aug., 1987",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1806",
        "title": "Ruben Arshakyan",
        "name": "Ruben",
        "surname": "Arshakyan",
        "dateOfBirth": "07 Mar., 1983",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1805",
        "title": "Samvel Petrosyan",
        "name": "Samvel",
        "surname": "Petrosyan",
        "dateOfBirth": "28 July, 2020",
        "dateOfDeath": "20Oct.,2000",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1804",
        "title": "Gevorg Gevorgyan",
        "name": "Gevorg",
        "surname": "Gevorgyan",
        "dateOfBirth": "29 Jan., 1980",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1803",
        "title": "Sipan Petrosyan",
        "name": "Sipan",
        "surname": "Petrosyan",
        "dateOfBirth": "22 Dec., 2001",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1802",
        "title": "Vrej Pogosyan",
        "name": "Vrej",
        "surname": "Pogosyan",
        "dateOfBirth": "01 July, 1992",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1801",
        "title": "Gor Sarkisyan",
        "name": "Gor",
        "surname": "Sarkisyan",
        "dateOfBirth": "23 Mar., 2002",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1170",
        "title": "Artur Zalinyan",
        "name": "Artur",
        "surname": "Zalinyan",
        "dateOfBirth": "03 Mar., 1993",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1169",
        "title": "Andranik Grigoryan",
        "name": "Andranik",
        "surname": "Grigoryan",
        "dateOfBirth": "29 Nov., 1989",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1168",
        "title": "Andranik Grigoryan",
        "name": "Andranik",
        "surname": "Grigoryan",
        "dateOfBirth": "16 Dec., 1996",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1167",
        "title": "Aren Gogoryan",
        "name": "Aren",
        "surname": "Gogoryan",
        "dateOfBirth": "16 June, 1991",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1166",
        "title": "Vachagan Ginosyan",
        "name": "Vachagan",
        "surname": "Ginosyan",
        "dateOfBirth": "01 May, 1985",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1165",
        "title": "Hayk Alexanyan",
        "name": "Hayk",
        "surname": "Alexanyan",
        "dateOfBirth": "07 Jan., 1991",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1164",
        "title": "Artyom Petrosyan",
        "name": "Artyom",
        "surname": "Petrosyan",
        "dateOfBirth": "20 Dec., 1996",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1163",
        "title": "Arsen Petrosyan",
        "name": "Arsen",
        "surname": "Petrosyan",
        "dateOfBirth": "13 Apr., 1986",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1162",
        "title": "Garik Nikoghosyan",
        "name": "Garik",
        "surname": "Nikoghosyan",
        "dateOfBirth": "17 Sept. , 1993",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1161",
        "title": "Alen Navasardyan",
        "name": "Alen",
        "surname": "Navasardyan",
        "dateOfBirth": "27 June, 1989",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2110",
        "title": "Liparit Tadevosyan",
        "name": "Liparit",
        "surname": "Tadevosyan",
        "dateOfBirth": "29 Aug., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2109",
        "title": "Narek Khachatryan",
        "name": "Narek",
        "surname": "Khachatryan",
        "dateOfBirth": "09 Apr., 2002",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2108",
        "title": "Arsen Nahapetyan",
        "name": "Arsen",
        "surname": "Nahapetyan",
        "dateOfBirth": "06 Sept. , 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2107",
        "title": "Israyel Akinyan",
        "name": "Israyel",
        "surname": "Akinyan",
        "dateOfBirth": "04 Aug., 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2106",
        "title": "Simon Artenyan",
        "name": "Simon",
        "surname": "Artenyan",
        "dateOfBirth": "25 Jan., 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2105",
        "title": "Hayk Atabekyan",
        "name": "Hayk",
        "surname": "Atabekyan",
        "dateOfBirth": "23 Mar., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2104",
        "title": "Gegham Avdalyan",
        "name": "Gegham",
        "surname": "Avdalyan",
        "dateOfBirth": "23 Aug., 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2103",
        "title": "Levon Najarian",
        "name": "Levon",
        "surname": "Najarian",
        "dateOfBirth": "13 Nov., 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2102",
        "title": "Tigran Avetisyan",
        "name": "Tigran",
        "surname": "Avetisyan",
        "dateOfBirth": "26 Apr., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2101",
        "title": "Avag Nazarian",
        "name": "Avag",
        "surname": "Nazarian",
        "dateOfBirth": "24 Nov., 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37480008787",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2420",
        "title": "Shahen Hayrapetyan",
        "name": "Shahen",
        "surname": "Hayrapetyan",
        "dateOfBirth": "16 Apr., 1976",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2419",
        "title": "Gegham Poghosyan",
        "name": "Gegham",
        "surname": "Poghosyan",
        "dateOfBirth": "04 Oct., 1969",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2418",
        "title": "Rafael Hakobyan",
        "name": "Rafael",
        "surname": "Hakobyan",
        "dateOfBirth": "07 May, 1995",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2417",
        "title": "Gagik Allahverdyan",
        "name": "Gagik",
        "surname": "Allahverdyan",
        "dateOfBirth": "28 May, 1976",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2416",
        "title": "David Asryan",
        "name": "David",
        "surname": "Asryan",
        "dateOfBirth": "18 Nov., 1986",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2415",
        "title": "Hovhannes Karapetyan",
        "name": "Hovhannes",
        "surname": "Karapetyan",
        "dateOfBirth": "05 May, 1996",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2414",
        "title": "Koryun Soghomonyan",
        "name": "Koryun",
        "surname": "Soghomonyan",
        "dateOfBirth": "30 June, 1984",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2413",
        "title": "Arsen Avakian",
        "name": "Arsen",
        "surname": "Avakian",
        "dateOfBirth": "11 Dec., 1978",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2412",
        "title": "Roman Aghabekyan",
        "name": "Roman",
        "surname": "Aghabekyan",
        "dateOfBirth": "12 May, 1998",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2411",
        "title": "Armen Babayan",
        "name": "Armen",
        "surname": "Babayan",
        "dateOfBirth": "23 May, 1974",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "510",
        "title": "Gevorg Ghukasyan",
        "name": "Gevorg",
        "surname": "Ghukasyan",
        "dateOfBirth": "12 May, 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "509",
        "title": "Vardan Poghosyan",
        "name": "Vardan",
        "surname": "Poghosyan",
        "dateOfBirth": "24 Jan., 1987",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "508",
        "title": "Vahe Eghikyan",
        "name": "Vahe",
        "surname": "Eghikyan",
        "dateOfBirth": "09 May, 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "507",
        "title": "Paylak Sahakyan",
        "name": "Paylak",
        "surname": "Sahakyan",
        "dateOfBirth": "24 Oct., 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "506",
        "title": "Arthur Ghukasyan",
        "name": "Arthur",
        "surname": "Ghukasyan",
        "dateOfBirth": "17 Jan., 1991",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "505",
        "title": "Kamo Minasyan",
        "name": "Kamo",
        "surname": "Minasyan",
        "dateOfBirth": "27 Aug., 1984",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "504",
        "title": "Ishkhan Galstyan",
        "name": "Ishkhan",
        "surname": "Galstyan",
        "dateOfBirth": "17 Jan., 2002",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "503",
        "title": "Hrant Azaryan",
        "name": "Hrant",
        "surname": "Azaryan",
        "dateOfBirth": "30 May, 1997",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "502",
        "title": "Alik Petrosyan",
        "name": "Alik",
        "surname": "Petrosyan",
        "dateOfBirth": "30 Aug., 1982",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "501",
        "title": "Artyom Eghoyan",
        "name": "Artyom",
        "surname": "Eghoyan",
        "dateOfBirth": "23 Oct., 1997",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1670",
        "title": "Ալեքսանդր Կարապետյան Աշոտի",
        "name": "Ալեքսանդր",
        "surname": "Կարապետյան Աշոտի",
        "dateOfBirth": "26 May, 2002",
        "dateOfDeath": "11Oct.,2000",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1669",
        "title": "Արման Կարապետյան Արտյոմի",
        "name": "Արման",
        "surname": "Կարապետյան Արտյոմի",
        "dateOfBirth": "02 Apr., 2002",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1668",
        "title": "Գագիկ Կարապետյան Կարենի",
        "name": "Գագիկ",
        "surname": "Կարապետյան Կարենի",
        "dateOfBirth": "26 Feb., 2001",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1667",
        "title": "Սամվել Գալստյան Մերուժանի",
        "name": "Սամվել",
        "surname": "Գալստյան Մերուժանի",
        "dateOfBirth": "24 June, 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1666",
        "title": "Գարիկ Կիզիրանց Քաջիկի",
        "name": "Գարիկ",
        "surname": "Կիզիրանց Քաջիկի",
        "dateOfBirth": "06 June, 2000",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1665",
        "title": "Գրիգոր Գարագավուրյան Անդրանիկի",
        "name": "Գրիգոր",
        "surname": "Գարագավուրյան Անդրանիկի",
        "dateOfBirth": "12 July, 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1664",
        "title": "Արտյոմ Լոգյան Էդվարդի",
        "name": "Արտյոմ",
        "surname": "Լոգյան Էդվարդի",
        "dateOfBirth": "15 Aug., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1663",
        "title": "Կամո Լոկյան Հայկի",
        "name": "Կամո",
        "surname": "Լոկյան Հայկի",
        "dateOfBirth": "08 Nov., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1662",
        "title": "Արարատ Մակարյան Ասատուրի",
        "name": "Արարատ",
        "surname": "Մակարյան Ասատուրի",
        "dateOfBirth": "25 May, 2002",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1661",
        "title": "Արման Մաշուրյան Արմենի",
        "name": "Արման",
        "surname": "Մաշուրյան Արմենի",
        "dateOfBirth": "26 Oct., 2000",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1780",
        "title": "Narek Hovhannisyan",
        "name": "Narek",
        "surname": "Hovhannisyan",
        "dateOfBirth": "28 Mar., 2001",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1779",
        "title": "Artyom Karapetyan",
        "name": "Artyom",
        "surname": "Karapetyan",
        "dateOfBirth": "06 Dec., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1778",
        "title": "Vanik Margaryan",
        "name": "Vanik",
        "surname": "Margaryan",
        "dateOfBirth": "23 Aug., 2000",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1777",
        "title": "Khachik Petrosyan",
        "name": "Khachik",
        "surname": "Petrosyan",
        "dateOfBirth": "22 July, 1987",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1776",
        "title": "Dmitri Martirosyan",
        "name": "Dmitri",
        "surname": "Martirosyan",
        "dateOfBirth": "11 Oct., 2000",
        "dateOfDeath": "19Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1775",
        "title": "Vazgen Melikyan",
        "name": "Vazgen",
        "surname": "Melikyan",
        "dateOfBirth": "15 May, 2002",
        "dateOfDeath": "30Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1774",
        "title": "Samvel Melkonyan",
        "name": "Samvel",
        "surname": "Melkonyan",
        "dateOfBirth": "14 Nov., 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1773",
        "title": "Tigran Melkonyan",
        "name": "Tigran",
        "surname": "Melkonyan",
        "dateOfBirth": "17 Oct., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1772",
        "title": "Hovhannes Mkrtchyan",
        "name": "Hovhannes",
        "surname": "Mkrtchyan",
        "dateOfBirth": "14 Aug., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1771",
        "title": "David Mnatsakanyan",
        "name": "David",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "14 Mar., 1999",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2210",
        "title": "Samson Ghazaryan",
        "name": "Samson",
        "surname": "Ghazaryan",
        "dateOfBirth": "01 May, 1994",
        "dateOfDeath": "17Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2209",
        "title": "Mesrop Nazaryan",
        "name": "Mesrop",
        "surname": "Nazaryan",
        "dateOfBirth": "07 May, 1999",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2208",
        "title": "Norayr Sargsyan",
        "name": "Norayr",
        "surname": "Sargsyan",
        "dateOfBirth": "10 Apr., 1998",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2207",
        "title": "Sergey Shaqrikyan",
        "name": "Sergey",
        "surname": "Shaqrikyan",
        "dateOfBirth": "25 Apr., 1994",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2206",
        "title": "Hakob Aghabekyan",
        "name": "Hakob",
        "surname": "Aghabekyan",
        "dateOfBirth": "30 Oct., 1981",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2205",
        "title": "Karen Manukyan",
        "name": "Karen",
        "surname": "Manukyan",
        "dateOfBirth": "14 Apr., 1977",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2204",
        "title": "Karen Badoyan",
        "name": "Karen",
        "surname": "Badoyan",
        "dateOfBirth": "27 May, 1990",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2203",
        "title": "Artush Zohrabyan",
        "name": "Artush",
        "surname": "Zohrabyan",
        "dateOfBirth": "30 Oct., 1967",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2202",
        "title": "Mkrtich Muradyan",
        "name": "Mkrtich",
        "surname": "Muradyan",
        "dateOfBirth": "22 July, 1972",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2201",
        "title": "Sargis Poghosyan",
        "name": "Sargis",
        "surname": "Poghosyan",
        "dateOfBirth": "14 June, 1982",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "590",
        "title": "Ghevond Ghevondyan",
        "name": "Ghevond",
        "surname": "Ghevondyan",
        "dateOfBirth": "08 Sept. , 1992",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "589",
        "title": "Arman Topchyan",
        "name": "Arman",
        "surname": "Topchyan",
        "dateOfBirth": "29 Mar., 1993",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "588",
        "title": "Armen Grigoryan",
        "name": "Armen",
        "surname": "Grigoryan",
        "dateOfBirth": "18 Jan., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "587",
        "title": "Moushegh Galstyan",
        "name": "Moushegh",
        "surname": "Galstyan",
        "dateOfBirth": "18 Feb., 1997",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "586",
        "title": "Artyom Khachatryan",
        "name": "Artyom",
        "surname": "Khachatryan",
        "dateOfBirth": "21 Nov., 2001",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "585",
        "title": "Arshaluys Khugoyan",
        "name": "Arshaluys",
        "surname": "Khugoyan",
        "dateOfBirth": "05 Apr., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "584",
        "title": "Erik Nahapetyan",
        "name": "Erik",
        "surname": "Nahapetyan",
        "dateOfBirth": "17 Apr., 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "583",
        "title": "Gagik Mikichyan",
        "name": "Gagik",
        "surname": "Mikichyan",
        "dateOfBirth": "31 Aug., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "582",
        "title": "Gor Karapetyan",
        "name": "Gor",
        "surname": "Karapetyan",
        "dateOfBirth": "17 Oct., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "581",
        "title": "Seyran Gharibyan",
        "name": "Seyran",
        "surname": "Gharibyan",
        "dateOfBirth": "28 Jan., 1998",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "140",
        "title": "Hayk Minasyan",
        "name": "Hayk",
        "surname": "Minasyan",
        "dateOfBirth": "23 Oct., 1996",
        "dateOfDeath": "26Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "139",
        "title": "Spartak Qalashyan",
        "name": "Spartak",
        "surname": "Qalashyan",
        "dateOfBirth": "11 Apr., 1997",
        "dateOfDeath": "21Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "138",
        "title": "Aram Abrahamyan",
        "name": "Aram",
        "surname": "Abrahamyan",
        "dateOfBirth": "25 Nov., 1996",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "137",
        "title": "Aramayis Miqayelyan",
        "name": "Aramayis",
        "surname": "Miqayelyan",
        "dateOfBirth": "21 July, 1996",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "136",
        "title": "Vahagn Virabyan",
        "name": "Vahagn",
        "surname": "Virabyan",
        "dateOfBirth": "04 Jan., 1997",
        "dateOfDeath": "15Oct.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "135",
        "title": "Garik Movsesyan",
        "name": "Garik",
        "surname": "Movsesyan",
        "dateOfBirth": "15 Jan., 1997",
        "dateOfDeath": "30Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "134",
        "title": "Henrik Gharamanyan",
        "name": "Henrik",
        "surname": "Gharamanyan",
        "dateOfBirth": "28 Nov., 1996",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "133",
        "title": "Sasha Galstyan",
        "name": "Sasha",
        "surname": "Galstyan",
        "dateOfBirth": "12 Aug., 1996",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "132",
        "title": "Bagrat Aleksanyan",
        "name": "Bagrat",
        "surname": "Aleksanyan",
        "dateOfBirth": "11 June, 1995",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "131",
        "title": "Manvel Gevorgyan",
        "name": "Manvel",
        "surname": "Gevorgyan",
        "dateOfBirth": "14 May, 1997",
        "dateOfDeath": "14Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "820",
        "title": "Vladimir Margaryan",
        "name": "Vladimir",
        "surname": "Margaryan",
        "dateOfBirth": "10 June, 2002",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "819",
        "title": "Mikael Manukyan",
        "name": "Mikael",
        "surname": "Manukyan",
        "dateOfBirth": "20 Nov., 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "818",
        "title": "Gagik Malkhasyan",
        "name": "Gagik",
        "surname": "Malkhasyan",
        "dateOfBirth": "20 Aug., 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "817",
        "title": "Arsen Avetisyan",
        "name": "Arsen",
        "surname": "Avetisyan",
        "dateOfBirth": "24 June, 1985",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "816",
        "title": "Vardan Amalyan",
        "name": "Vardan",
        "surname": "Amalyan",
        "dateOfBirth": "18 Jan., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "815",
        "title": "Sevak Aleksanyan",
        "name": "Sevak",
        "surname": "Aleksanyan",
        "dateOfBirth": "06 Nov., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "814",
        "title": "Hayk Arshakyan",
        "name": "Hayk",
        "surname": "Arshakyan",
        "dateOfBirth": "04 July, 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "813",
        "title": "Zhora Andriasyan",
        "name": "Zhora",
        "surname": "Andriasyan",
        "dateOfBirth": "11 June, 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "812",
        "title": "Gerasim Aharonyan",
        "name": "Gerasim",
        "surname": "Aharonyan",
        "dateOfBirth": "03 May, 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "811",
        "title": "Edgar Aghajanyan",
        "name": "Edgar",
        "surname": "Aghajanyan",
        "dateOfBirth": "02 July, 2000",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "960",
        "title": "Hovhannes Sargsyan",
        "name": "Hovhannes",
        "surname": "Sargsyan",
        "dateOfBirth": "13 Apr., 1990",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "959",
        "title": "Gegham Sargsyan",
        "name": "Gegham",
        "surname": "Sargsyan",
        "dateOfBirth": "27 Oct., 1992",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "958",
        "title": "Artak Sargsyan",
        "name": "Artak",
        "surname": "Sargsyan",
        "dateOfBirth": "19 Jan., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "957",
        "title": "Varazdat Nazaryan",
        "name": "Varazdat",
        "surname": "Nazaryan",
        "dateOfBirth": "14 Jan., 1998",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "956",
        "title": "Narek Safaryan",
        "name": "Narek",
        "surname": "Safaryan",
        "dateOfBirth": "16 July, 1990",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "955",
        "title": "Shant Navoyan",
        "name": "Shant",
        "surname": "Navoyan",
        "dateOfBirth": "14 Dec., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "954",
        "title": "Robert Mnatsakanyan",
        "name": "Robert",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "29 Aug., 1996",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "953",
        "title": "Sevak Mnatsakanyan",
        "name": "Sevak",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "06 Feb., 1988",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "952",
        "title": "Avetik Minasyan",
        "name": "Avetik",
        "surname": "Minasyan",
        "dateOfBirth": "15 Sept. , 1974",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "951",
        "title": "Raj Avanesyan",
        "name": "Raj",
        "surname": "Avanesyan",
        "dateOfBirth": "20 Dec., 1980",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "690",
        "title": "Khachik Baghdasaryan",
        "name": "Khachik",
        "surname": "Baghdasaryan",
        "dateOfBirth": "09 Dec., 1987",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "689",
        "title": "Karapet Hakobyan",
        "name": "Karapet",
        "surname": "Hakobyan",
        "dateOfBirth": "25 Dec., 1997",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "688",
        "title": "Artur khachatryan",
        "name": "Artur",
        "surname": "khachatryan",
        "dateOfBirth": "19 Dec., 1989",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "687",
        "title": "Arsen Malintsyan",
        "name": "Arsen",
        "surname": "Malintsyan",
        "dateOfBirth": "10 May, 1973",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "686",
        "title": "Armen Grigoryan",
        "name": "Armen",
        "surname": "Grigoryan",
        "dateOfBirth": "08 Dec., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "685",
        "title": "Artak Gasparyan",
        "name": "Artak",
        "surname": "Gasparyan",
        "dateOfBirth": "07 Sept. , 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "684",
        "title": "Karen Hayrapetyan",
        "name": "Karen",
        "surname": "Hayrapetyan",
        "dateOfBirth": "24 July, 1979",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "683",
        "title": "Hayk Eghiazaryan",
        "name": "Hayk",
        "surname": "Eghiazaryan",
        "dateOfBirth": "13 July, 2000",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "682",
        "title": "Varoujan Saghumyan",
        "name": "Varoujan",
        "surname": "Saghumyan",
        "dateOfBirth": "07 May, 2002",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "681",
        "title": "Karen Eranosyan",
        "name": "Karen",
        "surname": "Eranosyan",
        "dateOfBirth": "25 June, 1991",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1030",
        "title": "Mesrop Miskaryan",
        "name": "Mesrop",
        "surname": "Miskaryan",
        "dateOfBirth": "21 Sept. , 1989",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1029",
        "title": "Garik Hovakimyan",
        "name": "Garik",
        "surname": "Hovakimyan",
        "dateOfBirth": "30 Dec., 2000",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1028",
        "title": "Ara Hovakimyan",
        "name": "Ara",
        "surname": "Hovakimyan",
        "dateOfBirth": "01 Mar., 1988",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1027",
        "title": "Edgar Hayrapetyan",
        "name": "Edgar",
        "surname": "Hayrapetyan",
        "dateOfBirth": "06 Sept. , 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1026",
        "title": "Hamlet Nkruryan",
        "name": "Hamlet",
        "surname": "Nkruryan",
        "dateOfBirth": "14 Sept. , 2001",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1025",
        "title": "Ruben Hambardzumyan",
        "name": "Ruben",
        "surname": "Hambardzumyan",
        "dateOfBirth": "25 June, 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1024",
        "title": "Sasun Nersisyan",
        "name": "Sasun",
        "surname": "Nersisyan",
        "dateOfBirth": "21 Mar., 2020",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1023",
        "title": "Samvel Muradyan",
        "name": "Samvel",
        "surname": "Muradyan",
        "dateOfBirth": "25 Dec., 1979",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1022",
        "title": "Misha Hakobyan",
        "name": "Misha",
        "surname": "Hakobyan",
        "dateOfBirth": "13 Dec., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1021",
        "title": "Khoren Hajatyan",
        "name": "Khoren",
        "surname": "Hajatyan",
        "dateOfBirth": "12 Mar., 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1370",
        "title": "Gurgen Esayan",
        "name": "Gurgen",
        "surname": "Esayan",
        "dateOfBirth": "09 Oct., 1993",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1369",
        "title": "Davit Mnatsakanyan",
        "name": "Davit",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "04 Jan., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1368",
        "title": "Vahe Mkitaryan",
        "name": "Vahe",
        "surname": "Mkitaryan",
        "dateOfBirth": "11 Mar., 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1367",
        "title": "Misha Tchetchentsov",
        "name": "Misha",
        "surname": "Tchetchentsov",
        "dateOfBirth": "04 Jan., 1959",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1366",
        "title": "Vahagn Nahapetyan",
        "name": "Vahagn",
        "surname": "Nahapetyan",
        "dateOfBirth": "28 June, 1996",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1365",
        "title": "Tigran Baghdasaryan",
        "name": "Tigran",
        "surname": "Baghdasaryan",
        "dateOfBirth": "29 Jan., 1990",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1364",
        "title": "Edgar Barseghyan",
        "name": "Edgar",
        "surname": "Barseghyan",
        "dateOfBirth": "30 Nov., 1993",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1363",
        "title": "Artur Eranosyan",
        "name": "Artur",
        "surname": "Eranosyan",
        "dateOfBirth": "11 July, 1995",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1362",
        "title": "Karen Harutyunyan",
        "name": "Karen",
        "surname": "Harutyunyan",
        "dateOfBirth": "08 Oct., 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1361",
        "title": "Davit Hakobyan",
        "name": "Davit",
        "surname": "Hakobyan",
        "dateOfBirth": "09 June, 2002",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1000",
        "title": "Garik Muradyan",
        "name": "Garik",
        "surname": "Muradyan",
        "dateOfBirth": "16 Jan., 1964",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "999",
        "title": "Mher Elizbaryan",
        "name": "Mher",
        "surname": "Elizbaryan",
        "dateOfBirth": "03 Dec., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "998",
        "title": "Aram Danielyan",
        "name": "Aram",
        "surname": "Danielyan",
        "dateOfBirth": "09 May, 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "997",
        "title": "Aharon Asatryan",
        "name": "Aharon",
        "surname": "Asatryan",
        "dateOfBirth": "06 Sept. , 1996",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "996",
        "title": "Yura Chobanyan",
        "name": "Yura",
        "surname": "Chobanyan",
        "dateOfBirth": "20 Apr., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "995",
        "title": "Hovhannes Chobanyan",
        "name": "Hovhannes",
        "surname": "Chobanyan",
        "dateOfBirth": "12 Apr., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "994",
        "title": "Hovhannes Chobanyan",
        "name": "Hovhannes",
        "surname": "Chobanyan",
        "dateOfBirth": "17 July, 1997",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "993",
        "title": "Liparit Chilingaryan",
        "name": "Liparit",
        "surname": "Chilingaryan",
        "dateOfBirth": "19 Apr., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "992",
        "title": "Tovmas Tovmasyan",
        "name": "Tovmas",
        "surname": "Tovmasyan",
        "dateOfBirth": "10 Dec., 1983",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "991",
        "title": "Vanya Barseghyan",
        "name": "Vanya",
        "surname": "Barseghyan",
        "dateOfBirth": "15 May, 2002",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "480",
        "title": "Ishkhan Petrosyan",
        "name": "Ishkhan",
        "surname": "Petrosyan",
        "dateOfBirth": "02 June, 1977",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "479",
        "title": "Azad Ayvazyan",
        "name": "Azad",
        "surname": "Ayvazyan",
        "dateOfBirth": "29 Aug., 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "478",
        "title": "Yuri Aghasaryan",
        "name": "Yuri",
        "surname": "Aghasaryan",
        "dateOfBirth": "09 Dec., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "477",
        "title": "Gevorg Asatryan",
        "name": "Gevorg",
        "surname": "Asatryan",
        "dateOfBirth": "26 Jan., 2002",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "476",
        "title": "Lernik Mkhitaryan",
        "name": "Lernik",
        "surname": "Mkhitaryan",
        "dateOfBirth": "27 Mar., 1972",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "475",
        "title": "Arman Poghosyan",
        "name": "Arman",
        "surname": "Poghosyan",
        "dateOfBirth": "06 Jan., 2002",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "474",
        "title": "Narek Sargsyan",
        "name": "Narek",
        "surname": "Sargsyan",
        "dateOfBirth": "30 Jan., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "473",
        "title": "Nerses Nersisyan",
        "name": "Nerses",
        "surname": "Nersisyan",
        "dateOfBirth": "26 Oct., 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "472",
        "title": "Armen Martirosyan",
        "name": "Armen",
        "surname": "Martirosyan",
        "dateOfBirth": "16 Nov., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "471",
        "title": "Aram Kirakosyan",
        "name": "Aram",
        "surname": "Kirakosyan",
        "dateOfBirth": "05 Jan., 1979",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1940",
        "title": "Garik Margaryan",
        "name": "Garik",
        "surname": "Margaryan",
        "dateOfBirth": "11 June, 1993",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1939",
        "title": "Hovhannes Mazmanyan",
        "name": "Hovhannes",
        "surname": "Mazmanyan",
        "dateOfBirth": "08 Feb., 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1938",
        "title": "Karen Petikyan",
        "name": "Karen",
        "surname": "Petikyan",
        "dateOfBirth": "03 Oct., 1998",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1937",
        "title": "Artur Poghosyan",
        "name": "Artur",
        "surname": "Poghosyan",
        "dateOfBirth": "05 June, 1997",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1936",
        "title": "Eduard Kocharyan",
        "name": "Eduard",
        "surname": "Kocharyan",
        "dateOfBirth": "28 July, 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1935",
        "title": "Mekhak Sadoyan",
        "name": "Mekhak",
        "surname": "Sadoyan",
        "dateOfBirth": "18 Jan., 2000",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1934",
        "title": "Artur Simikyan",
        "name": "Artur",
        "surname": "Simikyan",
        "dateOfBirth": "18 Jan., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1933",
        "title": "Mushegh Vardanyan",
        "name": "Mushegh",
        "surname": "Vardanyan",
        "dateOfBirth": "01 Nov., 2001",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1932",
        "title": "Aren Osipov",
        "name": "Aren",
        "surname": "Osipov",
        "dateOfBirth": "21 Apr., 1984",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1931",
        "title": "Gagik Khachatryan",
        "name": "Gagik",
        "surname": "Khachatryan",
        "dateOfBirth": "13 July, 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "910",
        "title": "Vardan Hovhannisyan",
        "name": "Vardan",
        "surname": "Hovhannisyan",
        "dateOfBirth": "11 Jan., 1991",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "909",
        "title": "Henrik Hovhannisyan",
        "name": "Henrik",
        "surname": "Hovhannisyan",
        "dateOfBirth": "06 Mar., 1998",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "908",
        "title": "Volfik Gabrielyan",
        "name": "Volfik",
        "surname": "Gabrielyan",
        "dateOfBirth": "25 Aug., 1994",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "907",
        "title": "Karen Yesayan",
        "name": "Karen",
        "surname": "Yesayan",
        "dateOfBirth": "07 Nov., 2000",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "906",
        "title": "Sargis Danoyan",
        "name": "Sargis",
        "surname": "Danoyan",
        "dateOfBirth": "30 June, 2001",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "905",
        "title": "Vahan Sahakyan",
        "name": "Vahan",
        "surname": "Sahakyan",
        "dateOfBirth": "03 July, 2001",
        "dateOfDeath": "22Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "904",
        "title": "Garik Hovhannisyan",
        "name": "Garik",
        "surname": "Hovhannisyan",
        "dateOfBirth": "09 Dec., 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "903",
        "title": "Samvel Sahakyan",
        "name": "Samvel",
        "surname": "Sahakyan",
        "dateOfBirth": "26 June, 1986",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "902",
        "title": "Hmayak Harapetyan",
        "name": "Hmayak",
        "surname": "Harapetyan",
        "dateOfBirth": "04 Feb., 1993",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "901",
        "title": "Karen Harutyunyan",
        "name": "Karen",
        "surname": "Harutyunyan",
        "dateOfBirth": "15 Sept. , 2000",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "120",
        "title": "Norik Sargsyan",
        "name": "Norik",
        "surname": "Sargsyan",
        "dateOfBirth": "26 Sept. , 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "108 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "119",
        "title": "Vladimir Melqonyan",
        "name": "Vladimir",
        "surname": "Melqonyan",
        "dateOfBirth": "23 June, 1996",
        "dateOfDeath": "01Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "118",
        "title": "Simon Chavrshyan",
        "name": "Simon",
        "surname": "Chavrshyan",
        "dateOfBirth": "19 June, 1996",
        "dateOfDeath": "05Feb.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "117",
        "title": "Tigran Abgaryan",
        "name": "Tigran",
        "surname": "Abgaryan",
        "dateOfBirth": "14 Oct., 1996",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "116",
        "title": "Khachatur Harutyunyan",
        "name": "Khachatur",
        "surname": "Harutyunyan",
        "dateOfBirth": "25 Nov., 1996",
        "dateOfDeath": "17May,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "115",
        "title": "Arman Andrasyan",
        "name": "Arman",
        "surname": "Andrasyan",
        "dateOfBirth": "11 July, 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "114",
        "title": "Gevorg Vardanyan",
        "name": "Gevorg",
        "surname": "Vardanyan",
        "dateOfBirth": "19 Aug., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "113",
        "title": "Artyom Varderesyan",
        "name": "Artyom",
        "surname": "Varderesyan",
        "dateOfBirth": "30 Mar., 1996",
        "dateOfDeath": "17Mar.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "112",
        "title": "Gevorg Gevorgyan",
        "name": "Gevorg",
        "surname": "Gevorgyan",
        "dateOfBirth": "28 Sept. , 1996",
        "dateOfDeath": "19Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "111",
        "title": "Vladimir Alikhanyan",
        "name": "Vladimir",
        "surname": "Alikhanyan",
        "dateOfBirth": "29 Jan., 1996",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1220",
        "title": "Artyom Sargsyan",
        "name": "Artyom",
        "surname": "Sargsyan",
        "dateOfBirth": "29 July, 1981",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1219",
        "title": "Armen Sargsyan",
        "name": "Armen",
        "surname": "Sargsyan",
        "dateOfBirth": "20 Jan., 1976",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1218",
        "title": "Suren Hovhannisyan",
        "name": "Suren",
        "surname": "Hovhannisyan",
        "dateOfBirth": "01 June, 1974",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1217",
        "title": "Gevorg Kartashyan",
        "name": "Gevorg",
        "surname": "Kartashyan",
        "dateOfBirth": "13 May, 1984",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1216",
        "title": "Hayk Kirakosyan",
        "name": "Hayk",
        "surname": "Kirakosyan",
        "dateOfBirth": "30 Dec., 1986",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1215",
        "title": "Arman Tchagharyan",
        "name": "Arman",
        "surname": "Tchagharyan",
        "dateOfBirth": "15 Nov., 1999",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1214",
        "title": "Vahe Karamyan",
        "name": "Vahe",
        "surname": "Karamyan",
        "dateOfBirth": "30 Mar., 1988",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1213",
        "title": "Lyova Voskanyan",
        "name": "Lyova",
        "surname": "Voskanyan",
        "dateOfBirth": "25 Feb., 1987",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1212",
        "title": "Norayr Khachatryan",
        "name": "Norayr",
        "surname": "Khachatryan",
        "dateOfBirth": "22 Dec., 1979",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1211",
        "title": "Armen Khazaryan",
        "name": "Armen",
        "surname": "Khazaryan",
        "dateOfBirth": "21 June, 1969",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "440",
        "title": "Edgar Petrosyan",
        "name": "Edgar",
        "surname": "Petrosyan",
        "dateOfBirth": "25 Jan., 2002",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "439",
        "title": "Sargis Sargsyan",
        "name": "Sargis",
        "surname": "Sargsyan",
        "dateOfBirth": "14 Feb., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "438",
        "title": "Hovik Vardanyan",
        "name": "Hovik",
        "surname": "Vardanyan",
        "dateOfBirth": "01 Mar., 1996",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "437",
        "title": "Gagik Poghosyan",
        "name": "Gagik",
        "surname": "Poghosyan",
        "dateOfBirth": "16 Apr., 1995",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "436",
        "title": "Samvel Sahakyan",
        "name": "Samvel",
        "surname": "Sahakyan",
        "dateOfBirth": "19 Oct., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "435",
        "title": "Mikael Eganyan",
        "name": "Mikael",
        "surname": "Eganyan",
        "dateOfBirth": "14 Aug., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "434",
        "title": "Evgenij Gorodnichij",
        "name": "Evgenij",
        "surname": "Gorodnichij",
        "dateOfBirth": "01 Mar., 1993",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "433",
        "title": "Garnik Khachatryan",
        "name": "Garnik",
        "surname": "Khachatryan",
        "dateOfBirth": "28 Feb., 1987",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "432",
        "title": "Edgar Sargsyan",
        "name": "Edgar",
        "surname": "Sargsyan",
        "dateOfBirth": "03 Dec., 1988",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "431",
        "title": "Eduard Davoyan",
        "name": "Eduard",
        "surname": "Davoyan",
        "dateOfBirth": "08 Aug., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Junior Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1690",
        "title": "Գևորգ Բաբաջանյան Դավիթի",
        "name": "Գևորգ",
        "surname": "Բաբաջանյան Դավիթի",
        "dateOfBirth": "31 July, 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1689",
        "title": "Ռաֆիկ Բաբայան Արմենի",
        "name": "Ռաֆիկ",
        "surname": "Բաբայան Արմենի",
        "dateOfBirth": "17 July, 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1688",
        "title": "Արեգ Ադուրյան Արտակի",
        "name": "Արեգ",
        "surname": "Ադուրյան Արտակի",
        "dateOfBirth": "14 Aug., 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1687",
        "title": "Անդրանիկ Ապերյան Արգամի",
        "name": "Անդրանիկ",
        "surname": "Ապերյան Արգամի",
        "dateOfBirth": "26 Feb., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1686",
        "title": "Կարլեն Առաքելյան Մառլենի",
        "name": "Կարլեն",
        "surname": "Առաքելյան Մառլենի",
        "dateOfBirth": "10 Mar., 1998",
        "dateOfDeath": "11Oct.,2000",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1685",
        "title": "Արմեն Բաբելյան Հակոբի",
        "name": "Արմեն",
        "surname": "Բաբելյան Հակոբի",
        "dateOfBirth": "03 June, 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1684",
        "title": "Սամսոն Առաքելյան Արտավազդի",
        "name": "Սամսոն",
        "surname": "Առաքելյան Արտավազդի",
        "dateOfBirth": "11 June, 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1683",
        "title": "Նարեկ Ասատուրյան Վլադիմիրի",
        "name": "Նարեկ",
        "surname": "Ասատուրյան Վլադիմիրի",
        "dateOfBirth": "04 June, 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1682",
        "title": "Գոռ Բոյաջյան Մհերի",
        "name": "Գոռ",
        "surname": "Բոյաջյան Մհերի",
        "dateOfBirth": "23 June, 2002",
        "dateOfDeath": "20Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1681",
        "title": "Սերյոժա Ասլանյան Աղվանի",
        "name": "Սերյոժա",
        "surname": "Ասլանյան Աղվանի",
        "dateOfBirth": "02 Feb., 2001",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1720",
        "title": "Լևոն Ավդալյան Ռոբերտի",
        "name": "Լևոն",
        "surname": "Ավդալյան Ռոբերտի",
        "dateOfBirth": "17 July, 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1719",
        "title": "Սարգիս Դավթյան Արմենակի",
        "name": "Սարգիս",
        "surname": "Դավթյան Արմենակի",
        "dateOfBirth": "24 May, 1999",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "կամավոր",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1718",
        "title": "Արթուր Երիցյան Ավետիսի",
        "name": "Արթուր",
        "surname": "Երիցյան Ավետիսի",
        "dateOfBirth": "19 Nov., 1976",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1717",
        "title": "Գեղամ Գաբրիելյան Էդիկի",
        "name": "Գեղամ",
        "surname": "Գաբրիելյան Էդիկի",
        "dateOfBirth": "23 Feb., 1977",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1716",
        "title": "Դավիթ Գրիգորյան Կոմիտասի",
        "name": "Դավիթ",
        "surname": "Գրիգորյան Կոմիտասի",
        "dateOfBirth": "15 Sept. , 1997",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1715",
        "title": "Հրանտ Գրիգորյան Միասնիկի",
        "name": "Հրանտ",
        "surname": "Գրիգորյան Միասնիկի",
        "dateOfBirth": "27 June, 1989",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1714",
        "title": "Վահագն Գրիգորյան Արտուշի",
        "name": "Վահագն",
        "surname": "Գրիգորյան Արտուշի",
        "dateOfBirth": "16 Nov., 1996",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1713",
        "title": "Վահան Հարությունյան Սամվելի",
        "name": "Վահան",
        "surname": "Հարությունյան Սամվելի",
        "dateOfBirth": "22 Nov., 1987",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1712",
        "title": "Վրեժ Կոստանդյան Արմենի",
        "name": "Վրեժ",
        "surname": "Կոստանդյան Արմենի",
        "dateOfBirth": "13 Sept. , 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1711",
        "title": "Վրեժ Բարսեղյան Արսեն",
        "name": "Վրեժ",
        "surname": "Բարսեղյան Արսեն",
        "dateOfBirth": "11 May, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1500",
        "title": "Mkhitar Khudjoyan",
        "name": "Mkhitar",
        "surname": "Khudjoyan",
        "dateOfBirth": "04 Dec., 1997",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1499",
        "title": "Aram Khanumyan",
        "name": "Aram",
        "surname": "Khanumyan",
        "dateOfBirth": "15 Oct., 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1498",
        "title": "Davit Kostanyan",
        "name": "Davit",
        "surname": "Kostanyan",
        "dateOfBirth": "26 Dec., 1986",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1497",
        "title": "Arsen Davtyan",
        "name": "Arsen",
        "surname": "Davtyan",
        "dateOfBirth": "24 Nov., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1496",
        "title": "Erik Khachatryan",
        "name": "Erik",
        "surname": "Khachatryan",
        "dateOfBirth": "10 Apr., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1495",
        "title": "Davit Badalyan",
        "name": "Davit",
        "surname": "Badalyan",
        "dateOfBirth": "21 Oct., 1987",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1494",
        "title": "Ivan Kirakosyan",
        "name": "Ivan",
        "surname": "Kirakosyan",
        "dateOfBirth": "19 Mar., 1991",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1493",
        "title": "Ajdahak Kirakosyan",
        "name": "Ajdahak",
        "surname": "Kirakosyan",
        "dateOfBirth": "03 Nov., 1997",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1492",
        "title": "Gor Karapetyna",
        "name": "Gor",
        "surname": "Karapetyna",
        "dateOfBirth": "24 May, 1994",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1491",
        "title": "Armenak Khachatryan",
        "name": "Armenak",
        "surname": "Khachatryan",
        "dateOfBirth": "20 Aug., 2020",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1280",
        "title": "Gevorg Tangabekyan",
        "name": "Gevorg",
        "surname": "Tangabekyan",
        "dateOfBirth": "20 Sept. , 1977",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1279",
        "title": "Karen Sofyan",
        "name": "Karen",
        "surname": "Sofyan",
        "dateOfBirth": "15 Jan., 1996",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1278",
        "title": "Gevorg Simonyan",
        "name": "Gevorg",
        "surname": "Simonyan",
        "dateOfBirth": "22 Apr., 1987",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1277",
        "title": "Hakob Shahinyan",
        "name": "Hakob",
        "surname": "Shahinyan",
        "dateOfBirth": "23 Sept. , 1987",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1276",
        "title": "Tigran Mkrtchyan",
        "name": "Tigran",
        "surname": "Mkrtchyan",
        "dateOfBirth": "10 Aug., 1995",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1275",
        "title": "Razmik Mkrtchyan",
        "name": "Razmik",
        "surname": "Mkrtchyan",
        "dateOfBirth": "07 Mar., 1999",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1274",
        "title": "Artur Misakyan",
        "name": "Artur",
        "surname": "Misakyan",
        "dateOfBirth": "22 Jan., 1973",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1273",
        "title": "Ruben Melkonyan",
        "name": "Ruben",
        "surname": "Melkonyan",
        "dateOfBirth": "20 Jan., 1971",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1272",
        "title": "Karen Martirosyan",
        "name": "Karen",
        "surname": "Martirosyan",
        "dateOfBirth": "03 Jan., 1997",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1271",
        "title": "Armen Baghdasaryan",
        "name": "Armen",
        "surname": "Baghdasaryan",
        "dateOfBirth": "05 Nov., 1997",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1010",
        "title": "Gevorg Zakaryan",
        "name": "Gevorg",
        "surname": "Zakaryan",
        "dateOfBirth": "09 Feb., 1997",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1009",
        "title": "Errik Sayadyan",
        "name": "Errik",
        "surname": "Sayadyan",
        "dateOfBirth": "10 May, 1994",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1008",
        "title": "Ashot Taziyan",
        "name": "Ashot",
        "surname": "Taziyan",
        "dateOfBirth": "13 Mar., 1991",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1007",
        "title": "Artur Asryan",
        "name": "Artur",
        "surname": "Asryan",
        "dateOfBirth": "26 Mar., 1998",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1006",
        "title": "Armen Varosyan",
        "name": "Armen",
        "surname": "Varosyan",
        "dateOfBirth": "13 Apr., 1988",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1005",
        "title": "Armen Matevosyan",
        "name": "Armen",
        "surname": "Matevosyan",
        "dateOfBirth": "16 Dec., 1994",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1004",
        "title": "Arman Mesropyan",
        "name": "Arman",
        "surname": "Mesropyan",
        "dateOfBirth": "05 Feb., 1992",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1003",
        "title": "Grisha Nazaryan",
        "name": "Grisha",
        "surname": "Nazaryan",
        "dateOfBirth": "16 Feb., 1996",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1002",
        "title": "Henrik Muradyan",
        "name": "Henrik",
        "surname": "Muradyan",
        "dateOfBirth": "30 Jan., 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1001",
        "title": "Gor Eghiazaryan",
        "name": "Gor",
        "surname": "Eghiazaryan",
        "dateOfBirth": "07 July, 2000",
        "dateOfDeath": "13Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1890",
        "title": "Sevak Asryan",
        "name": "Sevak",
        "surname": "Asryan",
        "dateOfBirth": "07 Dec., 1980",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1889",
        "title": "Harutyun Hayrapetyan",
        "name": "Harutyun",
        "surname": "Hayrapetyan",
        "dateOfBirth": "27 Oct., 1996",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1888",
        "title": "Grigory Gabrielyan",
        "name": "Grigory",
        "surname": "Gabrielyan",
        "dateOfBirth": "18 Oct., 1995",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1887",
        "title": "Armen Hovakimyan",
        "name": "Armen",
        "surname": "Hovakimyan",
        "dateOfBirth": "18 Feb., 1991",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1886",
        "title": "Samvel Hovakimyan",
        "name": "Samvel",
        "surname": "Hovakimyan",
        "dateOfBirth": "26 Oct., 1997",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1885",
        "title": "Lernik Gabrielyan",
        "name": "Lernik",
        "surname": "Gabrielyan",
        "dateOfBirth": "20 Oct., 1990",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1884",
        "title": "Ashot Gevorgyan",
        "name": "Ashot",
        "surname": "Gevorgyan",
        "dateOfBirth": "31 Dec., 1996",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1883",
        "title": "Grisha Margaryan",
        "name": "Grisha",
        "surname": "Margaryan",
        "dateOfBirth": "24 July, 1956",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1882",
        "title": "Hayk Grigoryan",
        "name": "Hayk",
        "surname": "Grigoryan",
        "dateOfBirth": "30 June, 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1881",
        "title": "Anushavan Martirosyan",
        "name": "Anushavan",
        "surname": "Martirosyan",
        "dateOfBirth": "08 Aug., 1983",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1430",
        "title": "Artyom khachatryan",
        "name": "Artyom",
        "surname": "khachatryan",
        "dateOfBirth": "10 Feb., 1994",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1429",
        "title": "Aram Khachatryan",
        "name": "Aram",
        "surname": "Khachatryan",
        "dateOfBirth": "20 July, 1980",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1428",
        "title": "Marat Manoukyan",
        "name": "Marat",
        "surname": "Manoukyan",
        "dateOfBirth": "16 Sept. , 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1427",
        "title": "Gerasim Manoukyan",
        "name": "Gerasim",
        "surname": "Manoukyan",
        "dateOfBirth": "04 July, 2001",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1426",
        "title": "Erik Poghosyan",
        "name": "Erik",
        "surname": "Poghosyan",
        "dateOfBirth": "26 June, 1991",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1425",
        "title": "Habet Petrosyan",
        "name": "Habet",
        "surname": "Petrosyan",
        "dateOfBirth": "17 Nov., 1965",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1424",
        "title": "Mkrtich Kyureghyan",
        "name": "Mkrtich",
        "surname": "Kyureghyan",
        "dateOfBirth": "25 July, 2020",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1423",
        "title": "Karen Palakyan",
        "name": "Karen",
        "surname": "Palakyan",
        "dateOfBirth": "10 Oct., 1988",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1422",
        "title": "Paylak Khazaryan",
        "name": "Paylak",
        "surname": "Khazaryan",
        "dateOfBirth": "09 Jan., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1421",
        "title": "Garnik Khandilyan",
        "name": "Garnik",
        "surname": "Khandilyan",
        "dateOfBirth": "23 Aug., 2001",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "710",
        "title": "Arsen Sargsyan",
        "name": "Arsen",
        "surname": "Sargsyan",
        "dateOfBirth": "13 Dec., 2001",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "709",
        "title": "Artur Sargsyan",
        "name": "Artur",
        "surname": "Sargsyan",
        "dateOfBirth": "02 Oct., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "708",
        "title": "Samvel Harutyunyan",
        "name": "Samvel",
        "surname": "Harutyunyan",
        "dateOfBirth": "03 July, 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "707",
        "title": "Tigran Harutyunyan",
        "name": "Tigran",
        "surname": "Harutyunyan",
        "dateOfBirth": "22 Dec., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "706",
        "title": "Gegham Uzunyan",
        "name": "Gegham",
        "surname": "Uzunyan",
        "dateOfBirth": "04 May, 1994",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "705",
        "title": "Aghvan Hovhannisyan",
        "name": "Aghvan",
        "surname": "Hovhannisyan",
        "dateOfBirth": "03 Sept. , 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "704",
        "title": "Narek Sinanyan",
        "name": "Narek",
        "surname": "Sinanyan",
        "dateOfBirth": "26 May, 1992",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "703",
        "title": "Zohrab Hovhannisyan",
        "name": "Zohrab",
        "surname": "Hovhannisyan",
        "dateOfBirth": "17 Oct., 1982",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "702",
        "title": "Koryun Sergoyan",
        "name": "Koryun",
        "surname": "Sergoyan",
        "dateOfBirth": "16 Jan., 1979",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "701",
        "title": "Hovhannes Igityan",
        "name": "Hovhannes",
        "surname": "Igityan",
        "dateOfBirth": "08 Sept. , 1987",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1730",
        "title": "Հայկ Աննիկյան Տիգրանի",
        "name": "Հայկ",
        "surname": "Աննիկյան Տիգրանի",
        "dateOfBirth": "13 Nov., 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1729",
        "title": "Ժորա Առաքելյան Արմենի",
        "name": "Ժորա",
        "surname": "Առաքելյան Արմենի",
        "dateOfBirth": "23 Aug., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1728",
        "title": "Վարդան Ավետյան Արմենի",
        "name": "Վարդան",
        "surname": "Ավետյան Արմենի",
        "dateOfBirth": "28 Jan., 1998",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1727",
        "title": "էդգար Աղաբաբյան Իշխանի",
        "name": "էդգար",
        "surname": "Աղաբաբյան Իշխանի",
        "dateOfBirth": "14 Jan., 2002",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1726",
        "title": "Սլավիկ Ազարյան Արարատի",
        "name": "Սլավիկ",
        "surname": "Ազարյան Արարատի",
        "dateOfBirth": "17 Aug., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1725",
        "title": "Նարեկ Բալասյան Վիկտորի",
        "name": "Նարեկ",
        "surname": "Բալասյան Վիկտորի",
        "dateOfBirth": "18 Mar., 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1724",
        "title": "Սամվել Առաքելյան Լևոնի",
        "name": "Սամվել",
        "surname": "Առաքելյան Լևոնի",
        "dateOfBirth": "13 Sept. , 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1723",
        "title": "Գնել Ավագյան Արայիկի",
        "name": "Գնել",
        "surname": "Ավագյան Արայիկի",
        "dateOfBirth": "23 Feb., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1722",
        "title": "Սարգիս Ազարյան Վահանի",
        "name": "Սարգիս",
        "surname": "Ազարյան Վահանի",
        "dateOfBirth": "22 Jan., 1992",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1721",
        "title": "Դավիթ Բաղդասարյան Արմենի",
        "name": "Դավիթ",
        "surname": "Բաղդասարյան Արմենի",
        "dateOfBirth": "02 Oct., 1995",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1570",
        "title": "Հենրիկ  Վարդանյան  Ռազմիկի",
        "name": "Հենրիկ",
        "surname": " Վարդանյան  Ռազմիկի",
        "dateOfBirth": "02 Apr., 2001",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1569",
        "title": "Վաչիկ  Սարգսյան Հայկի",
        "name": "Վաչիկ",
        "surname": " Սարգսյան Հայկի",
        "dateOfBirth": "11 May, 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1568",
        "title": "Արսեն Խաչատրյան Սևակի",
        "name": "Արսեն",
        "surname": "Խաչատրյան Սևակի",
        "dateOfBirth": "17 Apr., 2002",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1567",
        "title": "Գագիկ Ղուկասյան Վաչիկի",
        "name": "Գագիկ",
        "surname": "Ղուկասյան Վաչիկի",
        "dateOfBirth": "23 Sept. , 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1566",
        "title": "Սերգեյ Վարդանյան Խոսրովի",
        "name": "Սերգեյ",
        "surname": "Վարդանյան Խոսրովի",
        "dateOfBirth": "14 Feb., 1993",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1565",
        "title": "Հայկ Խաչատրյան Ալբերտի",
        "name": "Հայկ",
        "surname": "Խաչատրյան Ալբերտի",
        "dateOfBirth": "06 Nov., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1564",
        "title": "Կարեն Խաչատրյան Արմենի",
        "name": "Կարեն",
        "surname": "Խաչատրյան Արմենի",
        "dateOfBirth": "07 Nov., 2001",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1563",
        "title": "Վարդան Վարդանյան Վալոդիայի",
        "name": "Վարդան",
        "surname": "Վարդանյան Վալոդիայի",
        "dateOfBirth": "31 Jan., 1962",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "կամավոր",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1562",
        "title": "Անուշավան  Խաչատրյան Վարուժանի",
        "name": "Անուշավան",
        "surname": " Խաչատրյան Վարուժանի",
        "dateOfBirth": "08 Apr., 1985",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1561",
        "title": "Մարսել Ասատրյան Խաչիկի",
        "name": "Մարսել",
        "surname": "Ասատրյան Խաչիկի",
        "dateOfBirth": "03 Aug., 2000",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2370",
        "title": "Sasun Grigoryan",
        "name": "Sasun",
        "surname": "Grigoryan",
        "dateOfBirth": "28 June, 1998",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2369",
        "title": "Artak Ishkhanyan",
        "name": "Artak",
        "surname": "Ishkhanyan",
        "dateOfBirth": "03 July, 1993",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2368",
        "title": "Arkady Baghdasaryan",
        "name": "Arkady",
        "surname": "Baghdasaryan",
        "dateOfBirth": "17 Jan., 1995",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2367",
        "title": "Sergey Mirzoyan",
        "name": "Sergey",
        "surname": "Mirzoyan",
        "dateOfBirth": "07 Jan., 1988",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2366",
        "title": "Armen Baghdasaryan",
        "name": "Armen",
        "surname": "Baghdasaryan",
        "dateOfBirth": "14 July, 1999",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2365",
        "title": "Igor Bagryan",
        "name": "Igor",
        "surname": "Bagryan",
        "dateOfBirth": "16 Mar., 1977",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2364",
        "title": "Makena Bagryan",
        "name": "Makena",
        "surname": "Bagryan",
        "dateOfBirth": "01 Mar., 1978",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2363",
        "title": "Grigory Tsatryan",
        "name": "Grigory",
        "surname": "Tsatryan",
        "dateOfBirth": "03 Mar., 1987",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2362",
        "title": "Ashot Harutyunyan",
        "name": "Ashot",
        "surname": "Harutyunyan",
        "dateOfBirth": "05 Oct., 1982",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2361",
        "title": "Garik Harutyunyan",
        "name": "Garik",
        "surname": "Harutyunyan",
        "dateOfBirth": "30 Mar., 1977",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1070",
        "title": "Edgar Arakelyan",
        "name": "Edgar",
        "surname": "Arakelyan",
        "dateOfBirth": "26 July, 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1069",
        "title": "Artyom Arakelyan",
        "name": "Artyom",
        "surname": "Arakelyan",
        "dateOfBirth": "31 Oct., 1997",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1068",
        "title": "Andranik Arakelyan",
        "name": "Andranik",
        "surname": "Arakelyan",
        "dateOfBirth": "22 Oct., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1067",
        "title": "Hovhannes Alishyan",
        "name": "Hovhannes",
        "surname": "Alishyan",
        "dateOfBirth": "19 Aug., 1998",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1066",
        "title": "Aram Torosyan",
        "name": "Aram",
        "surname": "Torosyan",
        "dateOfBirth": "19 Jan., 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1065",
        "title": "Aram Tevonyan",
        "name": "Aram",
        "surname": "Tevonyan",
        "dateOfBirth": "10 July, 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1064",
        "title": "Shahen Grigoryan",
        "name": "Shahen",
        "surname": "Grigoryan",
        "dateOfBirth": "08 June, 1970",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1063",
        "title": "Arman Harutyunyan",
        "name": "Arman",
        "surname": "Harutyunyan",
        "dateOfBirth": "24 June, 1984",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1062",
        "title": "Artur Grigoryan",
        "name": "Artur",
        "surname": "Grigoryan",
        "dateOfBirth": "02 Dec., 1977",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1061",
        "title": "Khazhak Grigoryan",
        "name": "Khazhak",
        "surname": "Grigoryan",
        "dateOfBirth": "20 Mar., 1973",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1020",
        "title": "Yuri Alevoryan",
        "name": "Yuri",
        "surname": "Alevoryan",
        "dateOfBirth": "10 Apr., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1019",
        "title": "Arayik Aleksanyan",
        "name": "Arayik",
        "surname": "Aleksanyan",
        "dateOfBirth": "20 Jan., 1978",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1018",
        "title": "Martun Tonoyan",
        "name": "Martun",
        "surname": "Tonoyan",
        "dateOfBirth": "08 Mar., 1990",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1017",
        "title": "Lyudvig Grigoryan",
        "name": "Lyudvig",
        "surname": "Grigoryan",
        "dateOfBirth": "15 May, 1993",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1016",
        "title": "Koryun Sarikyan",
        "name": "Koryun",
        "surname": "Sarikyan",
        "dateOfBirth": "20 Mar., 1990",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1015",
        "title": "Karo Balayan",
        "name": "Karo",
        "surname": "Balayan",
        "dateOfBirth": "19 Mar., 1998",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1014",
        "title": "Haykaz Khachatryan",
        "name": "Haykaz",
        "surname": "Khachatryan",
        "dateOfBirth": "10 June, 1986",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1013",
        "title": "Hakob Hakobyan",
        "name": "Hakob",
        "surname": "Hakobyan",
        "dateOfBirth": "03 Aug., 1979",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1012",
        "title": "Grigor Tumasyan",
        "name": "Grigor",
        "surname": "Tumasyan",
        "dateOfBirth": "01 June, 1988",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1011",
        "title": "Grigor Hakobyan",
        "name": "Grigor",
        "surname": "Hakobyan",
        "dateOfBirth": "01 Jan., 1976",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1250",
        "title": "Rusik Malkhasyan",
        "name": "Rusik",
        "surname": "Malkhasyan",
        "dateOfBirth": "15 Aug., 1976",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1249",
        "title": "Grigor Setpanyan",
        "name": "Grigor",
        "surname": "Setpanyan",
        "dateOfBirth": "06 Dec., 1994",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1248",
        "title": "Vahe Lazaryan",
        "name": "Vahe",
        "surname": "Lazaryan",
        "dateOfBirth": "08 Sept. , 1976",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1247",
        "title": "Asatur Shahnazaryan",
        "name": "Asatur",
        "surname": "Shahnazaryan",
        "dateOfBirth": "28 May, 1996",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1246",
        "title": "Alen Aghazaryan",
        "name": "Alen",
        "surname": "Aghazaryan",
        "dateOfBirth": "03 Feb., 1990",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1245",
        "title": "Gagik Avetisyan",
        "name": "Gagik",
        "surname": "Avetisyan",
        "dateOfBirth": "05 Feb., 1958",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1244",
        "title": "Avetis Avetisyan",
        "name": "Avetis",
        "surname": "Avetisyan",
        "dateOfBirth": "05 Oct., 1986",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1243",
        "title": "Valeri Sargsyan",
        "name": "Valeri",
        "surname": "Sargsyan",
        "dateOfBirth": "07 Jan., 1972",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1242",
        "title": "Gevorg Kostanyan",
        "name": "Gevorg",
        "surname": "Kostanyan",
        "dateOfBirth": "02 July, 1996",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1241",
        "title": "Gevorg Avdikhanyan",
        "name": "Gevorg",
        "surname": "Avdikhanyan",
        "dateOfBirth": "16 Jan., 1995",
        "dateOfDeath": "17Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1750",
        "title": "Tigran Hovhannisyan",
        "name": "Tigran",
        "surname": "Hovhannisyan",
        "dateOfBirth": "20 Apr., 2002",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1749",
        "title": "Sergo Kotsinyan",
        "name": "Sergo",
        "surname": "Kotsinyan",
        "dateOfBirth": "13 Apr., 1977",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1748",
        "title": "Senik Movsisyan",
        "name": "Senik",
        "surname": "Movsisyan",
        "dateOfBirth": "19 Mar., 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1747",
        "title": "Taron Nerisyan",
        "name": "Taron",
        "surname": "Nerisyan",
        "dateOfBirth": "26 Jan., 1999",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1746",
        "title": "Artyom Hovhannisyan",
        "name": "Artyom",
        "surname": "Hovhannisyan",
        "dateOfBirth": "11 Nov., 2000",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1745",
        "title": "Margar Hovhannisyan",
        "name": "Margar",
        "surname": "Hovhannisyan",
        "dateOfBirth": "03 Feb., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1744",
        "title": "Sargis Poghosyan",
        "name": "Sargis",
        "surname": "Poghosyan",
        "dateOfBirth": "22 June, 1990",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1743",
        "title": "Edgar Kotanjyan",
        "name": "Edgar",
        "surname": "Kotanjyan",
        "dateOfBirth": "15 Aug., 2001",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1742",
        "title": "Styopa Sukiasyan",
        "name": "Styopa",
        "surname": "Sukiasyan",
        "dateOfBirth": "10 Feb., 2001",
        "dateOfDeath": "11Jan.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1741",
        "title": "Albert Khachatryan",
        "name": "Albert",
        "surname": "Khachatryan",
        "dateOfBirth": "12 June, 1994",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "460",
        "title": "Garik Davtyan",
        "name": "Garik",
        "surname": "Davtyan",
        "dateOfBirth": "13 Feb., 1998",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "459",
        "title": "Volodya Mheryan",
        "name": "Volodya",
        "surname": "Mheryan",
        "dateOfBirth": "22 Aug., 1997",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "458",
        "title": "Arthur Hovsepyan",
        "name": "Arthur",
        "surname": "Hovsepyan",
        "dateOfBirth": "05 July, 1987",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "457",
        "title": "Hakob Meliksetyan",
        "name": "Hakob",
        "surname": "Meliksetyan",
        "dateOfBirth": "16 Oct., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "456",
        "title": "Tornik Matevosyan",
        "name": "Tornik",
        "surname": "Matevosyan",
        "dateOfBirth": "22 Mar., 1998",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "455",
        "title": "Emil Martirosyan",
        "name": "Emil",
        "surname": "Martirosyan",
        "dateOfBirth": "19 Jan., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "454",
        "title": "Narek Harutyunyan",
        "name": "Narek",
        "surname": "Harutyunyan",
        "dateOfBirth": "27 July, 2000",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "453",
        "title": "Harutyun Chobanyan",
        "name": "Harutyun",
        "surname": "Chobanyan",
        "dateOfBirth": "18 Feb., 1993",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "452",
        "title": "Khachatur Balasanyan",
        "name": "Khachatur",
        "surname": "Balasanyan",
        "dateOfBirth": "26 Nov., 1985",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "451",
        "title": "David Badalyan",
        "name": "David",
        "surname": "Badalyan",
        "dateOfBirth": "23 Aug., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1300",
        "title": "Arman Alexanyan",
        "name": "Arman",
        "surname": "Alexanyan",
        "dateOfBirth": "28 Apr., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1299",
        "title": "Aziz Aghadjanyan",
        "name": "Aziz",
        "surname": "Aghadjanyan",
        "dateOfBirth": "20 May, 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1298",
        "title": "Armen Yeranosyan",
        "name": "Armen",
        "surname": "Yeranosyan",
        "dateOfBirth": "13 May, 1972",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1297",
        "title": "Arman Azatyan",
        "name": "Arman",
        "surname": "Azatyan",
        "dateOfBirth": "06 July, 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1296",
        "title": "Gevorg Movsisyan",
        "name": "Gevorg",
        "surname": "Movsisyan",
        "dateOfBirth": "07 June, 1992",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1295",
        "title": "Sasun Movsesyan",
        "name": "Sasun",
        "surname": "Movsesyan",
        "dateOfBirth": "06 Aug., 1989",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1294",
        "title": "Tigran Mnoyan",
        "name": "Tigran",
        "surname": "Mnoyan",
        "dateOfBirth": "04 Feb., 1997",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1293",
        "title": "Karen Badalyan",
        "name": "Karen",
        "surname": "Badalyan",
        "dateOfBirth": "20 Feb., 1992",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1292",
        "title": "Ashot Mnacakanyan",
        "name": "Ashot",
        "surname": "Mnacakanyan",
        "dateOfBirth": "02 Apr., 1979",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1291",
        "title": "Nahapet Ghevondyan",
        "name": "Nahapet",
        "surname": "Ghevondyan",
        "dateOfBirth": "25 June, 1983",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1140",
        "title": "Grigori Stepanyan",
        "name": "Grigori",
        "surname": "Stepanyan",
        "dateOfBirth": "10 June, 2000",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1139",
        "title": "Seyran Miskaryan",
        "name": "Seyran",
        "surname": "Miskaryan",
        "dateOfBirth": "13 July, 1986",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1138",
        "title": "Samvel Gharibyan",
        "name": "Samvel",
        "surname": "Gharibyan",
        "dateOfBirth": "06 Oct., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1137",
        "title": "Arayik Ghahramanyan",
        "name": "Arayik",
        "surname": "Ghahramanyan",
        "dateOfBirth": "12 Sept. , 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1136",
        "title": "Zorik Shahramanyan",
        "name": "Zorik",
        "surname": "Shahramanyan",
        "dateOfBirth": "30 June, 2002",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1135",
        "title": "Hayrapet Mikayelyan",
        "name": "Hayrapet",
        "surname": "Mikayelyan",
        "dateOfBirth": "29 Aug., 1994",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1134",
        "title": "Karen Israyelyan",
        "name": "Karen",
        "surname": "Israyelyan",
        "dateOfBirth": "26 Dec., 1980",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1133",
        "title": "Karen Melkonyan",
        "name": "Karen",
        "surname": "Melkonyan",
        "dateOfBirth": "02 Oct., 1991",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1132",
        "title": "Marat Danielyan",
        "name": "Marat",
        "surname": "Danielyan",
        "dateOfBirth": "18 Feb., 1983",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1131",
        "title": "Hovhannes Markosyan",
        "name": "Hovhannes",
        "surname": "Markosyan",
        "dateOfBirth": "07 Oct., 1979",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1120",
        "title": "Zohrap Kirakosyan",
        "name": "Zohrap",
        "surname": "Kirakosyan",
        "dateOfBirth": "14 Sept. , 1985",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1119",
        "title": "Armen Avetisyan",
        "name": "Armen",
        "surname": "Avetisyan",
        "dateOfBirth": "10 June, 1975",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1118",
        "title": "Narek Davtyan",
        "name": "Narek",
        "surname": "Davtyan",
        "dateOfBirth": "18 July, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1117",
        "title": "Ghukas Poghosyan",
        "name": "Ghukas",
        "surname": "Poghosyan",
        "dateOfBirth": "21 Oct., 1990",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1116",
        "title": "Vagharshak Galstyan",
        "name": "Vagharshak",
        "surname": "Galstyan",
        "dateOfBirth": "14 Dec., 1989",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1115",
        "title": "Edgar Galstyan",
        "name": "Edgar",
        "surname": "Galstyan",
        "dateOfBirth": "30 Apr., 1999",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1114",
        "title": "Garnik Fidanyan",
        "name": "Garnik",
        "surname": "Fidanyan",
        "dateOfBirth": "19 Aug., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1113",
        "title": "Melik Harutyunyan",
        "name": "Melik",
        "surname": "Harutyunyan",
        "dateOfBirth": "15 Nov., 1998",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1112",
        "title": "Hamlet Badalyan",
        "name": "Hamlet",
        "surname": "Badalyan",
        "dateOfBirth": "19 Apr., 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1111",
        "title": "Emin Karapetyan",
        "name": "Emin",
        "surname": "Karapetyan",
        "dateOfBirth": "05 Nov., 1990",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "940",
        "title": "Hovhannes Avagyan",
        "name": "Hovhannes",
        "surname": "Avagyan",
        "dateOfBirth": "30 Nov., 1973",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "939",
        "title": "Zohrab Arshakyan",
        "name": "Zohrab",
        "surname": "Arshakyan",
        "dateOfBirth": "24 Jan., 1990",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "938",
        "title": "Karen Apresyan",
        "name": "Karen",
        "surname": "Apresyan",
        "dateOfBirth": "30 Apr., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "937",
        "title": "Movses Sargsyan",
        "name": "Movses",
        "surname": "Sargsyan",
        "dateOfBirth": "19 June, 2001",
        "dateOfDeath": "15Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "936",
        "title": "Vakhtang Apinyan",
        "name": "Vakhtang",
        "surname": "Apinyan",
        "dateOfBirth": "26 Mar., 1983",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "935",
        "title": "Hovik Sardaryan",
        "name": "Hovik",
        "surname": "Sardaryan",
        "dateOfBirth": "09 Feb., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "934",
        "title": "Ashot Sardaryan",
        "name": "Ashot",
        "surname": "Sardaryan",
        "dateOfBirth": "04 July, 2001",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "933",
        "title": "Zhora Martirosyan",
        "name": "Zhora",
        "surname": "Martirosyan",
        "dateOfBirth": "10 June, 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "932",
        "title": "Karen Margaryan",
        "name": "Karen",
        "surname": "Margaryan",
        "dateOfBirth": "23 Nov., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "931",
        "title": "Karapet Harutyunyan",
        "name": "Karapet",
        "surname": "Harutyunyan",
        "dateOfBirth": "13 June, 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "160",
        "title": "Davit Sargsyan",
        "name": "Davit",
        "surname": "Sargsyan",
        "dateOfBirth": "12 July, 1956",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "189 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "159",
        "title": "Armen Hovhannisyan",
        "name": "Armen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "13 May, 1978",
        "dateOfDeath": "07Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "85 856 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "158",
        "title": "Vrej Sargsyan",
        "name": "Vrej",
        "surname": "Sargsyan",
        "dateOfBirth": "22 Mar., 1989",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "149 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "157",
        "title": "Sargis Khalafyan",
        "name": "Sargis",
        "surname": "Khalafyan",
        "dateOfBirth": "10 June, 1967",
        "dateOfDeath": "05Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "156",
        "title": "Sasun Mkrtchyan",
        "name": "Sasun",
        "surname": "Mkrtchyan",
        "dateOfBirth": "10 June, 1989",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "155",
        "title": "Benjamin Yeghoyan",
        "name": "Benjamin",
        "surname": "Yeghoyan",
        "dateOfBirth": "19 May, 1992",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "154",
        "title": "Hovsep Kirakosyan",
        "name": "Hovsep",
        "surname": "Kirakosyan",
        "dateOfBirth": "23 Aug., 1998",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "41 510 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "153",
        "title": "Armenak Urfanyan",
        "name": "Armenak",
        "surname": "Urfanyan",
        "dateOfBirth": "28 Jan., 1990",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "152",
        "title": "Ashot Shahbazyan",
        "name": "Ashot",
        "surname": "Shahbazyan",
        "dateOfBirth": "05 June, 1993",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "151",
        "title": "Merujan Stepanyan",
        "name": "Merujan",
        "surname": "Stepanyan",
        "dateOfBirth": "17 Jan., 1993",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "226 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "390",
        "title": "Khachatur Khachatryan",
        "name": "Khachatur",
        "surname": "Khachatryan",
        "dateOfBirth": "28 Oct., 1994",
        "dateOfDeath": "15Sept.,2013",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "389",
        "title": "Hrant Harutyunyan",
        "name": "Hrant",
        "surname": "Harutyunyan",
        "dateOfBirth": "25 Sept. , 1999",
        "dateOfDeath": "14May,2019",
        "Status": "Marked disability",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "5 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "388",
        "title": "Mushegh Abovyan",
        "name": "Mushegh",
        "surname": "Abovyan",
        "dateOfBirth": "10 Dec., 1991",
        "dateOfDeath": "03Oct.,2019",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "387",
        "title": "Arayik Babayan",
        "name": "Arayik",
        "surname": "Babayan",
        "dateOfBirth": "13 Mar., 1995",
        "dateOfDeath": "27Mar.,2014",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "386",
        "title": "Hakob Khojoyan",
        "name": "Hakob",
        "surname": "Khojoyan",
        "dateOfBirth": "18 Apr., 1989",
        "dateOfDeath": "02Sept.,2008",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "385",
        "title": "Levon Tamrazyan",
        "name": "Levon",
        "surname": "Tamrazyan",
        "dateOfBirth": "07 Mar., 1971",
        "dateOfDeath": "07July,2012",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "384",
        "title": "Vazgen Hovhannisyan",
        "name": "Vazgen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "29 Apr., 1994",
        "dateOfDeath": "07Jan.,2014",
        "Status": "Marked disability",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "26 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "383",
        "title": "Andranik Arzumanyan",
        "name": "Andranik",
        "surname": "Arzumanyan",
        "dateOfBirth": "30 May, 1991",
        "dateOfDeath": "23June,2012",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "382",
        "title": "Arman Bulghadaryan",
        "name": "Arman",
        "surname": "Bulghadaryan",
        "dateOfBirth": "11 July, 1995",
        "dateOfDeath": "28July,2019",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "381",
        "title": "Harutyun Safaryan",
        "name": "Harutyun",
        "surname": "Safaryan",
        "dateOfBirth": "02 June, 1987",
        "dateOfDeath": "31Mar.,2014",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "770",
        "title": "Samvel Gevorgyan",
        "name": "Samvel",
        "surname": "Gevorgyan",
        "dateOfBirth": "09 Oct., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "769",
        "title": "Nubar Hanyan",
        "name": "Nubar",
        "surname": "Hanyan",
        "dateOfBirth": "03 June, 1998",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "768",
        "title": "Hrayr Harutyunyan",
        "name": "Hrayr",
        "surname": "Harutyunyan",
        "dateOfBirth": "12 June, 1997",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "767",
        "title": "Manvel Harutyunyan",
        "name": "Manvel",
        "surname": "Harutyunyan",
        "dateOfBirth": "27 Dec., 1991",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "766",
        "title": "Misha Harutyunyan",
        "name": "Misha",
        "surname": "Harutyunyan",
        "dateOfBirth": "17 July, 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "765",
        "title": "Gevorg Martirosyan",
        "name": "Gevorg",
        "surname": "Martirosyan",
        "dateOfBirth": "07 Jan., 1996",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "764",
        "title": "Karen Araqelyan",
        "name": "Karen",
        "surname": "Araqelyan",
        "dateOfBirth": "30 Sept. , 2000",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "763",
        "title": "Gevorg Manasaryan",
        "name": "Gevorg",
        "surname": "Manasaryan",
        "dateOfBirth": "06 Nov., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "762",
        "title": "Armen Arustamyan",
        "name": "Armen",
        "surname": "Arustamyan",
        "dateOfBirth": "19 Jan., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "761",
        "title": "Samvel Atoyan",
        "name": "Samvel",
        "surname": "Atoyan",
        "dateOfBirth": "04 May, 1977",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "550",
        "title": "Grigor Grigoryan",
        "name": "Grigor",
        "surname": "Grigoryan",
        "dateOfBirth": "28 Apr., 2002",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "549",
        "title": "Slavik Avetisyan",
        "name": "Slavik",
        "surname": "Avetisyan",
        "dateOfBirth": "13 Aug., 1980",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "548",
        "title": "Haroutyun Avetisyan",
        "name": "Haroutyun",
        "surname": "Avetisyan",
        "dateOfBirth": "11 Aug., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "547",
        "title": "Karapet Antonyan",
        "name": "Karapet",
        "surname": "Antonyan",
        "dateOfBirth": "09 July, 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "546",
        "title": "Emil Alexandryan",
        "name": "Emil",
        "surname": "Alexandryan",
        "dateOfBirth": "05 Dec., 2000",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "545",
        "title": "Boris Grigoryan",
        "name": "Boris",
        "surname": "Grigoryan",
        "dateOfBirth": "22 Oct., 1972",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "544",
        "title": "Arsen Abroyan",
        "name": "Arsen",
        "surname": "Abroyan",
        "dateOfBirth": "26 Aug., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "543",
        "title": "Gevorg Gevorgyan",
        "name": "Gevorg",
        "surname": "Gevorgyan",
        "dateOfBirth": "14 Feb., 1999",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "542",
        "title": "Edmon Gevorgyan",
        "name": "Edmon",
        "surname": "Gevorgyan",
        "dateOfBirth": "25 Dec., 2001",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "541",
        "title": "Karen Tsatryan",
        "name": "Karen",
        "surname": "Tsatryan",
        "dateOfBirth": "08 Aug., 1977",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2010",
        "title": "Aram Arakelyan",
        "name": "Aram",
        "surname": "Arakelyan",
        "dateOfBirth": "03 Mar., 1971",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2009",
        "title": "Artsrun Arakelyan",
        "name": "Artsrun",
        "surname": "Arakelyan",
        "dateOfBirth": "15 Aug., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2008",
        "title": "Rafael Ghazaryan",
        "name": "Rafael",
        "surname": "Ghazaryan",
        "dateOfBirth": "27 July, 1993",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37410513701",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2007",
        "title": "Garik Arevikyan",
        "name": "Garik",
        "surname": "Arevikyan",
        "dateOfBirth": "07 Nov., 1997",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2006",
        "title": "Boris Avanesyan",
        "name": "Boris",
        "surname": "Avanesyan",
        "dateOfBirth": "08 Feb., 1990",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410511211",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2005",
        "title": "Arthur Avetisyan",
        "name": "Arthur",
        "surname": "Avetisyan",
        "dateOfBirth": "07 Oct., 1972",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2004",
        "title": "Erik Abrahamyan",
        "name": "Erik",
        "surname": "Abrahamyan",
        "dateOfBirth": "28 Apr., 1994",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2003",
        "title": "Razmik Avetisyan",
        "name": "Razmik",
        "surname": "Avetisyan",
        "dateOfBirth": "08 Mar., 1993",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2002",
        "title": "Surik Avetisyan",
        "name": "Surik",
        "surname": "Avetisyan",
        "dateOfBirth": "30 Nov., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2001",
        "title": "Gnel Aghajanyan",
        "name": "Gnel",
        "surname": "Aghajanyan",
        "dateOfBirth": "26 July, 1996",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "+37410592019",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "430",
        "title": "Arayik Edilyan",
        "name": "Arayik",
        "surname": "Edilyan",
        "dateOfBirth": "10 Nov., 1997",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "429",
        "title": "Dmitri Ispiryan",
        "name": "Dmitri",
        "surname": "Ispiryan",
        "dateOfBirth": "01 Dec., 1987",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "428",
        "title": "Albert Dadoyan",
        "name": "Albert",
        "surname": "Dadoyan",
        "dateOfBirth": "28 Oct., 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "427",
        "title": "Eduard Hovhannisyan",
        "name": "Eduard",
        "surname": "Hovhannisyan",
        "dateOfBirth": "15 Apr., 1988",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Contract soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "426",
        "title": "Narek Loretsyan",
        "name": "Narek",
        "surname": "Loretsyan",
        "dateOfBirth": "03 Oct., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "425",
        "title": "Narek Mesropyan",
        "name": "Narek",
        "surname": "Mesropyan",
        "dateOfBirth": "11 Sept. , 1989",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Military officer staff",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "424",
        "title": "Arman Tovmasyan",
        "name": "Arman",
        "surname": "Tovmasyan",
        "dateOfBirth": "02 Feb., 1999",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "423",
        "title": "Edgar Zakaryan",
        "name": "Edgar",
        "surname": "Zakaryan",
        "dateOfBirth": "18 Mar., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "422",
        "title": "Liporit Dashtoyan",
        "name": "Liporit",
        "surname": "Dashtoyan",
        "dateOfBirth": "19 Nov., 1997",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "421",
        "title": "Gevorg Harutyunyan",
        "name": "Gevorg",
        "surname": "Harutyunyan",
        "dateOfBirth": "18 Jan., 1999",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1700",
        "title": "Սպարտակ Մնացականյան Լևոնի",
        "name": "Սպարտակ",
        "surname": "Մնացականյան Լևոնի",
        "dateOfBirth": "03 Nov., 2000",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1699",
        "title": "Խաչիկ Մուրադյան  Մեսրոպի",
        "name": "Խաչիկ",
        "surname": "Մուրադյան  Մեսրոպի",
        "dateOfBirth": "14 Mar., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1698",
        "title": "Վազգեն Հովհաննիսյան Մարտինի",
        "name": "Վազգեն",
        "surname": "Հովհաննիսյան Մարտինի",
        "dateOfBirth": "12 Sept. , 1984",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1697",
        "title": "Ալբերտ Նազարյան Էդուարդի",
        "name": "Ալբերտ",
        "surname": "Նազարյան Էդուարդի",
        "dateOfBirth": "19 Dec., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1696",
        "title": "Հմայակ Կարապետյան Գրիշայի",
        "name": "Հմայակ",
        "surname": "Կարապետյան Գրիշայի",
        "dateOfBirth": "25 June, 1979",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1695",
        "title": "Արմեն Աղեկյան Գեղայրի",
        "name": "Արմեն",
        "surname": "Աղեկյան Գեղայրի",
        "dateOfBirth": "09 Nov., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1694",
        "title": "Արման Աղվանյան Սանասարի",
        "name": "Արման",
        "surname": "Աղվանյան Սանասարի",
        "dateOfBirth": "10 Aug., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1693",
        "title": "Թաթուլ Աբրահամյան Գագիկի",
        "name": "Թաթուլ",
        "surname": "Աբրահամյան Գագիկի",
        "dateOfBirth": "17 Mar., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1692",
        "title": "Համբարձում Կիրակոսյան Հովհաննեսի",
        "name": "Համբարձում",
        "surname": "Կիրակոսյան Հովհաննեսի",
        "dateOfBirth": "01 Jan., 1995",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1691",
        "title": "Արտաշես Այվազյան Ռուստամի",
        "name": "Արտաշես",
        "surname": "Այվազյան Ռուստամի",
        "dateOfBirth": "13 Aug., 2001",
        "dateOfDeath": "27Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1530",
        "title": "Արման Նազարեթյան Վախթանգի",
        "name": "Արման",
        "surname": "Նազարեթյան Վախթանգի",
        "dateOfBirth": "08 Aug., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1529",
        "title": "Իսահակ Ղարիբյան Արթուրի",
        "name": "Իսահակ",
        "surname": "Ղարիբյան Արթուրի",
        "dateOfBirth": "19 Nov., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1528",
        "title": "Դավիթ Ռաֆայելյան Արայի",
        "name": "Դավիթ",
        "surname": "Ռաֆայելյան Արայի",
        "dateOfBirth": "23 June, 2001",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1527",
        "title": "Արա Սաֆարյան Սեդրակի",
        "name": "Արա",
        "surname": "Սաֆարյան Սեդրակի",
        "dateOfBirth": "10 May, 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1526",
        "title": "Հովիկ Սահակյան Արտաշեսի",
        "name": "Հովիկ",
        "surname": "Սահակյան Արտաշեսի",
        "dateOfBirth": "08 Apr., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1525",
        "title": "Սևակ Հենրիկի Սահակյան",
        "name": "Սևակ",
        "surname": "Հենրիկի Սահակյան",
        "dateOfBirth": "10 Feb., 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1524",
        "title": "Արմեն Ղազարյան Վաղինակի",
        "name": "Արմեն",
        "surname": "Ղազարյան Վաղինակի",
        "dateOfBirth": "02 Dec., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1523",
        "title": "Արտյոմ  Սանոսյան Սարգիսի",
        "name": "Արտյոմ",
        "surname": " Սանոսյան Սարգիսի",
        "dateOfBirth": "11 Jan., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1522",
        "title": "Նարեկ Ղազարյան Գեղամի",
        "name": "Նարեկ",
        "surname": "Ղազարյան Գեղամի",
        "dateOfBirth": "13 Feb., 2002",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1521",
        "title": "Հայկ Ղուրշուդյան Էդվարդի",
        "name": "Հայկ",
        "surname": "Ղուրշուդյան Էդվարդի",
        "dateOfBirth": "30 July, 2000",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "450",
        "title": "Tigran Virabyan",
        "name": "Tigran",
        "surname": "Virabyan",
        "dateOfBirth": "17 Dec., 1977",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "449",
        "title": "Maksim Manukyan",
        "name": "Maksim",
        "surname": "Manukyan",
        "dateOfBirth": "08 Mar., 2002",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "448",
        "title": "Khachik Galstyan",
        "name": "Khachik",
        "surname": "Galstyan",
        "dateOfBirth": "02 Apr., 1989",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "447",
        "title": "Gegham Khachatryan",
        "name": "Gegham",
        "surname": "Khachatryan",
        "dateOfBirth": "08 Nov., 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "446",
        "title": "Vahe Vardanyan",
        "name": "Vahe",
        "surname": "Vardanyan",
        "dateOfBirth": "22 Aug., 2000",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "445",
        "title": "Arsen Hovhannisyan",
        "name": "Arsen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "17 June, 1968",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "444",
        "title": "Gagik Hovhannisyan",
        "name": "Gagik",
        "surname": "Hovhannisyan",
        "dateOfBirth": "12 Sept. , 2000",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "443",
        "title": "Levon Asoyan",
        "name": "Levon",
        "surname": "Asoyan",
        "dateOfBirth": "27 Dec., 2000",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "442",
        "title": "Mayis Khachatryan",
        "name": "Mayis",
        "surname": "Khachatryan",
        "dateOfBirth": "10 May, 1987",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "441",
        "title": "Ashot Vardazaryan",
        "name": "Ashot",
        "surname": "Vardazaryan",
        "dateOfBirth": "19 Apr., 1985",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "600",
        "title": "Eduard Muradyan",
        "name": "Eduard",
        "surname": "Muradyan",
        "dateOfBirth": "19 Jan., 2001",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "599",
        "title": "Albert Hovhannisyan",
        "name": "Albert",
        "surname": "Hovhannisyan",
        "dateOfBirth": "15 Sept. , 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "598",
        "title": "Edgar Muradyan",
        "name": "Edgar",
        "surname": "Muradyan",
        "dateOfBirth": "18 Feb., 2000",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "597",
        "title": "Artur Movsisyan",
        "name": "Artur",
        "surname": "Movsisyan",
        "dateOfBirth": "09 Mar., 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "596",
        "title": "Haykaz Mkrtchyan",
        "name": "Haykaz",
        "surname": "Mkrtchyan",
        "dateOfBirth": "31 Oct., 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "595",
        "title": "Arman Mkrtchyan",
        "name": "Arman",
        "surname": "Mkrtchyan",
        "dateOfBirth": "21 Dec., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "594",
        "title": "Areg Mkrtchyan",
        "name": "Areg",
        "surname": "Mkrtchyan",
        "dateOfBirth": "20 Nov., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "593",
        "title": "Edgar Mheryan",
        "name": "Edgar",
        "surname": "Mheryan",
        "dateOfBirth": "19 Feb., 2002",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "592",
        "title": "Albert Khachatryan",
        "name": "Albert",
        "surname": "Khachatryan",
        "dateOfBirth": "19 Apr., 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "591",
        "title": "Tatul Tovmasyan",
        "name": "Tatul",
        "surname": "Tovmasyan",
        "dateOfBirth": "19 Nov., 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1310",
        "title": "Van Aslanyan",
        "name": "Van",
        "surname": "Aslanyan",
        "dateOfBirth": "17 Aug., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1309",
        "title": "Ashot Ashotyan",
        "name": "Ashot",
        "surname": "Ashotyan",
        "dateOfBirth": "25 Sept. , 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1308",
        "title": "Artak Soghomonyan",
        "name": "Artak",
        "surname": "Soghomonyan",
        "dateOfBirth": "27 Jan., 1990",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1307",
        "title": "Artavazd Asatryan",
        "name": "Artavazd",
        "surname": "Asatryan",
        "dateOfBirth": "08 June, 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1306",
        "title": "Myasnik Simonyan",
        "name": "Myasnik",
        "surname": "Simonyan",
        "dateOfBirth": "21 Dec., 1984",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1305",
        "title": "Ilich Shahbazyan",
        "name": "Ilich",
        "surname": "Shahbazyan",
        "dateOfBirth": "18 Sept. , 1968",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1304",
        "title": "Gor Avagyan",
        "name": "Gor",
        "surname": "Avagyan",
        "dateOfBirth": "29 June, 1995",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1303",
        "title": "Garegin Aryan",
        "name": "Garegin",
        "surname": "Aryan",
        "dateOfBirth": "13 Aug., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1302",
        "title": "Norayr Artemyan",
        "name": "Norayr",
        "surname": "Artemyan",
        "dateOfBirth": "13 July, 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1301",
        "title": "Otar Amaryan",
        "name": "Otar",
        "surname": "Amaryan",
        "dateOfBirth": "16 July, 1998",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1460",
        "title": "Vazgen Mikhayelyan",
        "name": "Vazgen",
        "surname": "Mikhayelyan",
        "dateOfBirth": "21 May, 1998",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1459",
        "title": "Radik Manoukyan",
        "name": "Radik",
        "surname": "Manoukyan",
        "dateOfBirth": "29 Dec., 2020",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1458",
        "title": "Andrey Melkumyan",
        "name": "Andrey",
        "surname": "Melkumyan",
        "dateOfBirth": "06 Sept. , 1993",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1457",
        "title": "Garnik Kziryants",
        "name": "Garnik",
        "surname": "Kziryants",
        "dateOfBirth": "28 Aug., 1991",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1456",
        "title": "Narek Manoukyan",
        "name": "Narek",
        "surname": "Manoukyan",
        "dateOfBirth": "21 Nov., 2000",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1455",
        "title": "Samvel Hayrapetyan",
        "name": "Samvel",
        "surname": "Hayrapetyan",
        "dateOfBirth": "12 Aug., 1984",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1454",
        "title": "Harutyun Tavadyan",
        "name": "Harutyun",
        "surname": "Tavadyan",
        "dateOfBirth": "24 June, 1992",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1453",
        "title": "Artur Eghiazaryan",
        "name": "Artur",
        "surname": "Eghiazaryan",
        "dateOfBirth": "04 June, 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1452",
        "title": "Sevak Saghatelyan",
        "name": "Sevak",
        "surname": "Saghatelyan",
        "dateOfBirth": "01 Jan., 1978",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1451",
        "title": "Arsen Dadamyan",
        "name": "Arsen",
        "surname": "Dadamyan",
        "dateOfBirth": "03 Sept. , 1981",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2310",
        "title": "Edgar Dolukhanyan",
        "name": "Edgar",
        "surname": "Dolukhanyan",
        "dateOfBirth": "14 Nov., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2309",
        "title": "Sarmen Gasparyan",
        "name": "Sarmen",
        "surname": "Gasparyan",
        "dateOfBirth": "13 Jan., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2308",
        "title": "Arsen Grigoryan",
        "name": "Arsen",
        "surname": "Grigoryan",
        "dateOfBirth": "13 Jan., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2307",
        "title": "Hayk Grigoryan",
        "name": "Hayk",
        "surname": "Grigoryan",
        "dateOfBirth": "05 Aug., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2306",
        "title": "Leonid Allahverdyan",
        "name": "Leonid",
        "surname": "Allahverdyan",
        "dateOfBirth": "03 Nov., 2000",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2305",
        "title": "Aram Arzumanyan",
        "name": "Aram",
        "surname": "Arzumanyan",
        "dateOfBirth": "26 Jan., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2304",
        "title": "Aren Avagyan",
        "name": "Aren",
        "surname": "Avagyan",
        "dateOfBirth": "15 Dec., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2303",
        "title": "Arman Aghajanyan",
        "name": "Arman",
        "surname": "Aghajanyan",
        "dateOfBirth": "12 Apr., 2001",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2302",
        "title": "Hayk Mangasaryan",
        "name": "Hayk",
        "surname": "Mangasaryan",
        "dateOfBirth": "31 Aug., 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "2301",
        "title": "Andranik Babayan",
        "name": "Andranik",
        "surname": "Babayan",
        "dateOfBirth": "29 June, 2002",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "+37460747779",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "900",
        "title": "Manvel Yrkoyan",
        "name": "Manvel",
        "surname": "Yrkoyan",
        "dateOfBirth": "08 Oct., 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "899",
        "title": "Artur Poghosyan",
        "name": "Artur",
        "surname": "Poghosyan",
        "dateOfBirth": "11 Nov., 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "898",
        "title": "Artem Chipliev",
        "name": "Artem",
        "surname": "Chipliev",
        "dateOfBirth": "22 Feb., 2002",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "897",
        "title": "Harutyun Nikolyan",
        "name": "Harutyun",
        "surname": "Nikolyan",
        "dateOfBirth": "29 Apr., 2001",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "896",
        "title": "Hrant Chilingaryan",
        "name": "Hrant",
        "surname": "Chilingaryan",
        "dateOfBirth": "30 Mar., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "895",
        "title": "Nikoghos Mkhitaryan",
        "name": "Nikoghos",
        "surname": "Mkhitaryan",
        "dateOfBirth": "06 Sept. , 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "894",
        "title": "Mushegh Muradyan",
        "name": "Mushegh",
        "surname": "Muradyan",
        "dateOfBirth": "21 Apr., 1993",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "893",
        "title": "Khachik Botsinyan",
        "name": "Khachik",
        "surname": "Botsinyan",
        "dateOfBirth": "12 Dec., 1989",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "892",
        "title": "Artur Harutyunyan",
        "name": "Artur",
        "surname": "Harutyunyan",
        "dateOfBirth": "18 Jan., 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "891",
        "title": "Seryozha Mkhoyan",
        "name": "Seryozha",
        "surname": "Mkhoyan",
        "dateOfBirth": "22 Feb., 2002",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1210",
        "title": "Vrej Poghosyan",
        "name": "Vrej",
        "surname": "Poghosyan",
        "dateOfBirth": "01 July, 1992",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1209",
        "title": "Aram Papyan",
        "name": "Aram",
        "surname": "Papyan",
        "dateOfBirth": "06 Feb., 1973",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1208",
        "title": "Artem Poghosyan",
        "name": "Artem",
        "surname": "Poghosyan",
        "dateOfBirth": "12 Apr., 1982",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1207",
        "title": "Grigor Poghosyan",
        "name": "Grigor",
        "surname": "Poghosyan",
        "dateOfBirth": "13 May, 1992",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1206",
        "title": "Samvel Ananyan",
        "name": "Samvel",
        "surname": "Ananyan",
        "dateOfBirth": "30 Nov., 1988",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1205",
        "title": "Gevorg Ananyan",
        "name": "Gevorg",
        "surname": "Ananyan",
        "dateOfBirth": "07 Feb., 1989",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1204",
        "title": "Harutyun Amirdjanyan",
        "name": "Harutyun",
        "surname": "Amirdjanyan",
        "dateOfBirth": "20 Oct., 1983",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1203",
        "title": "Artyom Aleksanyan",
        "name": "Artyom",
        "surname": "Aleksanyan",
        "dateOfBirth": "28 Nov., 1983",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1202",
        "title": "Vardan Karapetyan",
        "name": "Vardan",
        "surname": "Karapetyan",
        "dateOfBirth": "22 Nov., 1984",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1201",
        "title": "Gurgen Alaverdyan",
        "name": "Gurgen",
        "surname": "Alaverdyan",
        "dateOfBirth": "15 Sept. , 1995",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "980",
        "title": "David Khachatryan",
        "name": "David",
        "surname": "Khachatryan",
        "dateOfBirth": "14 Feb., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "979",
        "title": "Vachagan Toroyan",
        "name": "Vachagan",
        "surname": "Toroyan",
        "dateOfBirth": "30 May, 1997",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "978",
        "title": "Arakel Tigranyan",
        "name": "Arakel",
        "surname": "Tigranyan",
        "dateOfBirth": "15 Mar., 2002",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "977",
        "title": "David Ter-Avetisyan",
        "name": "David",
        "surname": "Ter-Avetisyan",
        "dateOfBirth": "02 Nov., 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "976",
        "title": "Arman Srapionyan",
        "name": "Arman",
        "surname": "Srapionyan",
        "dateOfBirth": "22 July, 1999",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "975",
        "title": "Narek Tchagharyan",
        "name": "Narek",
        "surname": "Tchagharyan",
        "dateOfBirth": "27 Nov., 1991",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "974",
        "title": "Vrezhik Sayilyan",
        "name": "Vrezhik",
        "surname": "Sayilyan",
        "dateOfBirth": "25 Oct., 1988",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "973",
        "title": "Vahram Khachatryan",
        "name": "Vahram",
        "surname": "Khachatryan",
        "dateOfBirth": "05 June, 2002",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "972",
        "title": "Rafael Torgomyan",
        "name": "Rafael",
        "surname": "Torgomyan",
        "dateOfBirth": "27 Aug., 1988",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "971",
        "title": "Karen Vardanyan",
        "name": "Karen",
        "surname": "Vardanyan",
        "dateOfBirth": "16 Sept. , 2001",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1080",
        "title": "Arkadi Hovhannisyan",
        "name": "Arkadi",
        "surname": "Hovhannisyan",
        "dateOfBirth": "02 Dec., 1983",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1079",
        "title": "Albert Aslanyan",
        "name": "Albert",
        "surname": "Aslanyan",
        "dateOfBirth": "23 Jan., 2001",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1078",
        "title": "Grisha Tumanyan",
        "name": "Grisha",
        "surname": "Tumanyan",
        "dateOfBirth": "05 Dec., 1999",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1077",
        "title": "Arkadi Asiryan",
        "name": "Arkadi",
        "surname": "Asiryan",
        "dateOfBirth": "20 Aug., 2000",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1076",
        "title": "Armen Harutyunyan",
        "name": "Armen",
        "surname": "Harutyunyan",
        "dateOfBirth": "28 Nov., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1075",
        "title": "Sipan Zhuroyan",
        "name": "Sipan",
        "surname": "Zhuroyan",
        "dateOfBirth": "29 Mar., 2002",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1074",
        "title": "Henri Arutyunov",
        "name": "Henri",
        "surname": "Arutyunov",
        "dateOfBirth": "25 July, 2000",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1073",
        "title": "Nahapet Tovmasyan",
        "name": "Nahapet",
        "surname": "Tovmasyan",
        "dateOfBirth": "06 Oct., 2000",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1072",
        "title": "Harutyun Hovsepyan",
        "name": "Harutyun",
        "surname": "Hovsepyan",
        "dateOfBirth": "15 Apr., 2001",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1071",
        "title": "Vahe Arakelyan",
        "name": "Vahe",
        "surname": "Arakelyan",
        "dateOfBirth": "26 July, 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1350",
        "title": "Armen Karapetyan",
        "name": "Armen",
        "surname": "Karapetyan",
        "dateOfBirth": "17 Feb., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1349",
        "title": "Alexey Hovhannisyan",
        "name": "Alexey",
        "surname": "Hovhannisyan",
        "dateOfBirth": "02 May, 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1348",
        "title": "Areg Haroyan",
        "name": "Areg",
        "surname": "Haroyan",
        "dateOfBirth": "10 Dec., 2000",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1347",
        "title": "Martin Avtandilyan",
        "name": "Martin",
        "surname": "Avtandilyan",
        "dateOfBirth": "08 Mar., 1980",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1346",
        "title": "Gor Grigoryan",
        "name": "Gor",
        "surname": "Grigoryan",
        "dateOfBirth": "29 Oct., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1345",
        "title": "Gevorg Ghuzanyan",
        "name": "Gevorg",
        "surname": "Ghuzanyan",
        "dateOfBirth": "31 Jan., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1344",
        "title": "Arsen Azizyan",
        "name": "Arsen",
        "surname": "Azizyan",
        "dateOfBirth": "22 July, 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1343",
        "title": "Suren Galoyan",
        "name": "Suren",
        "surname": "Galoyan",
        "dateOfBirth": "11 July, 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1342",
        "title": "Narek Abrahamyan",
        "name": "Narek",
        "surname": "Abrahamyan",
        "dateOfBirth": "07 Feb., 1987",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1341",
        "title": "Vlad Avetisyan",
        "name": "Vlad",
        "surname": "Avetisyan",
        "dateOfBirth": "27 Feb., 1997",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "880",
        "title": "Edgar Hambardzumyan",
        "name": "Edgar",
        "surname": "Hambardzumyan",
        "dateOfBirth": "28 July, 2000",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "879",
        "title": "Erik Sargsyan",
        "name": "Erik",
        "surname": "Sargsyan",
        "dateOfBirth": "17 Mar., 1998",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "878",
        "title": "Gor Sargsyan",
        "name": "Gor",
        "surname": "Sargsyan",
        "dateOfBirth": "17 June, 1999",
        "dateOfDeath": "18Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "877",
        "title": "Hayk Sargsyan",
        "name": "Hayk",
        "surname": "Sargsyan",
        "dateOfBirth": "05 Feb., 1981",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "876",
        "title": "Khachik Avetisyan",
        "name": "Khachik",
        "surname": "Avetisyan",
        "dateOfBirth": "16 Sept. , 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "875",
        "title": "Levon Aslanyan",
        "name": "Levon",
        "surname": "Aslanyan",
        "dateOfBirth": "24 Nov., 1983",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "874",
        "title": "Melikset Arosyan",
        "name": "Melikset",
        "surname": "Arosyan",
        "dateOfBirth": "04 Mar., 1992",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "873",
        "title": "Aren Arakelyan",
        "name": "Aren",
        "surname": "Arakelyan",
        "dateOfBirth": "03 Dec., 2000",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "872",
        "title": "Mamikon Akulyan",
        "name": "Mamikon",
        "surname": "Akulyan",
        "dateOfBirth": "06 Mar., 1993",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "871",
        "title": "Tigran Hakobyan",
        "name": "Tigran",
        "surname": "Hakobyan",
        "dateOfBirth": "14 May, 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1640",
        "title": "Սասուն Սինանյան Գեղամի",
        "name": "Սասուն",
        "surname": "Սինանյան Գեղամի",
        "dateOfBirth": "01 Feb., 2002",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1639",
        "title": "Ռաֆայել Օվսեյան Արմանի",
        "name": "Ռաֆայել",
        "surname": "Օվսեյան Արմանի",
        "dateOfBirth": "17 Sept. , 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1638",
        "title": "Արման Հովակիմյան Արամի",
        "name": "Արման",
        "surname": "Հովակիմյան Արամի",
        "dateOfBirth": "01 Sept. , 2000",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1637",
        "title": "Անուշավան Հովհաննիսյան Գագիկի",
        "name": "Անուշավան",
        "surname": "Հովհաննիսյան Գագիկի",
        "dateOfBirth": "05 Mar., 1999",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1636",
        "title": "Տիգրան Համբարձումյան Արսենի",
        "name": "Տիգրան",
        "surname": "Համբարձումյան Արսենի",
        "dateOfBirth": "20 Sept. , 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1635",
        "title": "Հովհաննես Փափազյան Հարությունի",
        "name": "Հովհաննես",
        "surname": "Փափազյան Հարությունի",
        "dateOfBirth": "28 July, 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1634",
        "title": "Մաքսիմ Հովհաննիսյան Սահակի",
        "name": "Մաքսիմ",
        "surname": "Հովհաննիսյան Սահակի",
        "dateOfBirth": "20 Oct., 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1633",
        "title": "Եփրեմ Դավիթի Հարությունյան",
        "name": "Եփրեմ",
        "surname": "Դավիթի Հարությունյան",
        "dateOfBirth": "04 June, 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1632",
        "title": "Վարդան Հովսեփյան Արթուրի",
        "name": "Վարդան",
        "surname": "Հովսեփյան Արթուրի",
        "dateOfBirth": "14 Dec., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1631",
        "title": "Արմեն  Փարսոյան Արտակի",
        "name": "Արմեն",
        "surname": " Փարսոյան Արտակի",
        "dateOfBirth": "28 Aug., 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1600",
        "title": "Էրիկ Մեսրոպի Աբրահամյան",
        "name": "Էրիկ",
        "surname": "Մեսրոպի Աբրահամյան",
        "dateOfBirth": "02 Nov., 2001",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1599",
        "title": "Պավել  Մանուկյան Նորիկի",
        "name": "Պավել",
        "surname": " Մանուկյան Նորիկի",
        "dateOfBirth": "11 Jan., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1598",
        "title": "Արշալույս Սարգսյան Գագիկի",
        "name": "Արշալույս",
        "surname": "Սարգսյան Գագիկի",
        "dateOfBirth": "27 June, 1994",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1597",
        "title": "Գագիկ Սարգսյան Առաքելի",
        "name": "Գագիկ",
        "surname": "Սարգսյան Առաքելի",
        "dateOfBirth": "12 Oct., 1953",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1596",
        "title": "Ալեն Մարգարյան Լևոնի",
        "name": "Ալեն",
        "surname": "Մարգարյան Լևոնի",
        "dateOfBirth": "11 Aug., 1999",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1595",
        "title": "Վահե Վազգենի Ալեքսանյան",
        "name": "Վահե",
        "surname": "Վազգենի Ալեքսանյան",
        "dateOfBirth": "16 Apr., 2001",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1594",
        "title": "Հրաչյա Ստեփանյան Գագիկի",
        "name": "Հրաչյա",
        "surname": "Ստեփանյան Գագիկի",
        "dateOfBirth": "20 Apr., 1983",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1593",
        "title": "Գևորգ Մարգարյան Արտյոմի",
        "name": "Գևորգ",
        "surname": "Մարգարյան Արտյոմի",
        "dateOfBirth": "27 Feb., 2002",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1592",
        "title": "Էրիկ Ավագյան Վրեժի",
        "name": "Էրիկ",
        "surname": "Ավագյան Վրեժի",
        "dateOfBirth": "27 June, 2001",
        "dateOfDeath": "07Nov.,2021",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1591",
        "title": "Մարտին Պետրոսյան Մխիթար",
        "name": "Մարտին",
        "surname": "Պետրոսյան Մխիթար",
        "dateOfBirth": "19 Nov., 2000",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1510",
        "title": "Gor Hakobyan",
        "name": "Gor",
        "surname": "Hakobyan",
        "dateOfBirth": "21 Mar., 1993",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1509",
        "title": "Sasha Khorshudyan",
        "name": "Sasha",
        "surname": "Khorshudyan",
        "dateOfBirth": "04 Sept. , 2000",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1508",
        "title": "Levon Khurshudyan",
        "name": "Levon",
        "surname": "Khurshudyan",
        "dateOfBirth": "17 Dec., 2001",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1507",
        "title": "Rafik Khekoyan",
        "name": "Rafik",
        "surname": "Khekoyan",
        "dateOfBirth": "01 Nov., 2000",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1506",
        "title": "Nver Manoukyan",
        "name": "Nver",
        "surname": "Manoukyan",
        "dateOfBirth": "26 Aug., 1973",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1505",
        "title": "Hamlet Grigoryan",
        "name": "Hamlet",
        "surname": "Grigoryan",
        "dateOfBirth": "14 Dec., 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1504",
        "title": "Artur Manoukyan",
        "name": "Artur",
        "surname": "Manoukyan",
        "dateOfBirth": "25 Dec., 1994",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1503",
        "title": "Sargis Khazaryan",
        "name": "Sargis",
        "surname": "Khazaryan",
        "dateOfBirth": "03 Dec., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1502",
        "title": "Valeri Andryan",
        "name": "Valeri",
        "surname": "Andryan",
        "dateOfBirth": "13 Oct., 1981",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1501",
        "title": "Vachik Kharibyan",
        "name": "Vachik",
        "surname": "Kharibyan",
        "dateOfBirth": "09 Oct., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1540",
        "title": "Գոռ Հովհաննիսյան Վահագի",
        "name": "Գոռ",
        "surname": "Հովհաննիսյան Վահագի",
        "dateOfBirth": "18 May, 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1539",
        "title": "Հակոբ Հովհաննիսյան Ջոնիկի",
        "name": "Հակոբ",
        "surname": "Հովհաննիսյան Ջոնիկի",
        "dateOfBirth": "17 Nov., 1998",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1538",
        "title": "Արշավիր Ջանիկյան Խաչատուրի",
        "name": "Արշավիր",
        "surname": "Ջանիկյան Խաչատուրի",
        "dateOfBirth": "04 June, 2002",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1537",
        "title": "Աբրահամ Կարապետյան Հովսեփի",
        "name": "Աբրահամ",
        "surname": "Կարապետյան Հովսեփի",
        "dateOfBirth": "09 June, 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1536",
        "title": "Կարեն Կարապետյան Մովսեսի",
        "name": "Կարեն",
        "surname": "Կարապետյան Մովսեսի",
        "dateOfBirth": "07 Jan., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1535",
        "title": "Անդրանիկ Մանասյան Կամոյի",
        "name": "Անդրանիկ",
        "surname": "Մանասյան Կամոյի",
        "dateOfBirth": "16 Feb., 2002",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1534",
        "title": "Ժորա Մարդանյան Արմենի",
        "name": "Ժորա",
        "surname": "Մարդանյան Արմենի",
        "dateOfBirth": "18 Nov., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1533",
        "title": "Էրիկ Մարտիրոսյան Արթուրի",
        "name": "Էրիկ",
        "surname": "Մարտիրոսյան Արթուրի",
        "dateOfBirth": "26 Jan., 2002",
        "dateOfDeath": "27Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1532",
        "title": "Մարգար Մելքոնյան Աշոտի",
        "name": "Մարգար",
        "surname": "Մելքոնյան Աշոտի",
        "dateOfBirth": "02 Aug., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1531",
        "title": "Սարիբեկ Մկրտչյան Հովհաննեսի",
        "name": "Սարիբեկ",
        "surname": "Մկրտչյան Հովհաննեսի",
        "dateOfBirth": "23 Oct., 2000",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1320",
        "title": "Narek Eritsyan",
        "name": "Narek",
        "surname": "Eritsyan",
        "dateOfBirth": "18 Jan., 2002",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1319",
        "title": "Aren Chatinyan",
        "name": "Aren",
        "surname": "Chatinyan",
        "dateOfBirth": "11 Aug., 2000",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1318",
        "title": "Artsrunik Vardazaryan",
        "name": "Artsrunik",
        "surname": "Vardazaryan",
        "dateOfBirth": "02 Dec., 1958",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1317",
        "title": "Davit Babadjanyan",
        "name": "Davit",
        "surname": "Babadjanyan",
        "dateOfBirth": "18 Dec., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1316",
        "title": "Jora Avetisyan",
        "name": "Jora",
        "surname": "Avetisyan",
        "dateOfBirth": "10 Apr., 2001",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1315",
        "title": "Torgom Vardanyan",
        "name": "Torgom",
        "surname": "Vardanyan",
        "dateOfBirth": "04 Apr., 1992",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1314",
        "title": "Davit Aslikyan",
        "name": "Davit",
        "surname": "Aslikyan",
        "dateOfBirth": "12 Dec., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1313",
        "title": "Artak Tovmasyan",
        "name": "Artak",
        "surname": "Tovmasyan",
        "dateOfBirth": "21 Nov., 1994",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1312",
        "title": "Arayik Tigranyan",
        "name": "Arayik",
        "surname": "Tigranyan",
        "dateOfBirth": "17 Apr., 1996",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1311",
        "title": "Gor Soghomonyan",
        "name": "Gor",
        "surname": "Soghomonyan",
        "dateOfBirth": "19 Jan., 1997",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "150",
        "title": "Hrach Galstyan",
        "name": "Hrach",
        "surname": "Galstyan",
        "dateOfBirth": "20 Mar., 1991",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "116 356 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "149",
        "title": "Argishti gaboyan",
        "name": "Argishti",
        "surname": "gaboyan",
        "dateOfBirth": "13 May, 1991",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "257 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "148",
        "title": "Tigran Poghosyan",
        "name": "Tigran",
        "surname": "Poghosyan",
        "dateOfBirth": "21 Feb., 1992",
        "dateOfDeath": "23Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "147",
        "title": "Hayk Toroyan",
        "name": "Hayk",
        "surname": "Toroyan",
        "dateOfBirth": "11 Dec., 1985",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "239 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "146",
        "title": "Gor Kirakosyan",
        "name": "Gor",
        "surname": "Kirakosyan",
        "dateOfBirth": "21 Jan., 1996",
        "dateOfDeath": "05Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "145",
        "title": "Armen Martirosyan",
        "name": "Armen",
        "surname": "Martirosyan",
        "dateOfBirth": "27 Dec., 1996",
        "dateOfDeath": "11May,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "144",
        "title": "Sevak Khachatryan",
        "name": "Sevak",
        "surname": "Khachatryan",
        "dateOfBirth": "01 Feb., 1997",
        "dateOfDeath": "25Sept.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "143",
        "title": "Artak Petrosyan",
        "name": "Artak",
        "surname": "Petrosyan",
        "dateOfBirth": "18 June, 1997",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "142",
        "title": "Karen Aghabekyan",
        "name": "Karen",
        "surname": "Aghabekyan",
        "dateOfBirth": "07 Sept. , 1996",
        "dateOfDeath": "21Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "141",
        "title": "Khoren Harutyunyan",
        "name": "Khoren",
        "surname": "Harutyunyan",
        "dateOfBirth": "26 Mar., 1997",
        "dateOfDeath": "02June,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1520",
        "title": "Անդրանիկ Զաքարյան Սամվելի",
        "name": "Անդրանիկ",
        "surname": "Զաքարյան Սամվելի",
        "dateOfBirth": "25 June, 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1519",
        "title": "Դավիթ Զաքարյան Աշոտի",
        "name": "Դավիթ",
        "surname": "Զաքարյան Աշոտի",
        "dateOfBirth": "30 Sept. , 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1518",
        "title": "Մհեր Զազյան Դավիթի",
        "name": "Մհեր",
        "surname": "Զազյան Դավիթի",
        "dateOfBirth": "13 Nov., 2000",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1517",
        "title": "Վահագն Մովսեսյան Դոնիկի",
        "name": "Վահագն",
        "surname": "Մովսեսյան Դոնիկի",
        "dateOfBirth": "09 Feb., 2002",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1516",
        "title": "Ara Hovakimyan",
        "name": "Ara",
        "surname": "Hovakimyan",
        "dateOfBirth": "16 Dec., 1989",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1515",
        "title": "Karen Grigoryan",
        "name": "Karen",
        "surname": "Grigoryan",
        "dateOfBirth": "02 Jan., 1977",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1514",
        "title": "Artur Gevorgyan",
        "name": "Artur",
        "surname": "Gevorgyan",
        "dateOfBirth": "18 Oct., 1980",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1513",
        "title": "Hrachya Avetisyan",
        "name": "Hrachya",
        "surname": "Avetisyan",
        "dateOfBirth": "17 July, 1993",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1512",
        "title": "Yerem Galstyan",
        "name": "Yerem",
        "surname": "Galstyan",
        "dateOfBirth": "25 Feb., 1979",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1511",
        "title": "Karapet Mkhitaryan",
        "name": "Karapet",
        "surname": "Mkhitaryan",
        "dateOfBirth": "06 Apr., 1991",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "490",
        "title": "Arthur Kirakosyan",
        "name": "Arthur",
        "surname": "Kirakosyan",
        "dateOfBirth": "19 Aug., 1999",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "489",
        "title": "Marlen Harutyunyan",
        "name": "Marlen",
        "surname": "Harutyunyan",
        "dateOfBirth": "07 Mar., 1999",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "488",
        "title": "Radik Hovakimyan",
        "name": "Radik",
        "surname": "Hovakimyan",
        "dateOfBirth": "18 Oct., 2000",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "487",
        "title": "Narek Hovhannisyan",
        "name": "Narek",
        "surname": "Hovhannisyan",
        "dateOfBirth": "18 Mar., 1992",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "486",
        "title": "Bagrat Salamyan",
        "name": "Bagrat",
        "surname": "Salamyan",
        "dateOfBirth": "12 July, 1982",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "485",
        "title": "Yurik Qocharyan",
        "name": "Yurik",
        "surname": "Qocharyan",
        "dateOfBirth": "07 June, 1999",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "484",
        "title": "Pyotr Ivanov",
        "name": "Pyotr",
        "surname": "Ivanov",
        "dateOfBirth": "10 Dec., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "483",
        "title": "Vilhem Geghamyan",
        "name": "Vilhem",
        "surname": "Geghamyan",
        "dateOfBirth": "09 Oct., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "482",
        "title": "Frunze Arshakyan",
        "name": "Frunze",
        "surname": "Arshakyan",
        "dateOfBirth": "16 Mar., 1996",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "481",
        "title": "Ashot Aleksanyan",
        "name": "Ashot",
        "surname": "Aleksanyan",
        "dateOfBirth": "26 June, 2002",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1770",
        "title": "Jirays Kolozyan",
        "name": "Jirays",
        "surname": "Kolozyan",
        "dateOfBirth": "04 Aug., 1961",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1769",
        "title": "Samvel Mnatsakanyan",
        "name": "Samvel",
        "surname": "Mnatsakanyan",
        "dateOfBirth": "10 Sept. , 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1768",
        "title": "Aramayis Muradyan",
        "name": "Aramayis",
        "surname": "Muradyan",
        "dateOfBirth": "15 Oct., 1997",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1767",
        "title": "Gevorg Nazaryan",
        "name": "Gevorg",
        "surname": "Nazaryan",
        "dateOfBirth": "09 Mar., 2002",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1766",
        "title": "Artur Asriyan",
        "name": "Artur",
        "surname": "Asriyan",
        "dateOfBirth": "06 Dec., 1999",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1765",
        "title": "Artur Danielyan",
        "name": "Artur",
        "surname": "Danielyan",
        "dateOfBirth": "30 Aug., 1995",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1764",
        "title": "Artur Galstyan",
        "name": "Artur",
        "surname": "Galstyan",
        "dateOfBirth": "28 Dec., 1964",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1763",
        "title": "Manvel Galstyan",
        "name": "Manvel",
        "surname": "Galstyan",
        "dateOfBirth": "01 Aug., 1964",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1762",
        "title": "Armen Gevorgyan",
        "name": "Armen",
        "surname": "Gevorgyan",
        "dateOfBirth": "10 Mar., 1970",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1761",
        "title": "Narek Martirosyan",
        "name": "Narek",
        "surname": "Martirosyan",
        "dateOfBirth": "18 June, 1993",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1490",
        "title": "Bagrat Kakoyan",
        "name": "Bagrat",
        "surname": "Kakoyan",
        "dateOfBirth": "18 Jan., 1985",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1489",
        "title": "Arsen Ter-Poghosyan",
        "name": "Arsen",
        "surname": "Ter-Poghosyan",
        "dateOfBirth": "15 Sept. , 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1488",
        "title": "Volodya Julhakyan",
        "name": "Volodya",
        "surname": "Julhakyan",
        "dateOfBirth": "26 June, 1989",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1487",
        "title": "Vahram Isayan",
        "name": "Vahram",
        "surname": "Isayan",
        "dateOfBirth": "03 Mar., 1980",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1486",
        "title": "Melik Djanoyan",
        "name": "Melik",
        "surname": "Djanoyan",
        "dateOfBirth": "04 Sept. , 1993",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1485",
        "title": "Vagharshak Grigoryan",
        "name": "Vagharshak",
        "surname": "Grigoryan",
        "dateOfBirth": "20 Jan., 1984",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1484",
        "title": "Gevorg Indjikhulyan",
        "name": "Gevorg",
        "surname": "Indjikhulyan",
        "dateOfBirth": "13 Jan., 1993",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1483",
        "title": "Minas Hovhannisyan",
        "name": "Minas",
        "surname": "Hovhannisyan",
        "dateOfBirth": "05 Nov., 1995",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1482",
        "title": "Hovsep Hovhannisyan",
        "name": "Hovsep",
        "surname": "Hovhannisyan",
        "dateOfBirth": "16 Sept. , 1995",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1481",
        "title": "Artur Hovhannisyan",
        "name": "Artur",
        "surname": "Hovhannisyan",
        "dateOfBirth": "01 Jan., 1983",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "800",
        "title": "Gevorg Javakhyan",
        "name": "Gevorg",
        "surname": "Javakhyan",
        "dateOfBirth": "03 May, 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "799",
        "title": "Artur Sargsyan",
        "name": "Artur",
        "surname": "Sargsyan",
        "dateOfBirth": "21 Apr., 1975",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "798",
        "title": "Gor Botsinyan",
        "name": "Gor",
        "surname": "Botsinyan",
        "dateOfBirth": "11 Sept. , 1991",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "797",
        "title": "Armen Nalbandyan",
        "name": "Armen",
        "surname": "Nalbandyan",
        "dateOfBirth": "21 Feb., 1990",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "796",
        "title": "Grigor Muradyan",
        "name": "Grigor",
        "surname": "Muradyan",
        "dateOfBirth": "20 May, 2002",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "795",
        "title": "Aram Muradyan",
        "name": "Aram",
        "surname": "Muradyan",
        "dateOfBirth": "12 Mar., 2002",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "794",
        "title": "Henrik Mkrtchyan",
        "name": "Henrik",
        "surname": "Mkrtchyan",
        "dateOfBirth": "26 Dec., 1985",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "793",
        "title": "Karen Meliksetyan",
        "name": "Karen",
        "surname": "Meliksetyan",
        "dateOfBirth": "05 Mar., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "792",
        "title": "Artur Mazmanyan",
        "name": "Artur",
        "surname": "Mazmanyan",
        "dateOfBirth": "13 July, 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "791",
        "title": "Eghish Hovhannisyan",
        "name": "Eghish",
        "surname": "Hovhannisyan",
        "dateOfBirth": "05 June, 1989",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1590",
        "title": "Տիրան Պետրոսյան Անդրանիկի",
        "name": "Տիրան",
        "surname": "Պետրոսյան Անդրանիկի",
        "dateOfBirth": "10 Oct., 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1589",
        "title": "Արամ Պողոսյան Արթուրի",
        "name": "Արամ",
        "surname": "Պողոսյան Արթուրի",
        "dateOfBirth": "22 Oct., 1997",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1588",
        "title": "Ռուստամ Պողոսյան Իշխանի",
        "name": "Ռուստամ",
        "surname": "Պողոսյան Իշխանի",
        "dateOfBirth": "05 Nov., 1995",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1587",
        "title": "Սերգեյ Մելքումյան Սեյրանի",
        "name": "Սերգեյ",
        "surname": "Մելքումյան Սեյրանի",
        "dateOfBirth": "20 July, 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1586",
        "title": "Նարեկ Սարգսյան Սամվելի",
        "name": "Նարեկ",
        "surname": "Սարգսյան Սամվելի",
        "dateOfBirth": "12 Sept. , 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1585",
        "title": "Սերգեյ Թևոսյան Ալբերտի",
        "name": "Սերգեյ",
        "surname": "Թևոսյան Ալբերտի",
        "dateOfBirth": "13 Nov., 1995",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1584",
        "title": "Վազգեն Սարգսյան Մկրտչի",
        "name": "Վազգեն",
        "surname": "Սարգսյան Մկրտչի",
        "dateOfBirth": "27 Feb., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1583",
        "title": "Գառնիկ Միրզախանյան Հրաչիկի",
        "name": "Գառնիկ",
        "surname": "Միրզախանյան Հրաչիկի",
        "dateOfBirth": "21 Mar., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1582",
        "title": "Վիրաբ Սերոբյան Բենիկի",
        "name": "Վիրաբ",
        "surname": "Սերոբյան Բենիկի",
        "dateOfBirth": "19 Aug., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1581",
        "title": "Անդրանիկ Ծատրյան Սերժիկի",
        "name": "Անդրանիկ",
        "surname": "Ծատրյան Սերժիկի",
        "dateOfBirth": "30 Sept. , 1987",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "890",
        "title": "Gevorg Mkhitaryan",
        "name": "Gevorg",
        "surname": "Mkhitaryan",
        "dateOfBirth": "04 Oct., 1995",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "889",
        "title": "Khachatur Mikayelyan",
        "name": "Khachatur",
        "surname": "Mikayelyan",
        "dateOfBirth": "15 Sept. , 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "888",
        "title": "Gegham Mesropyan",
        "name": "Gegham",
        "surname": "Mesropyan",
        "dateOfBirth": "23 Feb., 1991",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "887",
        "title": "Sipan Meloyan",
        "name": "Sipan",
        "surname": "Meloyan",
        "dateOfBirth": "16 Jan., 1990",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "886",
        "title": "Arman Arakelyan",
        "name": "Arman",
        "surname": "Arakelyan",
        "dateOfBirth": "14 Jan., 2002",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "885",
        "title": "Harutyun Hovhannisyan",
        "name": "Harutyun",
        "surname": "Hovhannisyan",
        "dateOfBirth": "26 Aug., 2001",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "884",
        "title": "Aghajan Janoyan",
        "name": "Aghajan",
        "surname": "Janoyan",
        "dateOfBirth": "31 Jan., 2002",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "883",
        "title": "Karapet Hovhannisyan",
        "name": "Karapet",
        "surname": "Hovhannisyan",
        "dateOfBirth": "08 May, 1999",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "882",
        "title": "Artur Zhamharyan",
        "name": "Artur",
        "surname": "Zhamharyan",
        "dateOfBirth": "05 July, 1996",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "881",
        "title": "Harutyun Hayrapetyan",
        "name": "Harutyun",
        "surname": "Hayrapetyan",
        "dateOfBirth": "29 Dec., 1994",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "180",
        "title": "Vladimir Narinyan",
        "name": "Vladimir",
        "surname": "Narinyan",
        "dateOfBirth": "17 Feb., 1996",
        "dateOfDeath": "05Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "179",
        "title": "Yuri Paramazyan",
        "name": "Yuri",
        "surname": "Paramazyan",
        "dateOfBirth": "26 June, 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "178",
        "title": "Gegham Mkrtchyan",
        "name": "Gegham",
        "surname": "Mkrtchyan",
        "dateOfBirth": "24 Aug., 1986",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "82 460 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "177",
        "title": "Sargis Gasparyan",
        "name": "Sargis",
        "surname": "Gasparyan",
        "dateOfBirth": "23 Dec., 1995",
        "dateOfDeath": "02May,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "176",
        "title": "Masis Tovmasyan",
        "name": "Masis",
        "surname": "Tovmasyan",
        "dateOfBirth": "15 Mar., 1979",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "50 280 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "175",
        "title": "Marat Danielyan",
        "name": "Marat",
        "surname": "Danielyan",
        "dateOfBirth": "06 Mar., 1984",
        "dateOfDeath": "21Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "50 280 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "174",
        "title": "Aznaur Balayan",
        "name": "Aznaur",
        "surname": "Balayan",
        "dateOfBirth": "20 Oct., 1987",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "173",
        "title": "Eduard Aleksanyan",
        "name": "Eduard",
        "surname": "Aleksanyan",
        "dateOfBirth": "16 Apr., 1955",
        "dateOfDeath": "05Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "172",
        "title": "Sergery Danghyan",
        "name": "Sergery",
        "surname": "Danghyan",
        "dateOfBirth": "06 May, 1958",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "189 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "171",
        "title": "Karo hambardzumyan",
        "name": "Karo",
        "surname": "hambardzumyan",
        "dateOfBirth": "20 Apr., 1960",
        "dateOfDeath": "18Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "220 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "850",
        "title": "Karen Grigoryan",
        "name": "Karen",
        "surname": "Grigoryan",
        "dateOfBirth": "22 May, 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "849",
        "title": "Armenak Sargsyan",
        "name": "Armenak",
        "surname": "Sargsyan",
        "dateOfBirth": "01 Jan., 1998",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "848",
        "title": "Hayk Sahakyan",
        "name": "Hayk",
        "surname": "Sahakyan",
        "dateOfBirth": "21 Dec., 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "847",
        "title": "Hovhannes Marukyan",
        "name": "Hovhannes",
        "surname": "Marukyan",
        "dateOfBirth": "29 June, 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "846",
        "title": "Arman Kocharyan",
        "name": "Arman",
        "surname": "Kocharyan",
        "dateOfBirth": "22 Sept. , 2000",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "845",
        "title": "Roman Ghardashyan",
        "name": "Roman",
        "surname": "Ghardashyan",
        "dateOfBirth": "05 Sept. , 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "844",
        "title": "Suren Gevorgyan",
        "name": "Suren",
        "surname": "Gevorgyan",
        "dateOfBirth": "15 Apr., 1997",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "843",
        "title": "Gevorg Otaryan",
        "name": "Gevorg",
        "surname": "Otaryan",
        "dateOfBirth": "16 Dec., 1999",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "842",
        "title": "Martin Nersisyan",
        "name": "Martin",
        "surname": "Nersisyan",
        "dateOfBirth": "18 Feb., 1991",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "841",
        "title": "Roman Gevorgyan",
        "name": "Roman",
        "surname": "Gevorgyan",
        "dateOfBirth": "21 July, 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1200",
        "title": "Suren Adamyan",
        "name": "Suren",
        "surname": "Adamyan",
        "dateOfBirth": "23 Oct., 1997",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1199",
        "title": "Vahram Karapetyan",
        "name": "Vahram",
        "surname": "Karapetyan",
        "dateOfBirth": "16 Jan., 1975",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1198",
        "title": "Sisak Karapetyan",
        "name": "Sisak",
        "surname": "Karapetyan",
        "dateOfBirth": "27 Jan., 1984",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1197",
        "title": "Mushegh Karapetyan",
        "name": "Mushegh",
        "surname": "Karapetyan",
        "dateOfBirth": "10 Feb., 1960",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1196",
        "title": "Hayk Karapetyan",
        "name": "Hayk",
        "surname": "Karapetyan",
        "dateOfBirth": "03 Jan., 1977",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1195",
        "title": "Andranik Nersisyan",
        "name": "Andranik",
        "surname": "Nersisyan",
        "dateOfBirth": "04 Mar., 1990",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1194",
        "title": "Mkhitar Melkumyan",
        "name": "Mkhitar",
        "surname": "Melkumyan",
        "dateOfBirth": "28 Oct., 1982",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1193",
        "title": "Narek Melikyan",
        "name": "Narek",
        "surname": "Melikyan",
        "dateOfBirth": "06 Apr., 1995",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1192",
        "title": "Vahe Meliksetyan",
        "name": "Vahe",
        "surname": "Meliksetyan",
        "dateOfBirth": "05 Nov., 1988",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1191",
        "title": "Andranik Manukyan",
        "name": "Andranik",
        "surname": "Manukyan",
        "dateOfBirth": "02 Sept. , 1982",
        "dateOfDeath": "21Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "720",
        "title": "Miqael Danielyan",
        "name": "Miqael",
        "surname": "Danielyan",
        "dateOfBirth": "03 Mar., 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "719",
        "title": "Karen Yeremyan",
        "name": "Karen",
        "surname": "Yeremyan",
        "dateOfBirth": "21 Feb., 1984",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "718",
        "title": "Tigran Galstyan",
        "name": "Tigran",
        "surname": "Galstyan",
        "dateOfBirth": "25 Feb., 2000",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "717",
        "title": "Erik Sahakyan",
        "name": "Erik",
        "surname": "Sahakyan",
        "dateOfBirth": "17 Jan., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "716",
        "title": "Garik Sahakyan",
        "name": "Garik",
        "surname": "Sahakyan",
        "dateOfBirth": "20 July, 1983",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "715",
        "title": "Areg Gevorgyan",
        "name": "Areg",
        "surname": "Gevorgyan",
        "dateOfBirth": "05 Oct., 2001",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "714",
        "title": "Suren Sahradyan",
        "name": "Suren",
        "surname": "Sahradyan",
        "dateOfBirth": "01 Jan., 1994",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "713",
        "title": "Henrik Hakobyan",
        "name": "Henrik",
        "surname": "Hakobyan",
        "dateOfBirth": "17 June, 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "712",
        "title": "Nver Hakobyan",
        "name": "Nver",
        "surname": "Hakobyan",
        "dateOfBirth": "08 Nov., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "711",
        "title": "Serob Vardanyan",
        "name": "Serob",
        "surname": "Vardanyan",
        "dateOfBirth": "20 Jan., 1990",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1680",
        "title": "Էդուարդ Չիչյան Արմանի",
        "name": "Էդուարդ",
        "surname": "Չիչյան Արմանի",
        "dateOfBirth": "22 June, 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1679",
        "title": "Լևոն Դանիելյան Գրիգորի",
        "name": "Լևոն",
        "surname": "Դանիելյան Գրիգորի",
        "dateOfBirth": "30 Aug., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1678",
        "title": "Կարեն Մակարյան Սպարտակի",
        "name": "Կարեն",
        "surname": "Մակարյան Սպարտակի",
        "dateOfBirth": "06 July, 1986",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1677",
        "title": "Գագիկ Դավոյան Լևոնի",
        "name": "Գագիկ",
        "surname": "Դավոյան Լևոնի",
        "dateOfBirth": "22 July, 2000",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1676",
        "title": "Գարիկ Դավոյան Գառնիկի",
        "name": "Գարիկ",
        "surname": "Դավոյան Գառնիկի",
        "dateOfBirth": "28 Sept. , 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1675",
        "title": "Գոռ Միրզոյան Գևորգի",
        "name": "Գոռ",
        "surname": "Միրզոյան Գևորգի",
        "dateOfBirth": "11 July, 1990",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1674",
        "title": "Գևորգ Իսունց Ռաֆիկի",
        "name": "Գևորգ",
        "surname": "Իսունց Ռաֆիկի",
        "dateOfBirth": "13 Dec., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1673",
        "title": "Վաչիկ Գաբրիելյան Արմանի",
        "name": "Վաչիկ",
        "surname": "Գաբրիելյան Արմանի",
        "dateOfBirth": "05 Aug., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1672",
        "title": "Արա Մկրտչյան Մակարի",
        "name": "Արա",
        "surname": "Մկրտչյան Մակարի",
        "dateOfBirth": "01 Nov., 1992",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1671",
        "title": "Էրիկ Ջիլավյան Ֆուրմանի",
        "name": "Էրիկ",
        "surname": "Ջիլավյան Ֆուրմանի",
        "dateOfBirth": "21 July, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1110",
        "title": "Valeri Khachatryan",
        "name": "Valeri",
        "surname": "Khachatryan",
        "dateOfBirth": "12 Mar., 2001",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1109",
        "title": "Jivan Khachatryan",
        "name": "Jivan",
        "surname": "Khachatryan",
        "dateOfBirth": "02 Feb., 1992",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1108",
        "title": "David Avetisyan",
        "name": "David",
        "surname": "Avetisyan",
        "dateOfBirth": "16 Feb., 1982",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1107",
        "title": "Emil Khachatryan",
        "name": "Emil",
        "surname": "Khachatryan",
        "dateOfBirth": "08 Jan., 2002",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1106",
        "title": "Tsolak Khachatryan",
        "name": "Tsolak",
        "surname": "Khachatryan",
        "dateOfBirth": "04 Mar., 1990",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1105",
        "title": "Ashot Khachatryan",
        "name": "Ashot",
        "surname": "Khachatryan",
        "dateOfBirth": "27 Sept. , 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1104",
        "title": "Artyom Mikayelyan",
        "name": "Artyom",
        "surname": "Mikayelyan",
        "dateOfBirth": "13 Apr., 1997",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1103",
        "title": "Arsen Khachatryan",
        "name": "Arsen",
        "surname": "Khachatryan",
        "dateOfBirth": "24 Feb., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1102",
        "title": "Karen Janoyan",
        "name": "Karen",
        "surname": "Janoyan",
        "dateOfBirth": "28 Dec., 1978",
        "dateOfDeath": "14Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1101",
        "title": "Poghos Melikyan",
        "name": "Poghos",
        "surname": "Melikyan",
        "dateOfBirth": "06 Jan., 1997",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "470",
        "title": "Shota Zakaryan",
        "name": "Shota",
        "surname": "Zakaryan",
        "dateOfBirth": "17 Oct., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "469",
        "title": "Tigran Gharibyan",
        "name": "Tigran",
        "surname": "Gharibyan",
        "dateOfBirth": "15 Sept. , 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "468",
        "title": "Khachik Khachatryan",
        "name": "Khachik",
        "surname": "Khachatryan",
        "dateOfBirth": "28 Apr., 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "467",
        "title": "Derenik Khachatryan",
        "name": "Derenik",
        "surname": "Khachatryan",
        "dateOfBirth": "14 Aug., 1996",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "466",
        "title": "Grigor Muradkhanyan",
        "name": "Grigor",
        "surname": "Muradkhanyan",
        "dateOfBirth": "09 Dec., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "465",
        "title": "Aram Hovhannisyan",
        "name": "Aram",
        "surname": "Hovhannisyan",
        "dateOfBirth": "09 Oct., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "464",
        "title": "Arthur Harutyunyan",
        "name": "Arthur",
        "surname": "Harutyunyan",
        "dateOfBirth": "21 Nov., 1979",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "463",
        "title": "Erik Hajikyan",
        "name": "Erik",
        "surname": "Hajikyan",
        "dateOfBirth": "03 Jan., 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "462",
        "title": "Shahen Galstyan",
        "name": "Shahen",
        "surname": "Galstyan",
        "dateOfBirth": "20 Aug., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "461",
        "title": "Levon Yurdinyan",
        "name": "Levon",
        "surname": "Yurdinyan",
        "dateOfBirth": "31 Jan., 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1480",
        "title": "Sargis Grigoryan",
        "name": "Sargis",
        "surname": "Grigoryan",
        "dateOfBirth": "15 Sept. , 1985",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1479",
        "title": "Gegham Arakelyan",
        "name": "Gegham",
        "surname": "Arakelyan",
        "dateOfBirth": "19 Mar., 1977",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1478",
        "title": "Hrayr Asryan",
        "name": "Hrayr",
        "surname": "Asryan",
        "dateOfBirth": "14 Apr., 1988",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1477",
        "title": "Emil Shaghoyan",
        "name": "Emil",
        "surname": "Shaghoyan",
        "dateOfBirth": "22 Nov., 2020",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1476",
        "title": "Rashid Sedrakyan",
        "name": "Rashid",
        "surname": "Sedrakyan",
        "dateOfBirth": "18 Aug., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1475",
        "title": "Narek Sahakyan",
        "name": "Narek",
        "surname": "Sahakyan",
        "dateOfBirth": "05 Nov., 2001",
        "dateOfDeath": "26Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1474",
        "title": "Khachik Martirosyan",
        "name": "Khachik",
        "surname": "Martirosyan",
        "dateOfBirth": "28 Oct., 2000",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1473",
        "title": "Suren Muradyan",
        "name": "Suren",
        "surname": "Muradyan",
        "dateOfBirth": "21 Dec., 2000",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1472",
        "title": "Aksen Harutyunyan",
        "name": "Aksen",
        "surname": "Harutyunyan",
        "dateOfBirth": "04 Apr., 1997",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1471",
        "title": "Karen Mnacakanyan",
        "name": "Karen",
        "surname": "Mnacakanyan",
        "dateOfBirth": "10 Feb., 2002",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "740",
        "title": "Sargis Shamamyan",
        "name": "Sargis",
        "surname": "Shamamyan",
        "dateOfBirth": "15 July, 1982",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "739",
        "title": "Artur Ghazaryan",
        "name": "Artur",
        "surname": "Ghazaryan",
        "dateOfBirth": "11 Jan., 1984",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "738",
        "title": "Artak Vardanyan",
        "name": "Artak",
        "surname": "Vardanyan",
        "dateOfBirth": "22 Sept. , 1986",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "737",
        "title": "Hovhannes Khachiyan",
        "name": "Hovhannes",
        "surname": "Khachiyan",
        "dateOfBirth": "03 Oct., 1988",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "736",
        "title": "Marat Khachatryan",
        "name": "Marat",
        "surname": "Khachatryan",
        "dateOfBirth": "09 Dec., 1979",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "735",
        "title": "Sasun Movsisyan",
        "name": "Sasun",
        "surname": "Movsisyan",
        "dateOfBirth": "31 Oct., 2001",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "734",
        "title": "Sipan Soghomonyan",
        "name": "Sipan",
        "surname": "Soghomonyan",
        "dateOfBirth": "26 Nov., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "733",
        "title": "Vahe Sirunyan",
        "name": "Vahe",
        "surname": "Sirunyan",
        "dateOfBirth": "03 Oct., 1982",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "732",
        "title": "Edvard Danielyan",
        "name": "Edvard",
        "surname": "Danielyan",
        "dateOfBirth": "15 July, 1983",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "731",
        "title": "Vrezh Nalbandyan",
        "name": "Vrezh",
        "surname": "Nalbandyan",
        "dateOfBirth": "22 Apr., 1978",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "410",
        "title": "Rafael Azizyan",
        "name": "Rafael",
        "surname": "Azizyan",
        "dateOfBirth": "26 Nov., 1997",
        "dateOfDeath": "29Nov.,2019",
        "Status": "Marked disability",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "5 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "409",
        "title": "Zohrap Sianosyan",
        "name": "Zohrap",
        "surname": "Sianosyan",
        "dateOfBirth": "29 Jan., 1984",
        "dateOfDeath": "10Mar.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "408",
        "title": "Ashot Gaspatyan",
        "name": "Ashot",
        "surname": "Gaspatyan",
        "dateOfBirth": "15 Jan., 1951",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Marked disability",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "62 388 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "407",
        "title": "Artak Ohanyan",
        "name": "Artak",
        "surname": "Ohanyan",
        "dateOfBirth": "26 July, 1982",
        "dateOfDeath": "13Aug.,2019",
        "Status": "Marked disability",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "5 000 000 դր",
        "MonthlySum": "150 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "406",
        "title": "Harout Hakobyan",
        "name": "Harout",
        "surname": "Hakobyan",
        "dateOfBirth": "18 Mar., 1997",
        "dateOfDeath": "25Sept.,2015",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "405",
        "title": "Haykaz Matevosyan",
        "name": "Haykaz",
        "surname": "Matevosyan",
        "dateOfBirth": "09 May, 1980",
        "dateOfDeath": "19Sept.,2018",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "404",
        "title": "Karen Shahinyan",
        "name": "Karen",
        "surname": "Shahinyan",
        "dateOfBirth": "07 May, 1997",
        "dateOfDeath": "25Sept.,2015",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "403",
        "title": "Arthur Nalbandyan",
        "name": "Arthur",
        "surname": "Nalbandyan",
        "dateOfBirth": "28 July, 1980",
        "dateOfDeath": "21Jan.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "402",
        "title": "Norayr Khachatryan",
        "name": "Norayr",
        "surname": "Khachatryan",
        "dateOfBirth": "18 Dec., 1995",
        "dateOfDeath": "25Sept.,2015",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "401",
        "title": "Tigran Arzumanyan",
        "name": "Tigran",
        "surname": "Arzumanyan",
        "dateOfBirth": "06 Nov., 1994",
        "dateOfDeath": "02July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "930",
        "title": "Hrayr Hambardzumyan",
        "name": "Hrayr",
        "surname": "Hambardzumyan",
        "dateOfBirth": "12 Oct., 1999",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "929",
        "title": "Gagik Makaryan",
        "name": "Gagik",
        "surname": "Makaryan",
        "dateOfBirth": "12 Sept. , 1996",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "928",
        "title": "Marat Gevorgyan",
        "name": "Marat",
        "surname": "Gevorgyan",
        "dateOfBirth": "08 May, 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "927",
        "title": "Azat Majaryan",
        "name": "Azat",
        "surname": "Majaryan",
        "dateOfBirth": "30 Oct., 2000",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "926",
        "title": "Andranik Gevorgyan",
        "name": "Andranik",
        "surname": "Gevorgyan",
        "dateOfBirth": "14 Jan., 1961",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "925",
        "title": "Grigor Lemeshko",
        "name": "Grigor",
        "surname": "Lemeshko",
        "dateOfBirth": "23 Sept. , 2001",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "924",
        "title": "Gevorg Gasparyan",
        "name": "Gevorg",
        "surname": "Gasparyan",
        "dateOfBirth": "05 Apr., 1989",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "923",
        "title": "Mkhitar Galeyan",
        "name": "Mkhitar",
        "surname": "Galeyan",
        "dateOfBirth": "17 Aug., 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "922",
        "title": "Nikolay Karapetyan",
        "name": "Nikolay",
        "surname": "Karapetyan",
        "dateOfBirth": "20 Aug., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "921",
        "title": "Zhirayr Balyan",
        "name": "Zhirayr",
        "surname": "Balyan",
        "dateOfBirth": "01 Sept. , 1986",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "680",
        "title": "Eduard Dovlatbekyan",
        "name": "Eduard",
        "surname": "Dovlatbekyan",
        "dateOfBirth": "05 Jan., 1984",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "679",
        "title": "Tigran Satunts",
        "name": "Tigran",
        "surname": "Satunts",
        "dateOfBirth": "23 Mar., 1963",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "678",
        "title": "Khachik Sahakyan",
        "name": "Khachik",
        "surname": "Sahakyan",
        "dateOfBirth": "04 Nov., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "677",
        "title": "Aram Sahakyan",
        "name": "Aram",
        "surname": "Sahakyan",
        "dateOfBirth": "21 Sept. , 2001",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "676",
        "title": "Serj Beglaryan",
        "name": "Serj",
        "surname": "Beglaryan",
        "dateOfBirth": "30 Nov., 2000",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "675",
        "title": "Edgar Matevosyan",
        "name": "Edgar",
        "surname": "Matevosyan",
        "dateOfBirth": "07 June, 1984",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "674",
        "title": "Gegham Safyan",
        "name": "Gegham",
        "surname": "Safyan",
        "dateOfBirth": "13 Apr., 1984",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "673",
        "title": "Aram Martirosyan",
        "name": "Aram",
        "surname": "Martirosyan",
        "dateOfBirth": "07 Sept. , 2000",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "672",
        "title": "Edgar Baghumyan",
        "name": "Edgar",
        "surname": "Baghumyan",
        "dateOfBirth": "10 Mar., 2002",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "671",
        "title": "Hrant Avetisyan",
        "name": "Hrant",
        "surname": "Avetisyan",
        "dateOfBirth": "26 Apr., 1991",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1610",
        "title": "Սերյոժա Խաչատրյան Արամի",
        "name": "Սերյոժա",
        "surname": "Խաչատրյան Արամի",
        "dateOfBirth": "30 Oct., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1609",
        "title": "Գրիգոր Խալաթյան Սևադայի",
        "name": "Գրիգոր",
        "surname": "Խալաթյան Սևադայի",
        "dateOfBirth": "30 Nov., 2001",
        "dateOfDeath": "11Oct.,2000",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1608",
        "title": "Սամո Ղազարյան Կարենի",
        "name": "Սամո",
        "surname": "Ղազարյան Կարենի",
        "dateOfBirth": "15 Oct., 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1607",
        "title": "Ազատ Զաքարյան Նվերի",
        "name": "Ազատ",
        "surname": "Զաքարյան Նվերի",
        "dateOfBirth": "02 Mar., 1998",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1606",
        "title": "Սերգեյ Իսկանդարյան  Վարդանի",
        "name": "Սերգեյ",
        "surname": "Իսկանդարյան  Վարդանի",
        "dateOfBirth": "03 Mar., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1605",
        "title": "Վաչե  Պետրոսյան Արարատի",
        "name": "Վաչե",
        "surname": " Պետրոսյան Արարատի",
        "dateOfBirth": "09 Dec., 1996",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1604",
        "title": "Վազգեն Իսոյան Կարենի",
        "name": "Վազգեն",
        "surname": "Իսոյան Կարենի",
        "dateOfBirth": "28 Nov., 2000",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1603",
        "title": "Արտյոմ Կիրակոսյան Արթուրի",
        "name": "Արտյոմ",
        "surname": "Կիրակոսյան Արթուրի",
        "dateOfBirth": "12 Apr., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1602",
        "title": "Հրաչյա Պողոսյան Վաչիկի",
        "name": "Հրաչյա",
        "surname": "Պողոսյան Վաչիկի",
        "dateOfBirth": "19 Mar., 1995",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "պայմանագրային ենթասպա",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1601",
        "title": "Արտուշ Կնյազյան Եղիշեի",
        "name": "Արտուշ",
        "surname": "Կնյազյան Եղիշեի",
        "dateOfBirth": "20 May, 2001",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "170",
        "title": "Gagik Parsadanyan",
        "name": "Gagik",
        "surname": "Parsadanyan",
        "dateOfBirth": "16 May, 1960",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "147 860 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "169",
        "title": "Eghishe Nikalyan",
        "name": "Eghishe",
        "surname": "Nikalyan",
        "dateOfBirth": "01 July, 1961",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "158 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "168",
        "title": "Karen Davtyan",
        "name": "Karen",
        "surname": "Davtyan",
        "dateOfBirth": "16 Mar., 1981",
        "dateOfDeath": "05Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "81 280 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "167",
        "title": "Robert Abrahamyan",
        "name": "Robert",
        "surname": "Abrahamyan",
        "dateOfBirth": "20 Dec., 1993",
        "dateOfDeath": "10Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "150 640 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "166",
        "title": "Nodarik Margaryan",
        "name": "Nodarik",
        "surname": "Margaryan",
        "dateOfBirth": "03 Jan., 1963",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "158 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "165",
        "title": "Mayis Mirzoyan",
        "name": "Mayis",
        "surname": "Mirzoyan",
        "dateOfBirth": "01 May, 1956",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "127 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "164",
        "title": "Armen Beglaryan",
        "name": "Armen",
        "surname": "Beglaryan",
        "dateOfBirth": "10 Apr., 1973",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "189 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "163",
        "title": "Armen Gasparyan",
        "name": "Armen",
        "surname": "Gasparyan",
        "dateOfBirth": "03 Feb., 1974",
        "dateOfDeath": "06Apr.,2016",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "158 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "162",
        "title": "Hrachya Muradyan",
        "name": "Hrachya",
        "surname": "Muradyan",
        "dateOfBirth": "12 Nov., 1983",
        "dateOfDeath": "08Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "100 000 դր * ",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "161",
        "title": "Aram Ohanyan",
        "name": "Aram",
        "surname": "Ohanyan",
        "dateOfBirth": "04 Oct., 1991",
        "dateOfDeath": "14May,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "109 356 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1800",
        "title": "Sergey Sevlikyan",
        "name": "Sergey",
        "surname": "Sevlikyan",
        "dateOfBirth": "10 Oct., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1799",
        "title": "Hakob Soghoyan",
        "name": "Hakob",
        "surname": "Soghoyan",
        "dateOfBirth": "10 Aug., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1798",
        "title": "Melik Soghomonyan",
        "name": "Melik",
        "surname": "Soghomonyan",
        "dateOfBirth": "15 May, 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1797",
        "title": "Hayk Stepanyan",
        "name": "Hayk",
        "surname": "Stepanyan",
        "dateOfBirth": "28 Mar., 1996",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1796",
        "title": "Tigran Tovmasyan",
        "name": "Tigran",
        "surname": "Tovmasyan",
        "dateOfBirth": "28 June, 1998",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1795",
        "title": "Norik Vardanyan",
        "name": "Norik",
        "surname": "Vardanyan",
        "dateOfBirth": "16 Sept. , 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1794",
        "title": "Ashot Vardapetyan",
        "name": "Ashot",
        "surname": "Vardapetyan",
        "dateOfBirth": "15 Dec., 2000",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1793",
        "title": "Hovhannes Khachatryan",
        "name": "Hovhannes",
        "surname": "Khachatryan",
        "dateOfBirth": "16 Dec., 2000",
        "dateOfDeath": "12Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1792",
        "title": "Arman Khasabyan",
        "name": "Arman",
        "surname": "Khasabyan",
        "dateOfBirth": "02 Jan., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1791",
        "title": "Aram Ghazaryan",
        "name": "Aram",
        "surname": "Ghazaryan",
        "dateOfBirth": "22 Sept. , 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1290",
        "title": "Harutyun Manucharyan",
        "name": "Harutyun",
        "surname": "Manucharyan",
        "dateOfBirth": "13 June, 1988",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1289",
        "title": "Hrant Hovhannisyan",
        "name": "Hrant",
        "surname": "Hovhannisyan",
        "dateOfBirth": "03 Sept. , 1991",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1288",
        "title": "Andranik Hayryan",
        "name": "Andranik",
        "surname": "Hayryan",
        "dateOfBirth": "12 July, 1990",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1287",
        "title": "Tigran Goginyan",
        "name": "Tigran",
        "surname": "Goginyan",
        "dateOfBirth": "23 Aug., 1990",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1286",
        "title": "Karen Gevorgyan",
        "name": "Karen",
        "surname": "Gevorgyan",
        "dateOfBirth": "23 Sept. , 1995",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1285",
        "title": "Vashiktor Yesayan",
        "name": "Vashiktor",
        "surname": "Yesayan",
        "dateOfBirth": "29 Nov., 1986",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1284",
        "title": "Arsen Ahanyan",
        "name": "Arsen",
        "surname": "Ahanyan",
        "dateOfBirth": "07 Aug., 1987",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1283",
        "title": "Vahag Babayan",
        "name": "Vahag",
        "surname": "Babayan",
        "dateOfBirth": "28 Aug., 1990",
        "dateOfDeath": "10Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1282",
        "title": "Kostan Voskanyan",
        "name": "Kostan",
        "surname": "Voskanyan",
        "dateOfBirth": "24 Apr., 1967",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1281",
        "title": "Grisha Vardanyan",
        "name": "Grisha",
        "surname": "Vardanyan",
        "dateOfBirth": "12 July, 1997",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1150",
        "title": "Zhudeks Yazichyan",
        "name": "Zhudeks",
        "surname": "Yazichyan",
        "dateOfBirth": "22 Oct., 1991",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1149",
        "title": "Aghasi Khunoyan",
        "name": "Aghasi",
        "surname": "Khunoyan",
        "dateOfBirth": "19 Mar., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1148",
        "title": "Seryozha Ghukasyan",
        "name": "Seryozha",
        "surname": "Ghukasyan",
        "dateOfBirth": "07 Oct., 1996",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1147",
        "title": "David Ghukasyan",
        "name": "David",
        "surname": "Ghukasyan",
        "dateOfBirth": "03 Nov., 1980",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1146",
        "title": "Melkon Ghazaryan",
        "name": "Melkon",
        "surname": "Ghazaryan",
        "dateOfBirth": "14 Aug., 1982",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1145",
        "title": "Harut Ghazaryan",
        "name": "Harut",
        "surname": "Ghazaryan",
        "dateOfBirth": "14 May, 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1144",
        "title": "Garik Papyan",
        "name": "Garik",
        "surname": "Papyan",
        "dateOfBirth": "27 Jan., 1987",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1143",
        "title": "Azat Ghazaryan",
        "name": "Azat",
        "surname": "Ghazaryan",
        "dateOfBirth": "10 Aug., 2000",
        "dateOfDeath": "26Oct., 2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1142",
        "title": "Ghevond Lazaryan",
        "name": "Ghevond",
        "surname": "Lazaryan",
        "dateOfBirth": "13 Jan., 1972",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1141",
        "title": "Shahen Gharibyan",
        "name": "Shahen",
        "surname": "Gharibyan",
        "dateOfBirth": "01 Jan., 1985",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "580",
        "title": "Armen Karapetyan",
        "name": "Armen",
        "surname": "Karapetyan",
        "dateOfBirth": "17 Jan., 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "579",
        "title": "Aram Ghularyan",
        "name": "Aram",
        "surname": "Ghularyan",
        "dateOfBirth": "12 Aug., 1992",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "578",
        "title": "Sasun Tatikyan",
        "name": "Sasun",
        "surname": "Tatikyan",
        "dateOfBirth": "08 Jan., 1998",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "577",
        "title": "Garik Davtyan",
        "name": "Garik",
        "surname": "Davtyan",
        "dateOfBirth": "07 Nov., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "576",
        "title": "Mushegh Danielyan",
        "name": "Mushegh",
        "surname": "Danielyan",
        "dateOfBirth": "06 Feb., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "575",
        "title": "Armen Baghdasaryan",
        "name": "Armen",
        "surname": "Baghdasaryan",
        "dateOfBirth": "04 July, 1980",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "574",
        "title": "Vahe Bakunts",
        "name": "Vahe",
        "surname": "Bakunts",
        "dateOfBirth": "24 Mar., 2001",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "573",
        "title": "Yuri Hovsepyan",
        "name": "Yuri",
        "surname": "Hovsepyan",
        "dateOfBirth": "20 Sept. , 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "572",
        "title": "Garnik Hovakimyan",
        "name": "Garnik",
        "surname": "Hovakimyan",
        "dateOfBirth": "15 Mar., 2001",
        "dateOfDeath": "04Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "571",
        "title": "Khachatur Hayrapetyan",
        "name": "Khachatur",
        "surname": "Hayrapetyan",
        "dateOfBirth": "26 Apr., 1989",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "100",
        "title": "Karen Hovhannisyan",
        "name": "Karen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "20 Feb., 1992",
        "dateOfDeath": "12Aug.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "99",
        "title": "Vardan Vardanyan",
        "name": "Vardan",
        "surname": "Vardanyan",
        "dateOfBirth": "26 Mar., 1994",
        "dateOfDeath": "11Dec.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "98",
        "title": "Vahe Vanoyan",
        "name": "Vahe",
        "surname": "Vanoyan",
        "dateOfBirth": "08 Aug., 1995",
        "dateOfDeath": "12Nov.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "97",
        "title": "Arman Udumyan",
        "name": "Arman",
        "surname": "Udumyan",
        "dateOfBirth": "24 July, 1980",
        "dateOfDeath": "21Jan.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "100 000 դր * ",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "96",
        "title": "Miqayel Torosyan",
        "name": "Miqayel",
        "surname": "Torosyan",
        "dateOfBirth": "15 Nov., 1996",
        "dateOfDeath": "12Nov.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "95",
        "title": "Tigran Simonyan",
        "name": "Tigran",
        "surname": "Simonyan",
        "dateOfBirth": "10 Sept. , 1991",
        "dateOfDeath": "10Feb.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "270 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "94",
        "title": "Artak Sargsyan",
        "name": "Artak",
        "surname": "Sargsyan",
        "dateOfBirth": "27 July, 1993",
        "dateOfDeath": "23Jan.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "93",
        "title": "Hayk Qeshishoghlyan",
        "name": "Hayk",
        "surname": "Qeshishoghlyan",
        "dateOfBirth": "10 Apr., 1972",
        "dateOfDeath": "04Apr.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "116 856 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "92",
        "title": "Suren Pilosyan",
        "name": "Suren",
        "surname": "Pilosyan",
        "dateOfBirth": "19 Mar., 1995",
        "dateOfDeath": "20Jan.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "91",
        "title": "Arman Osipyan",
        "name": "Arman",
        "surname": "Osipyan",
        "dateOfBirth": "16 Mar., 1995",
        "dateOfDeath": "14Feb.,2015",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "130",
        "title": "Gevorg Harutyunyan",
        "name": "Gevorg",
        "surname": "Harutyunyan",
        "dateOfBirth": "08 Nov., 1996",
        "dateOfDeath": "02Aug.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "129",
        "title": "Adam Sahakyan",
        "name": "Adam",
        "surname": "Sahakyan",
        "dateOfBirth": "19 Aug., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "128",
        "title": "Vahe Zaqaryan",
        "name": "Vahe",
        "surname": "Zaqaryan",
        "dateOfBirth": "12 Oct., 1995",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "127",
        "title": "Hovhannes Harutyunyan",
        "name": "Hovhannes",
        "surname": "Harutyunyan",
        "dateOfBirth": "27 May, 1997",
        "dateOfDeath": "17Mar.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "126",
        "title": "Vardan Araqelyan",
        "name": "Vardan",
        "surname": "Araqelyan",
        "dateOfBirth": "17 Mar., 1995",
        "dateOfDeath": "13Nov.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "125",
        "title": "Norik gasparyan",
        "name": "Norik",
        "surname": "gasparyan",
        "dateOfBirth": "10 Feb., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "124",
        "title": "Rafik Hakobyan",
        "name": "Rafik",
        "surname": "Hakobyan",
        "dateOfBirth": "19 Jan., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "123",
        "title": "Baris Ozmanyan",
        "name": "Baris",
        "surname": "Ozmanyan",
        "dateOfBirth": "04 Dec., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "122",
        "title": "David Hayrapetyan",
        "name": "David",
        "surname": "Hayrapetyan",
        "dateOfBirth": "18 May, 1997",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "121",
        "title": "Arman Ghandilyan",
        "name": "Arman",
        "surname": "Ghandilyan",
        "dateOfBirth": "10 Jan., 1997",
        "dateOfDeath": "07Sept.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "950",
        "title": "Aren Mhrabyan",
        "name": "Aren",
        "surname": "Mhrabyan",
        "dateOfBirth": "02 Sept. , 1997",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "949",
        "title": "Khachik Harutyunyan",
        "name": "Khachik",
        "surname": "Harutyunyan",
        "dateOfBirth": "14 Feb., 1994",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "948",
        "title": "Harutyun Hakobyan",
        "name": "Harutyun",
        "surname": "Hakobyan",
        "dateOfBirth": "10 June, 1998",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "947",
        "title": "Gevorg Gasparyan",
        "name": "Gevorg",
        "surname": "Gasparyan",
        "dateOfBirth": "23 Aug., 1985",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "946",
        "title": "Gevorg Galoyan",
        "name": "Gevorg",
        "surname": "Galoyan",
        "dateOfBirth": "16 Jan., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "945",
        "title": "Arman Davtyan",
        "name": "Arman",
        "surname": "Davtyan",
        "dateOfBirth": "12 Jan., 2002",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "944",
        "title": "Tatul Garsevanyan",
        "name": "Tatul",
        "surname": "Garsevanyan",
        "dateOfBirth": "04 Aug., 2001",
        "dateOfDeath": "05Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "943",
        "title": "Aram Galstyan",
        "name": "Aram",
        "surname": "Galstyan",
        "dateOfBirth": "15 Sept. , 2000",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "942",
        "title": "Arman Badoyan",
        "name": "Arman",
        "surname": "Badoyan",
        "dateOfBirth": "21 May, 2002",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "941",
        "title": "Gevorg Azaryan",
        "name": "Gevorg",
        "surname": "Azaryan",
        "dateOfBirth": "07 Dec., 1989",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1550",
        "title": "Շիրազ Սանասարյան Զորիկի",
        "name": "Շիրազ",
        "surname": "Սանասարյան Զորիկի",
        "dateOfBirth": "26 Oct., 1998",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "սպայական կազմ",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1549",
        "title": "Գառնիկ Եգանյան Ֆելիքսի",
        "name": "Գառնիկ",
        "surname": "Եգանյան Ֆելիքսի",
        "dateOfBirth": "27 July, 2001",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1548",
        "title": "Էրիկ Գալստյան Էմիլի",
        "name": "Էրիկ",
        "surname": "Գալստյան Էմիլի",
        "dateOfBirth": "15 Nov., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1547",
        "title": "Խաչատուր  Խաչատրյան Հարութի",
        "name": "Խաչատուր",
        "surname": " Խաչատրյան Հարութի",
        "dateOfBirth": "16 Dec., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1546",
        "title": "Աղասի Գևորգյան Հակոբի",
        "name": "Աղասի",
        "surname": "Գևորգյան Հակոբի",
        "dateOfBirth": "25 Nov., 2000",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1545",
        "title": "Ռուբեն Գևորգյան Արամի",
        "name": "Ռուբեն",
        "surname": "Գևորգյան Արամի",
        "dateOfBirth": "02 May, 1998",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1544",
        "title": "Տիգրան Գևորգյան Հրաչիկի",
        "name": "Տիգրան",
        "surname": "Գևորգյան Հրաչիկի",
        "dateOfBirth": "18 Oct., 2000",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1543",
        "title": "Դավիթ Գրիգորյան Լյովայի",
        "name": "Դավիթ",
        "surname": "Գրիգորյան Լյովայի",
        "dateOfBirth": "17 Nov., 2000",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1542",
        "title": "Իլյա Հակոբյան Խաչատուրի",
        "name": "Իլյա",
        "surname": "Հակոբյան Խաչատուրի",
        "dateOfBirth": "12 Jan., 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1541",
        "title": "Արթուր Հովհաննիսյան Արայիկի",
        "name": "Արթուր",
        "surname": "Հովհաննիսյան Արայիկի",
        "dateOfBirth": "03 July, 2001",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "պարտադիր շարքային",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1090",
        "title": "Hamlet Harutyunyan",
        "name": "Hamlet",
        "surname": "Harutyunyan",
        "dateOfBirth": "01 Jan., 2001",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1089",
        "title": "Sedrak Vardanyan",
        "name": "Sedrak",
        "surname": "Vardanyan",
        "dateOfBirth": "18 Oct., 1990",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1088",
        "title": "Vrezh Avetisyan",
        "name": "Vrezh",
        "surname": "Avetisyan",
        "dateOfBirth": "17 Feb., 2001",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1087",
        "title": "Vazgen Aslanyan",
        "name": "Vazgen",
        "surname": "Aslanyan",
        "dateOfBirth": "28 Apr., 2001",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1086",
        "title": "Garik Avetisyan",
        "name": "Garik",
        "surname": "Avetisyan",
        "dateOfBirth": "02 Apr., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1085",
        "title": "Avetis Avetisyan",
        "name": "Avetis",
        "surname": "Avetisyan",
        "dateOfBirth": "27 Dec., 1979",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1084",
        "title": "Avetis Vardanyan",
        "name": "Avetis",
        "surname": "Vardanyan",
        "dateOfBirth": "25 Nov., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1083",
        "title": "Karen Avagyan",
        "name": "Karen",
        "surname": "Avagyan",
        "dateOfBirth": "23 Aug., 2001",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1082",
        "title": "Levon Levonyan",
        "name": "Levon",
        "surname": "Levonyan",
        "dateOfBirth": "13 Aug., 2000",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1081",
        "title": "Arman Atabekyan",
        "name": "Arman",
        "surname": "Atabekyan",
        "dateOfBirth": "28 Aug., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "790",
        "title": "Arsen Hakhverdyan",
        "name": "Arsen",
        "surname": "Hakhverdyan",
        "dateOfBirth": "13 Mar., 2001",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "789",
        "title": "Yura Harutyunyan",
        "name": "Yura",
        "surname": "Harutyunyan",
        "dateOfBirth": "15 Apr., 2001",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "788",
        "title": "Mihran Harutyunyan",
        "name": "Mihran",
        "surname": "Harutyunyan",
        "dateOfBirth": "07 Oct., 2001",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "787",
        "title": "David Kirakosyan",
        "name": "David",
        "surname": "Kirakosyan",
        "dateOfBirth": "25 Jan., 1989",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "786",
        "title": "David Hovhannisyan",
        "name": "David",
        "surname": "Hovhannisyan",
        "dateOfBirth": "01 Aug., 1995",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "785",
        "title": "Serob Isoyan",
        "name": "Serob",
        "surname": "Isoyan",
        "dateOfBirth": "07 Mar., 1992",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "784",
        "title": "Levon Manoyan",
        "name": "Levon",
        "surname": "Manoyan",
        "dateOfBirth": "09 Oct., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "783",
        "title": "Azat Aloyan",
        "name": "Azat",
        "surname": "Aloyan",
        "dateOfBirth": "14 June, 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "782",
        "title": "Kolya Aristakesyan",
        "name": "Kolya",
        "surname": "Aristakesyan",
        "dateOfBirth": "22 Nov., 1990",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "781",
        "title": "Elfik Avetisyan",
        "name": "Elfik",
        "surname": "Avetisyan",
        "dateOfBirth": "12 Sept. , 2000",
        "dateOfDeath": "30Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "990",
        "title": "Artak Avagimyan",
        "name": "Artak",
        "surname": "Avagimyan",
        "dateOfBirth": "01 June, 1980",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "989",
        "title": "Artur Aghajanyan",
        "name": "Artur",
        "surname": "Aghajanyan",
        "dateOfBirth": "25 Aug., 1984",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "988",
        "title": "Levon Martirosyan",
        "name": "Levon",
        "surname": "Martirosyan",
        "dateOfBirth": "20 July, 1991",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "987",
        "title": "Ashot Margaryan",
        "name": "Ashot",
        "surname": "Margaryan",
        "dateOfBirth": "12 Apr., 1963",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "986",
        "title": "Ararat Manukyan",
        "name": "Ararat",
        "surname": "Manukyan",
        "dateOfBirth": "27 Mar., 1983",
        "dateOfDeath": "02Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "985",
        "title": "Sedrak Levonyan",
        "name": "Sedrak",
        "surname": "Levonyan",
        "dateOfBirth": "11 Apr., 1976",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "984",
        "title": "Tigran Zakaryan",
        "name": "Tigran",
        "surname": "Zakaryan",
        "dateOfBirth": "07 Oct., 2000",
        "dateOfDeath": "04Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "983",
        "title": "Ashot Ghazaryan",
        "name": "Ashot",
        "surname": "Ghazaryan",
        "dateOfBirth": "18 Jan., 1974",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "982",
        "title": "Rudik Karapetyan",
        "name": "Rudik",
        "surname": "Karapetyan",
        "dateOfBirth": "13 Apr., 2001",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "981",
        "title": "Artak Ghazaryan",
        "name": "Artak",
        "surname": "Ghazaryan",
        "dateOfBirth": "06 July, 1982",
        "dateOfDeath": "03Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1160",
        "title": "Razmik Gevorgyan",
        "name": "Razmik",
        "surname": "Gevorgyan",
        "dateOfBirth": "17 Dec., 1984",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1159",
        "title": "Yuri Gevorgyan",
        "name": "Yuri",
        "surname": "Gevorgyan",
        "dateOfBirth": "09 July, 1985",
        "dateOfDeath": "17Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1158",
        "title": "Gor Gagreyan",
        "name": "Gor",
        "surname": "Gagreyan",
        "dateOfBirth": "18 June, 1996",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1157",
        "title": "Gnel Fadinyan",
        "name": "Gnel",
        "surname": "Fadinyan",
        "dateOfBirth": "01 Sept. , 1985",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1156",
        "title": "Karen Yeghoyan",
        "name": "Karen",
        "surname": "Yeghoyan",
        "dateOfBirth": "15 Jan., 1989",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1155",
        "title": "Hrachya Erndjakyan",
        "name": "Hrachya",
        "surname": "Erndjakyan",
        "dateOfBirth": "07 Dec., 1977",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1154",
        "title": "Hovhannes Edigaryan",
        "name": "Hovhannes",
        "surname": "Edigaryan",
        "dateOfBirth": "12 Jan., 1974",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1153",
        "title": "Vyacheslav Musaelyan",
        "name": "Vyacheslav",
        "surname": "Musaelyan",
        "dateOfBirth": "23 Oct., 1988",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1152",
        "title": "Vachagan Muradyan",
        "name": "Vachagan",
        "surname": "Muradyan",
        "dateOfBirth": "11 Aug., 1999",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1151",
        "title": "Sedrak Zakaryan",
        "name": "Sedrak",
        "surname": "Zakaryan",
        "dateOfBirth": "25 Jan., 1980",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "810",
        "title": "Vahe Adyan",
        "name": "Vahe",
        "surname": "Adyan",
        "dateOfBirth": "11 Dec., 2001",
        "dateOfDeath": "31Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "809",
        "title": "Gagik Abrahamyan",
        "name": "Gagik",
        "surname": "Abrahamyan",
        "dateOfBirth": "12 Jan., 2001",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "808",
        "title": "Hamlet Mekhakyan",
        "name": "Hamlet",
        "surname": "Mekhakyan",
        "dateOfBirth": "20 Jan., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "807",
        "title": "Samvel Manasyan",
        "name": "Samvel",
        "surname": "Manasyan",
        "dateOfBirth": "13 Mar., 2001",
        "dateOfDeath": "17Oct.,2002",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "806",
        "title": "Arsen Kyureghyan",
        "name": "Arsen",
        "surname": "Kyureghyan",
        "dateOfBirth": "04 July, 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "805",
        "title": "Norayr Konyan",
        "name": "Norayr",
        "surname": "Konyan",
        "dateOfBirth": "08 Sept. , 2000",
        "dateOfDeath": "05Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "804",
        "title": "Mher Muradkhanyan",
        "name": "Mher",
        "surname": "Muradkhanyan",
        "dateOfBirth": "20 Nov., 2001",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "803",
        "title": "Misha Dunamalyan",
        "name": "Misha",
        "surname": "Dunamalyan",
        "dateOfBirth": "29 Jan., 2000",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "802",
        "title": "Ara Madoyan",
        "name": "Ara",
        "surname": "Madoyan",
        "dateOfBirth": "28 Feb., 1977",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "801",
        "title": "Samvel Karapetyan",
        "name": "Samvel",
        "surname": "Karapetyan",
        "dateOfBirth": "11 July, 2000",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "520",
        "title": "Aghasi Sahakyan",
        "name": "Aghasi",
        "surname": "Sahakyan",
        "dateOfBirth": "15 Oct., 2000",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "519",
        "title": "Harutyun Rafayelyan",
        "name": "Harutyun",
        "surname": "Rafayelyan",
        "dateOfBirth": "07 July, 1992",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "518",
        "title": "David Poghosyan",
        "name": "David",
        "surname": "Poghosyan",
        "dateOfBirth": "09 Nov., 2001",
        "dateOfDeath": "22Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "517",
        "title": "Hmayak Pashyan",
        "name": "Hmayak",
        "surname": "Pashyan",
        "dateOfBirth": "13 Jan., 1983",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "516",
        "title": "Edgar Balyan",
        "name": "Edgar",
        "surname": "Balyan",
        "dateOfBirth": "30 June, 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "515",
        "title": "Aram Balyan",
        "name": "Aram",
        "surname": "Balyan",
        "dateOfBirth": "15 July, 1992",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "514",
        "title": "Goris Varagyan",
        "name": "Goris",
        "surname": "Varagyan",
        "dateOfBirth": "14 Feb., 2001",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "513",
        "title": "Sevak Zurabyan",
        "name": "Sevak",
        "surname": "Zurabyan",
        "dateOfBirth": "21 Apr., 1998",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "512",
        "title": "Karen Simonyan",
        "name": "Karen",
        "surname": "Simonyan",
        "dateOfBirth": "28 Oct., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "511",
        "title": "Rubik Sargsyan",
        "name": "Rubik",
        "surname": "Sargsyan",
        "dateOfBirth": "11 Aug., 2000",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1240",
        "title": "Taron Sargsyan",
        "name": "Taron",
        "surname": "Sargsyan",
        "dateOfBirth": "16 Apr., 1994",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1239",
        "title": "Semyon Avanesyan",
        "name": "Semyon",
        "surname": "Avanesyan",
        "dateOfBirth": "10 Sept. , 1984",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1238",
        "title": "Artak Atanesyan",
        "name": "Artak",
        "surname": "Atanesyan",
        "dateOfBirth": "03 Jan., 1985",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1237",
        "title": "Armen Sahakyan",
        "name": "Armen",
        "surname": "Sahakyan",
        "dateOfBirth": "31 Mar., 1994",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1236",
        "title": "Serob Asryan",
        "name": "Serob",
        "surname": "Asryan",
        "dateOfBirth": "01 Sept. , 1998",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1235",
        "title": "Bagrat Ardinyan",
        "name": "Bagrat",
        "surname": "Ardinyan",
        "dateOfBirth": "24 Nov., 1989",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1234",
        "title": "Armen Arakelyan",
        "name": "Armen",
        "surname": "Arakelyan",
        "dateOfBirth": "25 Sept. , 1996",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1233",
        "title": "Adnranik Arakelyan",
        "name": "Adnranik",
        "surname": "Arakelyan",
        "dateOfBirth": "01 May, 1969",
        "dateOfDeath": "08Nov.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1232",
        "title": "Sashik Rustamyan",
        "name": "Sashik",
        "surname": "Rustamyan",
        "dateOfBirth": "21 Apr., 1994",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1231",
        "title": "Derenik Kirakosyan",
        "name": "Derenik",
        "surname": "Kirakosyan",
        "dateOfBirth": "05 Apr., 1997",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "530",
        "title": "Styopa Barsumyan",
        "name": "Styopa",
        "surname": "Barsumyan",
        "dateOfBirth": "19 July, 2001",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "529",
        "title": "Gevorg Khachatryan",
        "name": "Gevorg",
        "surname": "Khachatryan",
        "dateOfBirth": "14 Sept. , 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "528",
        "title": "Gor Tumanyan",
        "name": "Gor",
        "surname": "Tumanyan",
        "dateOfBirth": "28 Aug., 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "527",
        "title": "Haroutyun Stepanyan",
        "name": "Haroutyun",
        "surname": "Stepanyan",
        "dateOfBirth": "04 Apr., 1974",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "526",
        "title": "Haroutyun Haroutyunyan",
        "name": "Haroutyun",
        "surname": "Haroutyunyan",
        "dateOfBirth": "21 Oct., 2020",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "525",
        "title": "Rafik Hakobyan",
        "name": "Rafik",
        "surname": "Hakobyan",
        "dateOfBirth": "10 Dec., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "524",
        "title": "Levon Sevoyan",
        "name": "Levon",
        "surname": "Sevoyan",
        "dateOfBirth": "14 May, 1994",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "523",
        "title": "Mayis Sargsyan",
        "name": "Mayis",
        "surname": "Sargsyan",
        "dateOfBirth": "24 Aug., 1991",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "522",
        "title": "David Ohanyan",
        "name": "David",
        "surname": "Ohanyan",
        "dateOfBirth": "11 Oct., 2000",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "521",
        "title": "Meroujan Sahakyan",
        "name": "Meroujan",
        "surname": "Sahakyan",
        "dateOfBirth": "17 Mar., 2001",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Sergeant",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "110",
        "title": "Aramayis Voskanyan",
        "name": "Aramayis",
        "surname": "Voskanyan",
        "dateOfBirth": "26 Jan., 1996",
        "dateOfDeath": "09Jan.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "108 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "109",
        "title": "Misha Aghadjanyan",
        "name": "Misha",
        "surname": "Aghadjanyan",
        "dateOfBirth": "19 July, 1996",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "139 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "108",
        "title": "Azat Simonyan",
        "name": "Azat",
        "surname": "Simonyan",
        "dateOfBirth": "04 Dec., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "107",
        "title": "Artur Gevorgyan",
        "name": "Artur",
        "surname": "Gevorgyan",
        "dateOfBirth": "24 June, 1997",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "106",
        "title": "Aghsai Asatryan",
        "name": "Aghsai",
        "surname": "Asatryan",
        "dateOfBirth": "23 Feb., 1996",
        "dateOfDeath": "03Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "105",
        "title": "Karen Nersisyan",
        "name": "Karen",
        "surname": "Nersisyan",
        "dateOfBirth": "08 Apr., 1997",
        "dateOfDeath": "04Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "104",
        "title": "Gurgen Ayvazyan",
        "name": "Gurgen",
        "surname": "Ayvazyan",
        "dateOfBirth": "19 Sept. , 1996",
        "dateOfDeath": "26Oct.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "103",
        "title": "Jora Yesayan",
        "name": "Jora",
        "surname": "Yesayan",
        "dateOfBirth": "09 Aug., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "102",
        "title": "Qyaram Sloyan",
        "name": "Qyaram",
        "surname": "Sloyan",
        "dateOfBirth": "27 Apr., 1996",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "101",
        "title": "Tigran Berakchyan",
        "name": "Tigran",
        "surname": "Berakchyan",
        "dateOfBirth": "22 Mar., 1997",
        "dateOfDeath": "02Apr.,2016",
        "Status": "Deceased",
        "rank": "Solider",
        "BankContact": "",
        "LumpSum": "0 դր",
        "MonthlySum": "170 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "920",
        "title": "Armenak Babayan",
        "name": "Armenak",
        "surname": "Babayan",
        "dateOfBirth": "17 Nov., 2000",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "919",
        "title": "Karen Avetisyan",
        "name": "Karen",
        "surname": "Avetisyan",
        "dateOfBirth": "12 Aug., 2000",
        "dateOfDeath": "19Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "918",
        "title": "Levon Avetisyan",
        "name": "Levon",
        "surname": "Avetisyan",
        "dateOfBirth": "19 Oct., 1995",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "917",
        "title": "Yervand Avetisyan",
        "name": "Yervand",
        "surname": "Avetisyan",
        "dateOfBirth": "23 July, 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "916",
        "title": "Yura Asryan",
        "name": "Yura",
        "surname": "Asryan",
        "dateOfBirth": "06 June, 2001",
        "dateOfDeath": "07Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "915",
        "title": "Narek Asryan",
        "name": "Narek",
        "surname": "Asryan",
        "dateOfBirth": "16 Sept. , 2001",
        "dateOfDeath": "11Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "914",
        "title": "Aghvan Aslamazyan",
        "name": "Aghvan",
        "surname": "Aslamazyan",
        "dateOfBirth": "27 Sept. , 2000",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "913",
        "title": "Karen Asatryan",
        "name": "Karen",
        "surname": "Asatryan",
        "dateOfBirth": "15 Nov., 1997",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "912",
        "title": "Samvel Amiryan",
        "name": "Samvel",
        "surname": "Amiryan",
        "dateOfBirth": "07 June, 1996",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "911",
        "title": "Arsen Aleksanyan",
        "name": "Arsen",
        "surname": "Aleksanyan",
        "dateOfBirth": "08 Nov., 1996",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "870",
        "title": "Yurik Sargsyan",
        "name": "Yurik",
        "surname": "Sargsyan",
        "dateOfBirth": "26 Mar., 1984",
        "dateOfDeath": "06Nov.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "869",
        "title": "Harutyun Saroyan",
        "name": "Harutyun",
        "surname": "Saroyan",
        "dateOfBirth": "15 Jan., 1993",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "868",
        "title": "Alen Stepanyan",
        "name": "Alen",
        "surname": "Stepanyan",
        "dateOfBirth": "02 Feb., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "867",
        "title": "David Hakobyan",
        "name": "David",
        "surname": "Hakobyan",
        "dateOfBirth": "27 Oct., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "866",
        "title": "Edgar Khachatryan",
        "name": "Edgar",
        "surname": "Khachatryan",
        "dateOfBirth": "24 June, 1986",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "865",
        "title": "Artur Hakobyan",
        "name": "Artur",
        "surname": "Hakobyan",
        "dateOfBirth": "15 June, 2002",
        "dateOfDeath": "13Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "864",
        "title": "Samvel Khachatryan",
        "name": "Samvel",
        "surname": "Khachatryan",
        "dateOfBirth": "28 Feb., 1999",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "863",
        "title": "Vrezh Martirosyan",
        "name": "Vrezh",
        "surname": "Martirosyan",
        "dateOfBirth": "23 Sept. , 2000",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "862",
        "title": "Narek Khachatryan",
        "name": "Narek",
        "surname": "Khachatryan",
        "dateOfBirth": "08 July, 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "861",
        "title": "Nver Gulinyan",
        "name": "Nver",
        "surname": "Gulinyan",
        "dateOfBirth": "12 Apr., 1996",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1050",
        "title": "Ara Shahbazyan",
        "name": "Ara",
        "surname": "Shahbazyan",
        "dateOfBirth": "25 July, 1982",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1049",
        "title": "Karen Serobyan",
        "name": "Karen",
        "surname": "Serobyan",
        "dateOfBirth": "30 June, 1981",
        "dateOfDeath": "30Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1048",
        "title": "Sargis Saribekyan",
        "name": "Sargis",
        "surname": "Saribekyan",
        "dateOfBirth": "29 Dec., 1989",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1047",
        "title": "Vahagn Sargsyan",
        "name": "Vahagn",
        "surname": "Sargsyan",
        "dateOfBirth": "15 Oct., 1975",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1046",
        "title": "Levon Sargsyan",
        "name": "Levon",
        "surname": "Sargsyan",
        "dateOfBirth": "11 Aug., 1986",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1045",
        "title": "Hamlet Sargsyan",
        "name": "Hamlet",
        "surname": "Sargsyan",
        "dateOfBirth": "05 Mar., 1994",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1044",
        "title": "Artyom Purtoyan",
        "name": "Artyom",
        "surname": "Purtoyan",
        "dateOfBirth": "26 May, 2001",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1043",
        "title": "Sos Pandunts",
        "name": "Sos",
        "surname": "Pandunts",
        "dateOfBirth": "30 Dec., 2001",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1042",
        "title": "Arayik Mkrtchyan",
        "name": "Arayik",
        "surname": "Mkrtchyan",
        "dateOfBirth": "10 July, 1967",
        "dateOfDeath": "06Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1041",
        "title": "Aram Grigoryan",
        "name": "Aram",
        "surname": "Grigoryan",
        "dateOfBirth": "05 Dec., 2002",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1410",
        "title": "Dmitri Novikov",
        "name": "Dmitri",
        "surname": "Novikov",
        "dateOfBirth": "24 Oct., 2000",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1409",
        "title": "Volodyan Mkhitaryan",
        "name": "Volodyan",
        "surname": "Mkhitaryan",
        "dateOfBirth": "31 Oct., 2001",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1408",
        "title": "Armen Minasyan",
        "name": "Armen",
        "surname": "Minasyan",
        "dateOfBirth": "06 July, 2001",
        "dateOfDeath": "29Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1407",
        "title": "Erik Movsisyan",
        "name": "Erik",
        "surname": "Movsisyan",
        "dateOfBirth": "19 Apr., 2001",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1406",
        "title": "Razmik Makhmuryan",
        "name": "Razmik",
        "surname": "Makhmuryan",
        "dateOfBirth": "16 May, 2002",
        "dateOfDeath": "09Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1405",
        "title": "Alex Martirosyan",
        "name": "Alex",
        "surname": "Martirosyan",
        "dateOfBirth": "17 Mar., 2001",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1404",
        "title": "David Margaryan",
        "name": "David",
        "surname": "Margaryan",
        "dateOfBirth": "16 Apr., 2002",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1403",
        "title": "Andranik Margaryan",
        "name": "Andranik",
        "surname": "Margaryan",
        "dateOfBirth": "07 Mar., 2001",
        "dateOfDeath": "27Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1402",
        "title": "Gurgen Mahtesyan",
        "name": "Gurgen",
        "surname": "Mahtesyan",
        "dateOfBirth": "27 June, 2001",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1401",
        "title": "Karen Hovhannisyan",
        "name": "Karen",
        "surname": "Hovhannisyan",
        "dateOfBirth": "14 May, 2002",
        "dateOfDeath": "16Nov.,2020",
        "Status": "Deceased",
        "rank": "private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "420",
        "title": "Hayk Chitchyan",
        "name": "Hayk",
        "surname": "Chitchyan",
        "dateOfBirth": "17 Nov., 1995",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Military officer staff",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "419",
        "title": "Miasnik Miasnikyan",
        "name": "Miasnik",
        "surname": "Miasnikyan",
        "dateOfBirth": "21 May, 1990",
        "dateOfDeath": "27Sept.,2020",
        "Status": "Deceased",
        "rank": "Contract soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "418",
        "title": "Davit Harutyunyan",
        "name": "Davit",
        "surname": "Harutyunyan",
        "dateOfBirth": "18 Sept. , 2000",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "417",
        "title": "Aghasi Mkrtchyan",
        "name": "Aghasi",
        "surname": "Mkrtchyan",
        "dateOfBirth": "20 Apr., 1999",
        "dateOfDeath": "06Sept.,2018",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "416",
        "title": "Arthur Muradyan",
        "name": "Arthur",
        "surname": "Muradyan",
        "dateOfBirth": "23 Aug., 2001",
        "dateOfDeath": "14July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "415",
        "title": "Ashot Mikaelyan",
        "name": "Ashot",
        "surname": "Mikaelyan",
        "dateOfBirth": "26 Aug., 1991",
        "dateOfDeath": "27July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "414",
        "title": "Garush Hambardzumyan",
        "name": "Garush",
        "surname": "Hambardzumyan",
        "dateOfBirth": "09 July, 1989",
        "dateOfDeath": "14July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "413",
        "title": "Sos Elbakyan",
        "name": "Sos",
        "surname": "Elbakyan",
        "dateOfBirth": "31 Jan., 1992",
        "dateOfDeath": "14July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "412",
        "title": "Grisha Matevosyan",
        "name": "Grisha",
        "surname": "Matevosyan",
        "dateOfBirth": "19 Apr., 2000",
        "dateOfDeath": "14July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "411",
        "title": "Smbat Gabrielyan",
        "name": "Smbat",
        "surname": "Gabrielyan",
        "dateOfBirth": "14 July, 2000",
        "dateOfDeath": "07July,2020",
        "Status": "Deceased",
        "rank": "Soldier",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "830",
        "title": "Karen Babayan",
        "name": "Karen",
        "surname": "Babayan",
        "dateOfBirth": "10 Mar., 1987",
        "dateOfDeath": "18Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "829",
        "title": "Artur Khachatryan",
        "name": "Artur",
        "surname": "Khachatryan",
        "dateOfBirth": "07 Apr., 2001",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "828",
        "title": "Andranik Babayan",
        "name": "Andranik",
        "surname": "Babayan",
        "dateOfBirth": "25 Apr., 1991",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "827",
        "title": "Marat Khachatryan",
        "name": "Marat",
        "surname": "Khachatryan",
        "dateOfBirth": "11 Jan., 2002",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "826",
        "title": "Khachik Avetisyan",
        "name": "Khachik",
        "surname": "Avetisyan",
        "dateOfBirth": "08 July, 1981",
        "dateOfDeath": "12Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "825",
        "title": "Argam Selimyan",
        "name": "Argam",
        "surname": "Selimyan",
        "dateOfBirth": "28 Oct., 2001",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "824",
        "title": "Sergey Sargsyan",
        "name": "Sergey",
        "surname": "Sargsyan",
        "dateOfBirth": "26 Mar., 2001",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "823",
        "title": "Garush Sargsyan",
        "name": "Garush",
        "surname": "Sargsyan",
        "dateOfBirth": "12 Oct., 2000",
        "dateOfDeath": "23Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "822",
        "title": "Arman Sargsyan",
        "name": "Arman",
        "surname": "Sargsyan",
        "dateOfBirth": "01 May, 1983",
        "dateOfDeath": "15Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "821",
        "title": "Harutyun Vardanyan",
        "name": "Harutyun",
        "surname": "Vardanyan",
        "dateOfBirth": "16 July, 1982",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1060",
        "title": "Manvel Tamoyan",
        "name": "Manvel",
        "surname": "Tamoyan",
        "dateOfBirth": "09 Feb., 2002",
        "dateOfDeath": "28Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1059",
        "title": "Areg Hakobyan",
        "name": "Areg",
        "surname": "Hakobyan",
        "dateOfBirth": "17 Sept. , 1977",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1058",
        "title": "Tigran Simonyan",
        "name": "Tigran",
        "surname": "Simonyan",
        "dateOfBirth": "16 July, 2000",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1057",
        "title": "Arman Sargsyan",
        "name": "Arman",
        "surname": "Sargsyan",
        "dateOfBirth": "06 Nov., 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1056",
        "title": "Artak Hakobjanyan",
        "name": "Artak",
        "surname": "Hakobjanyan",
        "dateOfBirth": "23 Nov., 2000",
        "dateOfDeath": "08Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1055",
        "title": "Zhorzh Gevorgyan",
        "name": "Zhorzh",
        "surname": "Gevorgyan",
        "dateOfBirth": "26 Dec., 2000",
        "dateOfDeath": "03Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1054",
        "title": "Haykaz Gevorgyan",
        "name": "Haykaz",
        "surname": "Gevorgyan",
        "dateOfBirth": "25 Aug., 1959",
        "dateOfDeath": "25Oct.,2020",
        "Status": "Deceased",
        "rank": "Volunteer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1053",
        "title": "Arman Simonyan",
        "name": "Arman",
        "surname": "Simonyan",
        "dateOfBirth": "30 June, 2002",
        "dateOfDeath": "11Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1052",
        "title": "Narek Shakaryan",
        "name": "Narek",
        "surname": "Shakaryan",
        "dateOfBirth": "27 June, 2002",
        "dateOfDeath": "26Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "1051",
        "title": "David Shahbazyan",
        "name": "David",
        "surname": "Shahbazyan",
        "dateOfBirth": "01 Apr., 2000",
        "dateOfDeath": "07Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "780",
        "title": "John Barkhudaryan",
        "name": "John",
        "surname": "Barkhudaryan",
        "dateOfBirth": "11 Feb., 1985",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "779",
        "title": "Gagik Brsoyan",
        "name": "Gagik",
        "surname": "Brsoyan",
        "dateOfBirth": "03 Apr., 2002",
        "dateOfDeath": "10Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "778",
        "title": "Aram Abrahamyan",
        "name": "Aram",
        "surname": "Abrahamyan",
        "dateOfBirth": "05 Nov., 1981",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "777",
        "title": "Edgar Cholakyan",
        "name": "Edgar",
        "surname": "Cholakyan",
        "dateOfBirth": "04 July, 1999",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "776",
        "title": "Marat Dadoyan",
        "name": "Marat",
        "surname": "Dadoyan",
        "dateOfBirth": "09 Jan., 2002",
        "dateOfDeath": "09Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "775",
        "title": "Arkadia Dallakyan",
        "name": "Arkadia",
        "surname": "Dallakyan",
        "dateOfBirth": "11 Apr., 1978",
        "dateOfDeath": "20Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "774",
        "title": "Valeri Danelin",
        "name": "Valeri",
        "surname": "Danelin",
        "dateOfBirth": "14 Mar., 1987",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Officer",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "300 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "773",
        "title": "Arshak Abrahamyan",
        "name": "Arshak",
        "surname": "Abrahamyan",
        "dateOfBirth": "05 July, 1978",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "772",
        "title": "Taron Filiposyan",
        "name": "Taron",
        "surname": "Filiposyan",
        "dateOfBirth": "13 Oct., 2000",
        "dateOfDeath": "28Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր * ",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "771",
        "title": "Sirekan Gasparyan",
        "name": "Sirekan",
        "surname": "Gasparyan",
        "dateOfBirth": "01 May, 1994",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "760",
        "title": "Hovhannes Avetisyan",
        "name": "Hovhannes",
        "surname": "Avetisyan",
        "dateOfBirth": "07 Feb., 1967",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "759",
        "title": "Tigran Shahnazaryan",
        "name": "Tigran",
        "surname": "Shahnazaryan",
        "dateOfBirth": "10 Sept. , 2000",
        "dateOfDeath": "01Nov.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "758",
        "title": "Levon Sargsyan",
        "name": "Levon",
        "surname": "Sargsyan",
        "dateOfBirth": "30 June, 2001",
        "dateOfDeath": "13Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "757",
        "title": "Babken Mkrtumyan",
        "name": "Babken",
        "surname": "Mkrtumyan",
        "dateOfBirth": "26 Apr., 1990",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "756",
        "title": "Gevorg Poghosyan",
        "name": "Gevorg",
        "surname": "Poghosyan",
        "dateOfBirth": "27 Nov., 1997",
        "dateOfDeath": "02Oct.,2020",
        "Status": "Deceased",
        "rank": "Corporal",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "250 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "755",
        "title": "Robert Sargsyan",
        "name": "Robert",
        "surname": "Sargsyan",
        "dateOfBirth": "13 Feb., 2001",
        "dateOfDeath": "16Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "754",
        "title": "Levon Manukyan",
        "name": "Levon",
        "surname": "Manukyan",
        "dateOfBirth": "27 Dec., 2000",
        "dateOfDeath": "14Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "753",
        "title": "Vardan Zakaryan",
        "name": "Vardan",
        "surname": "Zakaryan",
        "dateOfBirth": "13 Sept. , 1998",
        "dateOfDeath": "24Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "752",
        "title": "Erik Khachatryan",
        "name": "Erik",
        "surname": "Khachatryan",
        "dateOfBirth": "16 June, 2001",
        "dateOfDeath": "29Sept.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    },
    {
        "id": "751",
        "title": "David Uzunyan",
        "name": "David",
        "surname": "Uzunyan",
        "dateOfBirth": "01 Jan., 2001",
        "dateOfDeath": "01Oct.,2020",
        "Status": "Deceased",
        "rank": "Private",
        "BankContact": "",
        "LumpSum": "10 000 000 դր",
        "MonthlySum": "200 000 դր",
        "image": "",
        "region": "",
        "biography": "",
        "placeOfBirth": "",
        "nationality": "",
        "placeOfDeath": "",
        "profession": "",
        "numberOfChildren": "",
        "placesFought": "",
        "cirucmstancesOfDeath": "",
        "placeOfWork": "",
        "familyStatus": "",
        "testimonies": [
            {},
            {}
        ],
        "documents": [
            {}
        ],
        "images": []
    }
];