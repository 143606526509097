import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const useStyles = makeStyles((theme) => ({
    root: {
    
    },

}));
export default function AddSubmission(props) {
    const classes = useStyles();
    const theme = useTheme();
    const type = props.type;
    const [currentFile, setCurrentFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    console.log(type);
    const selectFile = function (event) {
       
        setCurrentFile(event.target.files[0])
        setPreviewImage(URL.createObjectURL(event.target.files[0]))
       
    }

   const upload=function() {
     
    }
    return (
        <div className={classes.root}>
            {type=="testimony"&&
                <>
                    <DialogContentText>
                        To subscribe to this website, please enter your email address here. We will send updates
                        occasionally.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        multiline
                        label="Enter text"
                        type="text"
                        fullWidth
                    />

                </>


            }
            {type == "document" &&
                <>
                <TextField
                    autoFocus
                    margin="dense"
                    id="link"
                    label="Enter Link"
                    type="text"
                    fullWidth
                />
          
            <TextField
                margin="dense"
                id="description"
                label="Enter Description"
                type="email"
                fullWidth
                />
                </>
            }
            {type == "picture" &&
                <>


            <div className="mg20">
                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={selectFile} />
                    <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span" >
                        Choose Image
          </Button>
                </label>
                <div className="file-name">
                    {currentFile ? currentFile.name : null}
                </div>                 
                {previewImage && (
                    <div>
                        <img className="preview my20" src={previewImage} alt="" />
                    </div>
                )}

                <Button
                    className="btn-upload"
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={!currentFile}
                    onClick={upload}>
                    Upload
                </Button>
                </div >
                </>
                }
            </div>
    );
}