import './App.css';
import Header from './Header';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './i18n';
import { Router, Switch, Route } from "react-router-dom";

import history from './history';
import HeroList from './HeroList/HeroList'
import HeroGrid from './HeroList/HeroGrid'
import Hero from './Hero/Hero'
import Memorial from './Memorial/Memorial'
function App() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#9e9e9e',
            },
            secondary: {
                main: '#e0e0e0',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
                xxl: 2500,
            },
        },
    }
    );
    return (
        <>
            <ThemeProvider theme={theme}>
                <Header />
                <Router history={history} >
                    <Switch>
                        <Route path="/" exact component={HeroGrid} />
                        <Route path="/Hero/:id" render={(props) => <Hero {...props} />} />
                        <Route path="/Memorial" render={(props) => <Memorial {...props} />} />
                    </Switch>
                </Router>
            </ThemeProvider>
     </>
  );
}

export default App;
