import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation, Trans } from 'react-i18next';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import history from './history';


import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: "#FFFFFF",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '62px'
    },
    Select: {
        color: "white"
    }, flag:{
        width: "32px",
        height: "auto",
        paddingRight:"16px"
}
}));

export default function Header() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = React.useState('en');
    const searchQuery = useLocation().search;
    const name = new URLSearchParams(searchQuery).get('search');
    console.log(name);
    const [search, setSearch] = React.useState(name);
    console.log(search);
    const [open, setOpen] = React.useState(true);
    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };
    const toggleModal = () => {
        setOpen(!open);
    };
   
    const _handleKeyDown = (e) => {

        if (e.key === 'Enter') {
            console.log('do validate' + search);
            history.push('/?search=' +search);
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <img className={classes.image}
                    src={process.env.PUBLIC_URL + "/assets/StripeforROH.png"}
                /> 
                <Toolbar style={{color: 'white', marginLeft: '10vw'}}>
                     <Typography variant="h6" className={classes.title} onClick={() => history.push('/')}>
                        {t('WebsiteTitle')}
                    </Typography>

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            onKeyDown={_handleKeyDown}
                            placeholder={t("SearchLbl")}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            onChange={event => {                                 //adding the onChange event
                                setSearch(event.target.value)
                            }}
                            value={ search}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <Button color="inherit" onClick={() => { history.push('/Memorial') }}>{t('MemorialsLbl')}</Button>

                    <div className={classes.grow} />
                    <Select 
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={classes.Select}
                        value={language}
                        onChange={handleChange}>
                        <MenuItem value={'en'}>
                            <img
                                className={classes.flag}
                                src={process.env.PUBLIC_URL + "/EN.png"} />
                            {t('EnglishTxt')}</MenuItem>
                        <MenuItem value={'hy'}> <img
                            className={classes.flag}
                            src={process.env.PUBLIC_URL + "/HY.png"} />{t('ArmenianTxt')}</MenuItem>
                        <MenuItem value={'ru'}> <img
                            className={classes.flag}
                            src={process.env.PUBLIC_URL + "/RU.png"} />{t('RussianTxt')}</MenuItem>
                        <MenuItem value={'fr'}> <img
                            className={classes.flag}
                            src={process.env.PUBLIC_URL + "/FR.png"} />{t('FrenchTxt')}</MenuItem>
                    </Select>
                    <Button color="inherit" onClick={() => { window.open('https://www.1000plus.am/'+language+'/payment') }}>{t('DonateLbl')}</Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}