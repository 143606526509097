import React from 'react';
import CropDinIcon from '@material-ui/icons/CropDin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { List, Typography } from '@material-ui/core/';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '100px',
        marginLeft: '100px',
        display: 'flex',
        flexDirection:'column'
    },
    listRoot: {
        paddingTop:"16px",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    cover: {
        width: '100%',
        margin: '0 auto'

    },
   
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function SimplePaper() {
    const classes = useStyles();

    return (
        <>
       
        <div className={classes.root}>
            <Typography variant="h5">
                    Yerablur Military Memorial Cemetery Map
            </Typography>
                <div class="row">
            <Paper elevation={3} >
                    <img
                        className={classes.cover}
                    src={process.env.PUBLIC_URL + "/assets/cemetary_model.jpg"}
                        alt={"Memorial"} />
                
            </Paper>
                    <Paper elevation={3} m={10} >
                        <Typography variant="body1">
                            Explore Different Sections of memorial
                      </Typography>
            <List className={classes.listRoot} subheader={<li />}>
                {['A', 'B', 'C', 'D'].map((sectionId) => (
                    <li key={`section-${sectionId}`} className={classes.listSection}>
                        <ul className={classes.ul}>
                            <ListSubheader>{`Section ${sectionId}`}</ListSubheader>
                            {[1, 2, 3, 4, 5].map((item) => (
                                <ListItem key={`item-${sectionId}-${item}`} button>
                                    <ListItemText primary={`Item ${item}`} />
                                </ListItem>
                            ))}
                        </ul>
                    </li>
                ))}
                </List>
                    </Paper>
                    </div>
            </div>
            </>
    );
}