export const resources = {
    en: {
        translation: {
            "ArmenianTxt":"Armenian",
            "EnglishTxt":"English",
            "RussianTxt":"Russian",
            "FrenchTxt":"French",
            "DonateLbl":"Donate",
            "WebsiteTitle":"Remembering Our Heroes",
            "MemorialsLbl":"Memorials",
            "SearchLbl":"Search...",
            "UploadLbl":"Upload a Picture",
            "AddBtn":"Add",
            "DocumentTitle": "Documents and Links",
            "DonateBtn": "Donate in {{name}}'s memory",
            "PersonalDetailsLbl":"Personal Details",
            "ServiceHistoryLbl":"Service History",
            "BiographyLbl":"Biography",
            "DateOfBirthLbl":"Date of Birth",
            "PlaceOfBirthLbl":"Place of Birth",
            "MartyrdomDateLbl":"Date of Martyrdom",
            "ProfessionLbl":"Profession",
            "NationalityLbl":"Nationality",
            "PlaceOfWorkLbl":"Place of Work",
            "FamilyStatusLbl":"Family Status",
            "NumberOfChildrensLbl":"Number of Children",
            "MilitaryRankLbl":"Military Rank",
            "ArmyProfessionLbl":"Army Profession",
            "PlaceOfMartyrdomLbl":"Place of Martyrdom",
            "PlacesFoughtLbl":"Places Fought",
            "CircumstancesOfDeathLbl":"Circumstances of death",
            "CemeteryLbl":"{{name}} is laid to rest in Yerablur Military Memorial Cemetery section {{section}} row {{row}}.",
            "StoryBtn":"Share your Story of {{name}}.",
            "TestimoniesTitle":"Testimonies",
            "fromLbl": "from: ",
            "SearchResultslbl": "Showing Results for <0>{{name}}</0>",
            "SearchCountLbl": "({{number}} results)",
          
        }
    },
    ru: {
        translation: {
            "ArmenianTxt": "Հայերեն",
            "EnglishTxt": "English",
            "RussianTxt": "русский",
            "FrenchTxt": "Français",
            "DonateLbl": "Пожертвовать",
            "WebsiteTitle": "Вспоминая наших героев",
            "MemorialsLbl": "Мемориалы",
            "SearchLbl": "Поиск...",
            "UploadLbl": "Загрузить изображение",
            "AddBtn": "Добавить",
            "DocumentTitle": "Документы и ссылки",
            "DonateBtn": "Пожертвовать в память {{name}}",
            "PersonalDetailsLbl": "Личные данные",
            "ServiceHistoryLbl": "История обслуживания",
            "BiographyLbl": "Биография",
            "DateOfBirthLbl": "Дата рождения",
            "PlaceOfBirthLbl": "Место рождения",
            "MartyrdomDateLbl": "Дата мученичества",
            "ProfessionLbl": "Профессия",
            "NationalityLbl": "Гражданство",
            "PlaceOfWorkLbl": "Место работы",
            "FamilyStatusLbl": "Cемейное положение",
            "NumberOfChildrensLbl": "Количество детей",
            "MilitaryRankLbl": "Военное звание",
            "ArmyProfessionLbl": "Армейская профессия",
            "PlaceOfMartyrdomLbl": "Место мученичества",
            "Places FoughtLbl": "Места сражений",
            "CircumstancesOfDeathLbl": "Обстоятельства смерти",
            "CemeteryLbl": "{{name}} захоронен в разделе Ераблурского военно-мемориального кладбища. {{section}} строка {{row}}.",
            "StoryBtn": "Поделитесь своей историей {{name}}.",
            "TestimoniesTitle": "Свидетельства",
            "FromLbl": "С: ",
            "SearchResultslbl": "Показать результаты для {{name}}",
            "SearchCountLbl": "({{number}} результаты)",
            "MartyrdomDateLbl": "Дата мученичества",
        }
    },
    fr: {
        translation: {
            "ArmenianTxt": "Հայերեն",
            "EnglishTxt": "English",
            "RussianTxt": "русский",
            "FrenchTxt": "Français",
            "DonateLbl": "Faire un don",
            "WebsiteTitle": "En souvenir de nos héros",
            "MemorialsLbl": "Mémoires",
            "SearchLbl": "Recherche...",
            "UploadLbl": "Télécharger une image",
            "AddBtn": "Ajouter",
            "DocumentTitle": "Documents et liens",
            "DonateBtn": "Faites un don en mémoire de {{name}}.",
            "PersonalDetailsLbl": "Détails personnels",
            "ServiceHistoryLbl": "Historique des services",
            "BiographyLbl": "Biographie",
            "DateOfBirthLbl": "Date de naissance",
            "PlaceOfBirthLbl": "Lieu de naissance",
            "MartyrdomDateLbl": "Date du martyre",
            "ProfessionLbl": "Profession",
            "NationalityLbl": "Nationalité",
            "PlaceOfWorkLbl": "Lieu de travail",
            "FamilyStatusLbl": "Situation de famille",
            "NumberOfChildrensLbl": "Nombre d'enfants",
            "MilitaryRankLbl": "Rang militaire",
            "ArmyProfessionLbl": "Profession de l'armée",
            "PlaceOfMartyrdomLbl": "Lieu du martyre",
            "Places FoughtLbl": "Lieux de combat",
            "CircumstancesOfDeathLbl": "Circonstances du décès",
            "CemeteryLbl": "{name} repose dans la section du cimetière militaire de Yerablur. {section} rangée {row}.",
            "StoryBtn": "Partagez votre histoire de {name}.",
            "TestimoniesTitle": "Témoignages",
            "FromLbl": "De: ",
            "SearchResultslbl": "Résultats pour {name}",
            "SearchCountLbl": "({number} results)",
            "MartyrdomDateLbl": "Date du martyre",
        }
    },
    hy: {
        translation: {
            "ArmenianTxt": "Հայերեն",
            "EnglishTxt": "English",
            "RussianTxt": "русский",
            "FrenchTxt": "Français",
            "DonateLbl": "Նվիրաբերել",
            "WebsiteTitle": "Հիշելով մեր հերոսներին",
            "MemorialsLbl": "Հուշարձաններ",
            "SearchLbl": "Որոնում...",
            "UploadLbl": "Վերբեռնեք նկար",
            "AddBtn": "Ավելացնել",
            "DocumentTitle": "Փաստաթղթեր և հղումներ",
            "DonateBtn": "Նվիրաբերեք {{name}}  հիշատակին",
            "PersonalDetailsLbl": "Անձնական տվյալներ",
            "ServiceHistoryLbl": "Ծառայությունների պատմություն",
            "BiographyLbl": "Կենսագրություն",
            "DateOfBirthLbl": "Ծննդյան ամսաթիվ",
            "PlaceOfBirthLbl": "Ծննդավայր",
            "MartyrdomDateLbl": "Նահատակության ամսաթիվը",
            "ProfessionLbl": "Մասնագիտություն",
            "NationalityLbl": "Ազգություն",
            "PlaceOfWorkLbl": "Աշխատանքի վայրը",
            "FamilyStatusLbl": "Ընտանեկան կարգավիճակ",
            "NumberOfChildrensLbl": "Երեխաների քանակը",
            "MilitaryRankLbl": "Ռազմական կոչում",
            "ArmyProfessionLbl": "Բանակի մասնագիտություն",
            "PlaceOfMartyrdomLbl": "Նահատակության վայրը",
            "Places FoughtLbl": "Մարտնչած տեղեր",
            "CircumstancesOfDeathLbl": "Մահվան հանգամանքներ",
            "CemeteryLbl": "{{name}} Հուղարկավորված է Եռաբլուրի ռազմական հուշահամալիրի գերեզմանատանը {{section}} շարք {{row}}.",
            "StoryBtn": "Կիսվեք ձեր պատմությամբ {{name}}.",
            "TestimoniesTitle": "Վկայություններ",
            "FromLbl": "Բնակավայր: ",
            "SearchResultslbl": "Ցուցադրվում են {{name}} արդյունքները",
            "SearchCountLbl": "({{number}} Արդյունքներ)",
            "MartyrdomDateLbl": "Նահատակության ամսաթիվը",

        }
    }
};