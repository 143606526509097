import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import * as Constants from './../config';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { IconButton, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import history from './../history';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
import { FixedSizeList as List } from "react-window";
import { purple } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '104px',
        flexWrap: 'wrap',
        flexDirection:'column',
        display:'flex',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginLeft:'40px',
        marginRight: '40px'
    },   
    icon: {
        color: 'white',
    },
    gridList: {
        width: '80vw',
        alignSelf:'center',
        // Promote the list into its own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    avatar: {

        filter: 'grayscale(100%)',
        '&:hover': {
            cursor: 'pointer',
            filter: 'grayscale(0%)',
            "& div": {
                color: 'purple'
            }
        }
    }
}));

export default function HeroList() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [hovered, setHovered] = useState(false);

    const theme = useTheme();

    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');
   // console.log(resultList);
    const getScreenWidth = () => {
        if (screenExtraLarge) {
            return 6;
        } else if (screenNarrow) {
            return 1;
        } else if (screenLarge) {
            return 5;
        } else if (screenMedium) {
            return 4;
        } else if (screenSmall) {
            return 3;
        } else if (screenExtraSmall) {
            return 2;
        } else {
            return 3;
        }
    }
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('search');
    const resultList = search ? (Constants.tileData.filter(hero => hero.title.toLowerCase().indexOf(name.toLowerCase()) > -1)) : Constants.tileData;
    const shuffleArray = (array) => {
        //if (name) return array;
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const toggleHover = function(e){
        //console.log(e.target);
        var elem = e.target;
        console.log(elem.getElementsByClassName("avatarLbl"));
  //      setHovered(!hovered);
    }
    return (
        <div className={classes.root}>
            {
                name&& <Typography variant="h6" gutterBottom>
                    Showing Results for {'\u00A0'}
                        <b>
                     {name}
                    </b>
                    {'\u00A0'}
                    {t('SearchCountLbl', resultList.length)}
            </Typography>}
            
            <GridList
                cols={getScreenWidth()}
                spacing={40}
                className={classes.gridList}
            >                    
                {shuffleArray(resultList).map((tile) => (
                    <GridListTile
                        onMouseEnter={toggleHover}
                        onMouseLeave={toggleHover}
                        key={tile.id} style={{ height: '400px' }} onClick={() => history.push(`/hero/${tile.id}`)} aria-label={`info about ${tile.title}`}>
                        <img src={process.env.PUBLIC_URL + "/assets/" + (tile.image ? tile.image : "soldier-vwma.png")} alt={tile.title} className={classes.avatar} />
                        <GridListTileBar
                            title={tile.title}
                            className="avatarLbl"
                            subtitle={<span>{t("fromLbl")}{tile.region}</span>}
                            titlePosition="bottom"
                            actionIcon={                             
                                <IconButton onClick={() => history.push(`/hero/${tile.id}`)} aria-label={`info about ${tile.title}`} className={classes.icon}>
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}