import { FixedSizeGrid as Grid } from 'react-window';
import React, { useState } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import * as Constants from './../config';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { IconButton, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import history from './../history';
import { useLocation } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AutoSizer from "react-virtualized-auto-sizer";

import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '104px',
        flexWrap: 'wrap',
        flexDirection: 'column',
        display: 'flex',
        overflow: 'hidden',
        marginLeft: '40px',
        marginRight: '40px',
        height: '88vh' 
    },
    icon: {
        color: 'white',
    },
    gridList: {
        width: '80vw',
        alignSelf: 'center',
        // Promote the list into its own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    avatarLbl: {
        transition: "all 1.8s",

    },
    avatar: {
        transition: "all 0.4s",
        filter: 'grayscale(100%)',
        '&:hover': {
            cursor: 'pointer',
            filter: 'grayscale(0%)',
            "& div": {
                color: 'purple'
            }
        }
    },
    GridItem: {
    display: 'flex',
    alignItems: 'center',
        justifyContent: 'center',

        listStyleType: 'none'
    },
    Grid: {
        border: '1px solid #d9dddd',
        listStyleType: 'none'
    }

}));



export default function HeroGrid() {
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('search');
    const { t, i18n } = useTranslation();

    const resultList = search ? (Constants.tileData.filter(hero => hero.title.toLowerCase().indexOf(name.toLowerCase()) > -1)) : Constants.tileData;
    

    const theme = useTheme();
    const classes = useStyles();
    const screenExtraExtraLarge = useMediaQuery(theme.breakpoints.only('xxl'));
    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');
    // console.log(resultList);
    const getScreenWidth = () => {
        if (screenExtraExtraLarge) {
            return 7;
        }
        if (screenExtraLarge) {
            return 6;
        } else if (screenNarrow) {
            return 1;
        } else if (screenLarge) {
            return 5;
        } else if (screenMedium) {
            return 4;
        } else if (screenSmall) {
            return 3;
        } else if (screenExtraSmall) {
            return 2;
        } else {
            return 3;
        }
    }
    const getColumnWidth = () => {
        if (screenExtraExtraLarge) {
            return 400;
        }
        if (screenExtraLarge) {
            return 400;
        } else if (screenNarrow) {
            return 400;
        } else if (screenLarge) {
            return 350;
        } else if (screenMedium) {
            return 350;
        } else if (screenSmall) {
            return 300;
        } else if (screenExtraSmall) {
            return 200;
        } else {
            return 100;
        }
    }
    const toggleHover = function (e) {
        var elem = e.target;

        console.log(elem.getElementsByClassName("MuiGridListTileBar-title"));
        var child1 = elem.getElementsByClassName("MuiGridListTileBar-title")[0];
        var child2 = elem.getElementsByClassName("MuiGridListTileBar-subtitle")[0];
        var child3 = elem.getElementsByClassName("avatarLbl ")[0];
        if (!child1) return;
        child1.style = "font-size: large;";
        if (!child2) return;
        child2.style = "font-size: large;";
        if (!child3) return;
        child3.style = "height: 100px;";
    }
    const toggleunHover = function (e) {
        var elem = e.target;
        var child1 = elem.getElementsByClassName("MuiGridListTileBar-title")[0];
        var child2 = elem.getElementsByClassName("MuiGridListTileBar-subtitle")[0];
        var child3 = elem.getElementsByClassName("avatarLbl ")[0];

        if (!child1) return;       
        child1.style = "font-size: unset;";
        if (!child2) return;
        child2.style = "font-size: unset;";
        if (!child3) return;
        child3.style = "height: 68px;";
    }
    const getRowCount = function () {
       
        return Math.ceil(resultList.length / getScreenWidth());
    }

    return (
        <div className={classes.root}>
            
            <div style={{ flex: '1 1 auto' }}>
                {
                    name && <Typography variant="h6" gutterBottom>

                        <Trans i18nKey="SearchResultslbl">
                            <b>{{ name }}</b>
                        </Trans>                        
                        {'\u00A0'}
                        {t('SearchCountLbl', { number: resultList.length })}
                    </Typography>}
                <AutoSizer>
                    {({ height, width }) => (
                        <Grid
                            className="Grid"
                            columnCount={getScreenWidth()}
                            columnWidth={getColumnWidth()}
                            height={height}
                            rowCount={getRowCount()}
                            rowHeight={400}
                            width={width}
                        >
                            { ({ columnIndex, rowIndex, style }) => {
                                let tile = resultList[rowIndex * getScreenWidth() + columnIndex];
                                
                                return (
                                    <Container maxWidth="sm"
                                                 className={
                                            columnIndex % 2
                                                ? rowIndex % 2 === 0
                                                    ? 'GridItemOdd'
                                                    : 'GridItemEven'
                                                : rowIndex % 2
                                                    ? 'GridItemOdd'
                                                    : 'GridItemEven'
                                        }
                                        style={style}
                                    >
                                        {tile&&<GridListTile
                                            onMouseEnter={toggleHover}
                                            onMouseLeave={toggleunHover}
                                            key={tile.id}
                                            style={{ padding: "20px", height: '400px', listStyleType: 'none' }}
                                            onClick={() => history.push(`/hero/${tile.id}`)}
                                            aria-label={`info about ${tile.title}`}>
                                            <img src={process.env.PUBLIC_URL + "/assets/" + (tile.image ? tile.image : "soldier-vwma.png")} alt={tile.title} className={classes.avatar} />
                                            <GridListTileBar
                                                title={tile.title+" #"+tile.id}
                                                className="avatarLbl"
                                                subtitle={<span>{t("fromLbl")}{tile.region}</span>}
                                                titlePosition="bottom"
                                                actionIcon={
                                                    <IconButton onClick={() => history.push(`/hero/${tile.id}`)} aria-label={`info about ${tile.title}`} className={classes.icon}>
                                                        <InfoIcon />
                                                    </IconButton>
                                                }
                                            />
                                        </GridListTile>}
                                    </Container>

                                ); }}
                        </Grid>
                     )}
            </AutoSizer>
            </div>
        </div>
);

}