import React from 'react';


import Typography from '@material-ui/core/Typography';
export default function Pair(props) {
    const { label, value} = props;

    return (
        <>            
            <Typography variant="body1">
                <b>{label}:</b> {value}
            </Typography>
        </>
    );
}